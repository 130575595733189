const REQUEST_GLOBAL = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_GLOBAL = (state, { body }) => {
  state.status = "success";
  state.Global = body;
};

const FAILURE_REQUEST_GLOBAL = state => {
  state.status = "failure";
};

const REQUEST_GLOBAL_ALL = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_GLOBAL_ALL = (state, { body }) => {
  state.status = "success";
  state.GlobalAll = body;
};

const FAILURE_REQUEST_GLOBAL_ALL = state => {
  state.status = "failure";
};

const REQUEST_UPDATE_GLOBAL = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_GLOBAL = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_GLOBAL = state => {
  state.statusUpdate = "failure";
};

const REQUEST_CREATE_GLOBAL = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_CREATE_GLOBAL = state => {
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_GLOBAL = state => {
  state.statusCreate = "failure";
};



export default {
  REQUEST_GLOBAL,
  SUCCESS_REQUEST_GLOBAL,
  FAILURE_REQUEST_GLOBAL,
  REQUEST_GLOBAL_ALL,
  SUCCESS_REQUEST_GLOBAL_ALL,
  FAILURE_REQUEST_GLOBAL_ALL,
  REQUEST_UPDATE_GLOBAL,
  SUCCESS_REQUEST_UPDATE_GLOBAL,
  FAILURE_REQUEST_UPDATE_GLOBAL,
  REQUEST_CREATE_GLOBAL,
  SUCCESS_REQUEST_CREATE_GLOBAL,
  FAILURE_REQUEST_CREATE_GLOBAL,
};
