<template>
  <v-container class="pa-0 ma-0" :style="cssVars">
    <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3">  
        72h Power Range Forecast
      </v-col>
    </v-row>  
    <v-row class="heatingDataStyle pt-5">
      <v-col >
         <apexchart  v-if="showChart" type="line" height="350" :options="apexChartOptions" :series="apexChartData"></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";

export default {
  name: "HeatingDataScreen",
  props: { },
  components: {
    //Bar
  },
  data() {
    return {
      overallDemand: [],
      overallDemandMin: [],
      overallDemandMax: [],
      dataYMin: 10000000,
      dataYMax: 0,
      //overallDemandMinMax: [],
      selectedDate: null,
      // options: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   legend: {
      //     labels: {
      //       fontColor: "orange",
      //       defaultFontColor: "red",
      //       defaultFontSize: "40"
      //     }
      //   }
      // },
      apexChartOptions: {
        
        theme: {
          mode: "dark",
        },
        legend: {
          position: "top"
        },
        colors: [this.$vuetify.theme.themes.dark.energy_secondary, this.$vuetify.theme.themes.dark.heating_primary],
        forecastDataPoints: {
          count: 0,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        },
        chart: {
          toolbar: {
            show: true
          },
          zoom: {
            enabled: false,
          },
          background: this.$vuetify.theme.themes.dark.background_secondary,
          height: 350,
          type: 'rangeArea',
        },
        stroke: {
          width: "2",
          dashArray: 2,
          curve: "smooth"
          //curve: 'straight'
        },
        fill: {
          type:'solid',
          opacity: [1, 1],
        },
        grid: {
          show: true,
          borderColor: '#222222',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: true
            }
          },   
          yaxis: {
            lines: {
              show: true
            }
          },  
          
        },
        labels: [],
        markers: {
          size: 0
        },
        yaxis: [
          {
            min: 0,
            max: 0,
            //forceNiceScale: true,
            title: {
              text: 'Watth',
            },
          },
        ],
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            format: 'dd MMM HH:mm',
            formatter: undefined,
          },
          y: {
            formatter: function (y) {
              if(typeof y !== "undefined") {
                return  y.toFixed(1) + "  Wh";
              }
              return y;
            }
          }
        }
      },
      showChart: false,
    };
  },
  created: function () {
    this.selectedDate = this.$moment().format("DD MMM YYYY");
  },
  async mounted() {
    this.loadHeatControl();
  },
  methods: {
    ...mapActions("device", [
      "getPowerDevicesOnly",
    ]),

    async loadHeatControl() {
      await this.getPowerDevicesOnly({type: "heat control"});
      if (this.Devices.length > 0) {
        if (this.Devices[0].powerDemand.length > 0) {
          this.overallDemand = [];
          for (let ha = 0; ha < this.Devices[0].powerDemand.length; ha++) {
            if (ha == 0) {
              this.overallDemand = JSON.parse(JSON.stringify(this.Devices[0].powerDemand[ha]));
            } else {
              for (let pos = 0; pos < this.Devices[0].powerDemand[ha].length; pos++) {
                //console.log("loadHeatControl", ha, pos, this.overallDemand[pos], this.Devices[0].powerDemand[ha][pos])
                this.overallDemand[pos] += this.Devices[0].powerDemand[ha][pos];
              }
            }
          }
          for (let idx = 0; idx < this.overallDemand.length; idx = idx + 2) {
            const time = this.$moment().startOf("hour").add(Math.floor(idx / 2), "hour").format("x");
            //this.overallDemandMinMax.push({x: Number(time), y: [this.overallDemand[idx], this.overallDemand[idx+1]]});
            //this.overallDemandMinMax.push([Number(time), [this.overallDemand[idx], this.overallDemand[idx+1]]]);
            this.overallDemandMin.push([Number(time), this.overallDemand[idx]]);
            this.overallDemandMax.push([Number(time), this.overallDemand[idx+1]]);
            if (this.dataYMin > this.overallDemand[idx]) {
              this.dataYMin = this.overallDemand[idx];
            }
            if (this.dataYMax < this.overallDemand[idx+1]) {
              this.dataYMax = this.overallDemand[idx+1];
            }
          }

          this.apexChartOptions.yaxis[0].min = this.dataYMin;
          this.apexChartOptions.yaxis[0].max = this.dataYMax;
          
        }
      }
      this.showChart = true;
    }
    
  },

  computed: {
    ...mapState({
      Devices: ({ device }) => device.powerDevicesOnly,
      SolarFc: ({ forecast }) => forecast.SolarFc,
    }),

    formatedDate() {
      return this.$moment(this.selectedDate).format("DD MMM YYYY");
    },
    // chartData() {
    //   return {
    //     datasets: [
    //       {
    //         label: "Power Wh",
    //         data: this.pv_data,
    //         backgroundColor: "rgba(255, 223, 20, 0.5)",
    //         borderColor: "rgb(255, 223, 20)",
    //         borderWidth: 2
    //       },
    //       // {
    //       //   label: "Own",
    //       //   data: this.own_data,
    //       //   backgroundColor: "rgba(51, 153, 51, 0.5)",
    //       //   borderColor: "rgb(51, 153, 51)",
    //       //   borderWidth: 2
    //       // }
    //     ],
    //     labels: this.date
    //   };
    // },
    // pv_data() {
    //   // console.log(_.map(this.SolarFc[0].data, "value"));
    //   return _.map(this.SolarFc[0].data, "value");
    // },
    // own_data() {
    //   return _.map(this.events, "value");
    // },
    // date() {
    //   return _.map(this.SolarFc[0].data, val => {
    //     return this.$vuetify.breakpoint.smAndDown
    //       ? this.$moment(val.time, "X").format("HH:mm")
    //       : new Date(val.time).toLocaleString();
    //   });
    // },

    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    },

    apexChartData() {
      return [{
            name: 'min',
            type: 'line',
            data: this.overallDemandMin
          }, 
          {
            name: 'max',
            type: 'line',
            data: this.overallDemandMax
          }
          ]
      
    },
  },
  watch: {
    
  },
};
</script>

<style lang='scss'>

.heatingDataStyle {
  width: 90%;
}

</style>