<template>
  <v-container class="pa-0 ma-0" :style="cssVars" >
    <!-- <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3">  
        Balance
      </v-col>
    </v-row> -->

    <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3">  
        Actual Month
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 mt-5 mb-n1 moneyStyle" >
      <v-col>
        <v-row>
          <v-col class="caption pb-1 font-weight-bold" cols="7">
            Earnings
          </v-col>
        </v-row>    
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Shares
          </v-col>
          <v-col cols="5" class="caption py-0">
            6,14 €
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Incentive
          </v-col>
          <v-col cols="5" class="caption py-0">
            1,2 €
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Total
          </v-col>
          <v-col cols="5" class="caption py-0">
            7,9 €
          </v-col>
        </v-row>
        <!-- <v-row>     
          <v-col cols="5" class="caption py-0">
            Return on Capital
          </v-col>
          <v-col cols="5" class="caption py-0">
            7,9 €
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 mt-5 mb-n1 moneyStyle" >
      <v-col>
        <v-row class="">
          <v-col class="caption pb-1 font-weight-bold" cols="7">
            PV 1
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Share Quota
          </v-col>
          <v-col cols="5" class="caption py-0">
            20,3 %
          </v-col>
        </v-row>
        <!-- <v-row>     
          <v-col cols="5" class="caption py-0">
            Return on Capital
          </v-col>
          <v-col cols="5" class="caption py-0">
            4770 €
          </v-col>
        </v-row> -->
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Share Value
          </v-col>
          <v-col cols="5" class="caption py-0">
            4770 €
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Internal Selling
          </v-col>
          <v-col cols="5" class="caption py-0">
            210 kWh
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Internal Earnings
          </v-col>
          <v-col cols="5" class="caption py-0">
            63,0 €
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            External Selling
          </v-col>
          <v-col cols="5" class="caption py-0">
            280 kWh
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            External Earnings
          </v-col>
          <v-col cols="5" class="caption py-0">
            67,2 €
          </v-col>
        </v-row>
        <!-- <v-row>     
          <v-col cols="5" class="caption py-0">
            Running Costs
          </v-col>
          <v-col cols="5" class="caption py-0">
            0,00 €
          </v-col>
        </v-row> -->
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Depreciation
          </v-col>
          <v-col cols="5" class="caption py-0">
            83,0 €
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Profit
          </v-col>
          <v-col cols="5" class="caption py-0">
            30,68 €
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 mt-5 mb-n1 moneyStyle" >
      <v-col>
        <v-row class="">
          <v-col class="caption pb-1 font-weight-bold" cols="7">
            Parameters PV 1
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Internal Price
          </v-col>
          <v-col cols="5" class="caption py-0">
            0.30 € / kWh
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            External Price
          </v-col>
          <v-col cols="5" class="caption py-0">
            0.24 € / kWh
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="5" class="caption py-0">
            Incentive Rate
          </v-col>
          <v-col cols="5" class="caption py-0">
            25 %
          </v-col>
        </v-row>
         <v-row>     
          <v-col cols="5" class="caption py-0">
            Reserves Rate
          </v-col>
          <v-col cols="5" class="caption py-0">
            10 %
          </v-col>
        </v-row>
      </v-col>
    </v-row>
   
    <v-row class="ml-0 mr-3 mt-10" >
      <v-col>
        <v-row>     
          <v-col cols="6" class="caption py-2">
            <v-btn block disabled>Buy Shares</v-btn>
          </v-col>
          <v-col cols="6" class="caption py-2">
            <v-btn @click="offerShares" block>Offer Shares</v-btn>
          </v-col>
        </v-row>
        <v-row>     
          <v-col cols="6" class="caption py-2">
            <v-btn block>Buy Coins</v-btn>
          </v-col>
          <v-col cols="6" class="caption py-2">
            <v-btn block>Transfer Coins</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="offerSharesDialog">
      <v-col>
        <v-row>
          How Much ?
        </v-row>
      </v-col> 
    </v-dialog>
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";


export default {
  name: "MoneyDataScreen",
  props: { },
  components: {
   
  },
  data() {
    return {
      offerSharesDialog: false
    };
  },
  created: function () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  },
  async mounted() {

  },
  methods: {
    offerShares() {
      this.offerSharesDialog = true;
    }
  },
  computed: {
    ...mapState({
      
    }),

   
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    },

    themeMode() {
      if (this.$vuetify.theme.isDark) {
        return "dark";
      } else {
        return "light"
      }
    }
  },
  watch: {
    themeMode(theme) {
      // console.log("Theme changed ", theme);
      
    }
  },
};
</script>

<style lang='scss'>


.moneyStyle {
  width: 80%;
}

</style>