<template>
  <div class="costcenter" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers_costcenter"
            :items="Costcenters"
            sort-by="name1"
            class="elevation-1"
            dense
            must-sort
            :footer-props="{'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Costcenters</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
                 <v-btn
                  color="blue"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemCostcenter"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_costcenter" max-width="500px">
                 
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleCostcenter }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form v-model="valid" style="width:100%;height:100%;" class="compact-form">
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemCostcenter.name"
                          label="Name"
                        ></v-text-field>
                        
                        <v-checkbox
                          dense
                          v-model="editedItemCostcenter.active"
                          label="Active"
                        ></v-checkbox>
                      
                        <v-checkbox
                          dense
                          v-model="editedItemCostcenter.productive"
                          label="Productive"
                        ></v-checkbox>

                        <v-select
                          :items="Locations"
                          item-text="name"
                          item-value="_id"
                          label="Client"
                          v-model="editedItemCostcenter.location"
                        >
                        

                        <!-- v-on:change="changeFilter" -->
                        </v-select>
                        
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeCostcenter">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="saveCostcenter">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.location="{ item }">
              {{comp_location(item.location)}}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemCostcenter(item)">mdi-pencil</v-icon>
              <v-icon small @click="deleteItemCostcenter(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule } from "../utils/rules";

export default {
  name: "costcenter",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,

      dialog_delete: false,
      dialog_costcenter: false,
    
      headers_costcenter: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Active", value: "active" },
        { text: "Productive", value: "productive" },
        { text: "Location", value: "location" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndexCostcenter: -1,
      defaultItemCostcenter: {
        name: "",
        active: true,
        productive: true,
        location: ""
      },
      editedItemCostcenter: {},
      dialog_costcenter: false,
      editedIndexCostcenter: -1,
      snackbar: false,
      snackbar_text: ""
    };
  },
  created() {
    this.editedItemCostcenter = JSON.parse(JSON.stringify(this.defaultItemCostcenter));
  },

  async mounted() {
    //await this.getCostcenters({user: this.mySelf._id});
    //await this.getUsers();
    await this.getCostcenters({location: this.$store.state.selectedLocation._id});
    await this.getLocation({});
    //console.log(this.Costcenters);
  },
  computed: {
    ...mapState({
      status: ({ costcenter }) => costcenter.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Costcenters: ({ costcenter }) => costcenter.Costcenters,
      CostcenterState: ({ costcenter }) => costcenter,
      Users: ({ user }) => user.Users,
      Locations: ({ location }) => location.Locations,
    }),

    formTitleCostcenter() {
      return this.editedIndexCostcenter === -1 ? "New Costcenter" : "Edit Costcenter";
    },

    
  },
  methods: {
    ...mapActions("costcenter", [
      "getCostcenters",
      "createCostcenter",
      "updateCostcenter",
      "deleteCostcenter"
    ]),
    ...mapActions("user", [
      "getUsers",
    ]),
    ...mapActions(
      "location", [
        "getLocation"
        ]
      ),

    async createItemCostcenter() {
      this.editedItemCostcenter = Object.assign({}, this.defaultItemCostcenter);
      this.editedIndexCostcenter = -1;
      //this.changeFilter(null);
      this.dialog_costcenter = true;
    },

    async editItemCostcenter(item) {
      //console.log("editItem ",item);
      
      this.editedIndexCostcenter = this.Costcenters.indexOf(item);
      this.editedItemCostcenter = Object.assign({}, item);
      //this.changeFilter(item._id);
      this.dialog_costcenter = true;
    },

    async deleteItemCostcenter(item) {
      const index = this.Costcenters.indexOf(item);
      await this.deleteCostcenter(item._id);
      if (this.CostcenterState.statusDelete == "success") {
        //this.Costcenters.splice(index, 1);
        await this.getCostcenters({location: this.$store.state.selectedLocation._id});
      } else {
        //this.snackbar_text = "Already deleted";
        //this.snackbar = true;
      }
      
    },

    closeCostcenter() {
      this.dialog_costcenter = false;
      this.$nextTick(() => {
        this.editedItemCostcenter = Object.assign({}, this.defaultItem);
        this.editedIndexCostcenter = -1;
      });
    },

    async saveCostcenter() {
      //console.log("saveCostcenter");

      //console.log(this.editedItemCostcenter)

      if (this.editedIndexCostcenter > -1) {
        await this.updateCostcenter(this.editedItemCostcenter);
        if (this.CostcenterState.statusUpdate == "success") {
          // this.editedItemCostcenter._id = this.CostcenterState.Costcenter._id;
          // this.editedItemCostcenter.updatedAt = this.$moment(
          //   this.CostcenterState.Costcenter.updatedAt
          // ).format("DD-MM-YYYY HH:mm");
          Object.assign(
            this.Costcenters[this.editedIndexCostcenter],
            this.editedItemCostcenter
          );
        } else {
          //this.snackbar_text = this.CostcenterState.Costcenter.message;
          this.snackbar_text = "Update failed";
          this.snackbar = true;
        }
      } else {
        //this.editedItemCostcenter.user.push(this.mySelf._id);
        await this.createCostcenter(this.editedItemCostcenter);
        if (this.CostcenterState.statusCreate == "success") {
          // this.editedItemCostcenter._id = this.CostcenterState.Costcenter._id;
          // this.editedItemCostcenter.updatedAt = this.$moment(
          //   this.CostcenterState.Costcenter.updatedAt
          // ).format("DD-MM-YYYY HH:mm");
          this.Costcenters.push(this.editedItemCostcenter);
        } else {
          //this.snackbar_text = this.snackbar_text = this.CostcenterState.Costcenter.message;
          this.snackbar_text = "Create failed";
          this.snackbar = true;
        }
      }
      this.closeCostcenter();
    },

    comp_location (id) {
      
      try {
        const res = this.Locations.find(obj => {return obj._id == id}).name;
        return res
      }
      catch {
        return ""
      }
    },
   
  },
  watch: {
    // editedIndexCostcenter(item) {
    //   console.log(item);
    // },
  },
};
</script>

<style scoped>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
</style>