<template>
  <div id="app" >
    <v-app>
      <v-main :style="{background: $vuetify.theme.themes[theme].background_primary}">
        <router-view></router-view>
      </v-main>
    </v-app>
    <div v-if="debugMode" class="version caption">
      <v-btn color="#222222" @click="doLogout()"><v-btn x-small color="#222222" @click="doReload()">X</v-btn>{{version}}</v-btn>
    </div>
    <div v-else class="version caption" @click="debugMode = true;">
      {{version}}
    </div>
    <!-- <div v-if="debugMode" class="bottom-menu-left">
      <v-btn class="mb-1 mr-3" @click="changeTheme">Switch Theme</v-btn>
    </div> -->
  </div>
  
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import packageJson from "../package.json";

export default {
  name: "App",
  data() {
    return {
      debugMode: false,
      version: "V1." + packageJson.version.substring(2,packageJson.version.length), //"V1.7.33",
      //version: "V1.7.46",
      drawer: false,
      items: [],
      //mini: true,
      right: null,
      // theme1: undefined,
    };
  },

  mounted() {
    // this.logout();
    // this.theme1 = JSON.stringify(this.$vuetify.theme.themes);
    console.log("App mounted");
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
    }),
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  watch: {
    mySelf(value) {
      if (!value) {
        //console.log("No mySelf!");
        this.IntervalOff();
        this.$router.push({ name: "login" });
      }   
      
      if (this.mySelf.obs_settings.theme == "dark") {
        this.$vuetify.theme.isDark = true;
      } else {
        this.$vuetify.theme.isDark = false;
      }

      this.items = [];
      try {
        //console.log(this.mySelf.roles);
        if (this.mySelf.roles.includes("user")) {
          this.items.push({
            title: "Home",
            icon: "mdi-home",
            color: "blue darken-2",
            path: "/home",
          });
          this.items.push({
            title: "Issue",
            icon: "mdi-archive",
            color: "green",
            path: "/issue",
          });
          this.items.push({
            title: "Report",
            icon: "mdi-chart-box-outline",
            color: "orange",
            path: "/report",
          });
        } else if (this.mySelf.roles.includes("admin")) {
          console.log("admin detected");
          this.$router.options.routes.forEach((route) => {
            if (!_.isUndefined(route.icon)) {
              this.items.push({
                title: route.name,
                icon: route.icon,
                color: route.color,
                path: route.path,
              });
            }
          });
        }
      } catch {}
    },
  },
  destroyed() {
    this.IntervalOff();
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),

    // changeTheme () {
    //   this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark
    // },

    pushRoute(item) {
      let item_path = item.path;
      item_path = item_path.slice(1, item.path.length);
      //this.mini = true
      //this.drawer = false
      this.$router.push(item_path);
    },
    actualRoute(path) {
      let getRoute = _.find(this.items, { path });
      if (_.isUndefined(getRoute)) {
        getRoute = {
          color: "",
          icon: "",
          name: "",
        };
      }
      return getRoute;
    },
    doLogout() {
      
      try {
        let authData = {};
        authData = JSON.parse(localStorage.getItem("authData"));
        authData.autoLogin = false;
        localStorage.setItem("authData", JSON.stringify(authData));
      } catch (err) {
        console.log(err);
      }
      
      this.logout();
    },
    doReload() {
      window.location.reload(true);
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.authInterval)) {
          clearInterval(this.$store.authInterval);
          this.$store.authInterval = undefined;
        }
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style lang='scss'>
.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}

.body {
  overscroll-behavior-y: contain !important;
}

.html {
  overscroll-behavior-y: contain !important;
}
body {
  overscroll-behavior-y: contain !important;
}
html {
  overscroll-behavior-y: contain !important;
}
#app {
  overflow-x: hidden;
  overflow-y: hidden;
}
.version {
  position: absolute;
  bottom: 0%;
  left: 40%;
}
</style>

