import api from "../../../api";

export default {
  async getWeatherDaily({ commit }, filter) {
    commit("REQUEST_WEATHERDAILY");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_WEATHERDAILY",
        await api.getWeatherDaily(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_WEATHERDAILY");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getSolarFc({ commit }, filter) {
    commit("REQUEST_SOLARFC");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SOLARFC",
        await api.getSolarFc(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_SOLARFC");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
