<template>
  <v-container class="HomeScreen" >
    <div v-if="loading_data">
      <img class="logo" src="../assets/sit-logo-xs1-20.gif"/>
      <div class="logo-progress white--text"> 
        <v-progress-linear
          color="green"
          :active="loading_data"
          :height="5"
          indeterminate
        ></v-progress-linear></div>
      <div class="logo-overlay">
      </div>
      <pre class="caption logo-console" id="log"></pre>
      <v-btn color="green" v-if="AllLoaded" class="logo-button" @click="loading_data = false; ">Start OBS App</v-btn>
    </div>
    <!-- <loading
      :active="refresh_data"
      :lock-scroll="true"
      color="green"
      :height="200"
      :width="200"
      :opacity="1"
      loader="bars"
      background-color="black"
    > 
    </loading> -->
    <!-- <v-progress-linear
      class="refresh-bar"
      color="green"
      :active="refresh_data"
      :height="5"
      indeterminate
    ></v-progress-linear></div> -->
    
    <ScoreBar class="TopBar ml-3 mt-5" :elements="topBarElements" title="SCORE" glow="false" size="25"  @menuEvent="topMenuClick" />
    <ScoreBar class="MainBar ml-3 mt-3" :elements="mainBarElements" title="MAIN" glow="false" size="40"  @menuEvent="topMenuClick" />

    <RadialMenu class="ml-3" :user="mySelf" :myIssues="myIssues" :message="info_message" :showMsgBox="showMsgBox" :radialIcon="radialIcon"  @radialClick="radialClick"/>
  
    <MainScreen class="ml-3" v-if="SelectedScreen.name == 'mainScreen'"/>
    <ElectricityScreen class="ml-3 mt-10" :myIssues="myIssues" :radialIcon="radialIcon" @menuEvent="changesInElectricityScreen" v-if="SelectedScreen.name == 'electricityScreen'"/>
    <HeatingScreen class="ml-3 mt-10" :radialIcon="radialIcon" @menuEvent="changesInHeatingScreen" v-if="SelectedScreen.name == 'heatingScreen'"/>
    <ShadingScreen class="ml-3 mt-10" :radialIcon="radialIcon" @menuEvent="changesInShadingScreen" v-if="SelectedScreen.name == 'shadingScreen'"/>
    <SettingsScreen class="ml-3 mt-10" :radialIcon="radialIcon" @menuEvent="changesInSettingsScreen" v-if="SelectedScreen.name == 'settingsScreen'"/>
    <HeatingDataScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'heatingDataScreen'"/>
    <ElectricityDataScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'electricityDataScreen'"/>
    
    <BenchmarkDataScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'benchmarkDataScreen'"/>
    <EnvironmentDataScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'environmentDataScreen'"/>
    <ActivityDataScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'activityDataScreen'"/>
    <MoneyDataScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'moneyDataScreen'"/>
    <VotingScreen class="ml-3 mt-10" :radialIcon="radialIcon" v-if="SelectedScreen.name == 'votingScreen'"/>
   
   
    <div v-if="SelectedScreen.name != 'mainScreen'" class="bottom-menu-right" @click="topMenuClick({name: 'main'})">
      <!-- <v-btn fab @click="topMenuClick({name: 'main'})"> -->
        <!-- <ShowSVG
          class="ml-3 mb-1"
          id="MainButton"
          name="iconpic_hauptmenue_cut"
          title="MainButton"
          primary="inactive_primary"
          secondary="inactive_secondary"
          size="40"
          text=""
          :glow="false"
        /> -->
        <!-- <v-icon size="50">mdi-home-import-outline</v-icon> -->
        <v-btn
          fab
          style="width: 70px; height: 70px;"
          >
          <v-icon size="70">mdi-chevron-left</v-icon>
        </v-btn>
      <!-- </v-btn> -->
    </div>
    
    <!-- <v-row class="bottom-menu">
      <v-col cols="1" class="justify-start" bottom-menu>
        <v-btn  @click="topMenuClick({name: 'main'})">Home</v-btn>
      </v-col>
      <v-col cols="11" class="justify-center">
        <v-btn  @click="changeTheme">Switch Theme</v-btn>
      </v-col>
    </v-row> -->
    

    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ShowSVG from "../components/ShowSVG";

import RadialMenu from "../components/RadialMenu";
import ScoreBar from "../components/ScoreBar";
import StatusBar from "../components/StatusBar";

import MainScreen from "../components/MainScreen";
import ElectricityScreen from "../components/ElectricityScreen";
import ElectricityDataScreen from "../components/ElectricityDataScreen";
import HeatingScreen from "../components/HeatingScreen";
import HeatingDataScreen from "../components/HeatingDataScreen";
import ShadingScreen from "../components/ShadingScreen";
import SettingsScreen from "../components/SettingsScreen";

import BenchmarkDataScreen from "../components/BenchmarkDataScreen";
import EnvironmentDataScreen from "../components/EnvironmentDataScreen";
import ActivityDataScreen from "../components/ActivityDataScreen";
import MoneyDataScreen from "../components/MoneyDataScreen";
import VotingScreen from "../components/VotingScreen";


export default {
  name: "Home",
  props: {},
  components: {
    RadialMenu,
    Loading,
    ScoreBar,
    StatusBar,
    MainScreen,
    ElectricityScreen,
    HeatingScreen,
    ShadingScreen,
    SettingsScreen,
    HeatingDataScreen,
    ElectricityDataScreen,
    EnvironmentDataScreen,
    BenchmarkDataScreen,
    ActivityDataScreen,
    MoneyDataScreen,
    ShowSVG,
    VotingScreen
  },
  data() {
    return {
      // selectedPage: 'main',
      loading_data: true,
      //refresh_data: false,
      refreshInterval: null,
      refreshDataInterval: null,
      fullPage: true,
      snackbar: false,
      snackbar_text: "",
      showMsgBox: true,
      radialIcon: {},

      myIssues: [],

      topBarElements: [
      //   {id: 0, name: "communityData", icon: "community-grey-solo-9", primary: "community_primary", secondary: "community_secondary", number: 0, denominator: "", suffix: "", prefix: "#"},
      //  //{id: 0, name: "community", icon: "iconpic_groundfloor", primary: "community_primary", secondary: "community_secondary", number: 989, denominator: "", suffix: ""},
      //   {id: 1, name: "activityData",  icon: "activity-grey-solo-9", primary: "interaction_primary", secondary: "interaction_secondary", number: 1, denominator: "", suffix: ""},
      //   {id: 2, name: "electricityData", icon: "electricity-grey-solo-9", primary: "energy_primary", secondary: "energy_secondary", number: 0, denominator: "", suffix: ""},
      //   {id: 3, name: "heatData", icon: "heating-grey-solo-9", primary: "heating_primary", secondary: "heating_secondary", number: 0, denominator: "", suffix: ""}
      ],
      mainBarElements: [
        // {id: 0, name: "leavesData", icon: "leaves-grey-solo-9", primary: "leaves_primary", secondary: "leaves_secondary", number: 77, denominator: "", suffix: ""},
        // {id: 1, name: "savingsData", icon: "coins", primary: "energy_primary", secondary: "energy_secondary", number: 12, denominator: "", suffix: "€"}
      ],

      rankingBarElements: [
        // {id: 0, name: "community", icon: "community-grey-solo-rad", primary: "community_primary", secondary: "community_secondary", number: 989, denominator: "/ 1219", suffix: ""},
        // {id: 1, name: "activity", icon: "activity-grey-solo-rad", primary: "interaction_primary", secondary: "interaction_secondary", number: 177, denominator: "/ 200", suffix: ""},
      ],
      performanceBarElements: [
        // {id: 0, name: "electricity", icon: "electricity-grey-solo-rad", primary: "energy_primary", secondary: "energy_secondary", number: 989, denominator: "", suffix: "%"},
        // {id: 1, name: "heat", icon: "heating-grey-solo-rad", primary: "heating_primary", secondary: "heating_secondary", number: 177, denominator: "", suffix: "kWh"},
      ],
      barActualElements: [
        {id: 0, name: "electricity", icon: "iconpic_strom", primary: "energy_primary", secondary: "energy_secondary", number: 0, denominator: "", suffix: "kW"},
        {id: 1, name: "cashback", icon: "iconpic_cashback", primary: "grey", secondary: "black", number: 25, denominator: "", suffix: "°C"},
        {id: 2, name: "heat", icon: "iconpic_heating", primary: "heating_primary", secondary: "heating_secondary", number: 0, denominator: "", suffix: "kW"}
      ],

      info_message: ["Hi, i am the Green Leave. I help you to contribute to a better environment."],
  
      energySavingTips: [
      {
        len: 2,
        text1: "Use energy-saving light bulbs and turn off the lights when,",
        text2: "leaving a room."
      },
      {
        len: 2,
        text1: "Turn off your electrical appliances when not in use. Use a",
        text2: "power strip with an on/off switch to turn off multiple devices at once."
      },
      {
        len: 2,
        text1: "Make use of natural sunlight by opening your windows during",
        text2: "the day."
      },
      {
        len: 2,
        text1: "Use curtains to keep your rooms insulated in winter and prevent",
        text2: "overheating in summer."
      },
      {
        len: 2,
        text1: "Use energy-efficient household appliances and check the energy",
        text2: "label before purchasing."
      },
      {
        len: 1,
        text1: "Wash your clothes at lower temperatures to save energy.",
        text2: ""
      },
      {
        len: 2,
        text1: "Ensure your house is well insulated and close doors and windows",
        text2: "to prevent drafts."
      },
      {
        len: 2,
        text1: "Reduce water consumption in your household to save energy used",
        text2: "for water heating."
      },
      {
        len: 2,
        text1: "Remove unused chargers from the socket, as they can still consume",
        text2: "energy even when not in use."
      },
      {
        len: 2,
        text1: "Utilize renewable energy sources, such as solar or wind energy,",
        text2: "to meet your energy needs."
      },
      {
        len: 2,
        text1: "Unplug appliances like TVs and computers when not in use,",
        text2: "as they can continue to use energy even when turned off."
      },
      {
        len: 2,
        text1: "Use a programmable thermostat to regulate the temperature in ",
        text2: "your home and save energy."
      },
      {
        len: 2,
        text1: "Take shorter showers and install low-flow showerheads to reduce ",
        text2: "hot water usage."
      },
      {
        len: 2,
        text1: "Plant trees and shrubs around your home to provide shade in the ",
        text2: "summer and insulation in the winter."
      },
      {
        len: 2,
        text1: "Cook with lids on pots and pans to reduce cooking time and save",
        text2: "energy."
      },
      {
        len: 2,
        text1: "Avoid using your oven for small meals and instead use a microwave",
        text2: "or toaster oven."
      },
      {
        len: 2,
        text1: "Air dry your dishes and clothes instead of using the dishwasher",
        text2: "and dryer."
      },
      {
        len: 2,
        text1: "Keep your fridge and freezer full, as they use less energy when",
        text2: "full than when empty."
      },
      {
        len: 2,
        text1: "Use a clothesline or drying rack to dry your clothes instead",
        text2: "of using a dryer."
      },
      {
        len: 1,
        text1: "Turn down the temperature on your water heater",
        text2: "to save energy."
      },
      {
        len: 2,
        text1: "Use rechargeable batteries to reduce waste and save money",
        text2: "in the long run."
      }
    ],

      actualMessage: 0
    };
  },
  created: async function () {
    if (!this.ObsUserData) {
      this.$store.commit("user/SET_OBS_USER_DATA", {obs_settings: this.mySelf.obs_settings})
    }
    this.generateTopBarElements();
    this.generateMainBarElements();
    this.generateRankingBarElements();
    this.generatePerformanceBarElements();

    //this.$store.state.elements.rankingBarElements = this.rankingBarElements;
    this.$store.commit("SET_ELEMENTS_RANKING", this.rankingBarElements);
    //this.$store.state.elements.performanceBarElements = this.performanceBarElements;
    this.$store.commit("SET_ELEMENTS_PERFORMANCE", this.performanceBarElements);
    //this.$store.state.elements.barActualElements = this.barActualElements;
    this.$store.commit("SET_ELEMENTS_ACTUAL", this.barActualElements);

    await this.getIcons();
    await this.getScreens();
    
    //this.myIssues = this.mainIssues;
    const screen = _.find(this.Screens, {name: 'mainScreen'});
    const icon = _.find(this.Icons, {name: 'main'});
    icon.screenname = screen.name;
    icon.parent = screen.parent;
    
    this.radialClick(icon);

    //await this.getWeatherDaily({"data.start": {$gte: parseInt(this.$moment.utc().startOf("day").format("X")), $lte: parseInt(this.$moment.utc().add(0,"days").endOf("day").format("X"))}});
    //ToDo: Set TimeZone from DB
    await this.getWeatherDaily({"data.start": {$gte: parseInt(this.$moment().tz('Europe/Vienna').startOf("day").format("X"))}, "data.end": {$lte: parseInt(this.$moment().tz('Europe/Vienna').add(0,"days").endOf("day").format("X"))}});
    if (this.ForeCast.statusWeather == "failure") {
      this.logout();
    }
    //console.log("Weather loaded ", this.ForeCast.statusWeather, this.WeatherDaily, this.WeatherDaily[0]);
    console.log("Weather loaded");
    
    await this.getSolarFc({"timestamp": {$gte: this.$moment().startOf("day").format("X")}});
    console.log("SolarFC loaded");

    await this.calcSolarFcLong();
    console.log("Calculation SolarFC done");

 
  },
  
  destroyed() {
    try {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
      }
      if (this.refreshDataInterval) {
        clearInterval(this.refreshDataInterval);
        this.refreshDataInterval = null;
      }
    } catch (e) {
      //
    }
  },
  
  updated() {},

  async mounted() {

    // const old = console.log;
    // const logger = document.getElementById('log');
    // console.log = function () {
    //   for (var i = 0; i < arguments.length; i++) {
    //     if (typeof arguments[i] == 'object') {
    //         logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(arguments[i], undefined, 2) : arguments[i]) + '<br />';
    //     } else {
    //         logger.innerHTML += arguments[i] + '<br />';
    //     }
    //   }
    // }

    console.log("Starting OBS App");
    // await this.getLocation({});
    if (this.$store.state.selectedLocation) {
      this.selectedLocation = this.$store.state.selectedLocation;
    } else {
      this.selectedLocation = this.Locations[0];
      //this.$store.state.selectedLocation = this.Locations[0];
      this.$store.commit("SET_LOCATION", this.Locations[0]);
    }
    // await this.getIssues({
    //   user: this.mySelf._id,
    //   location: this.$store.state.selectedLocation._id,
    //   active: true,
    // });
    // this.myIssues = this.Issues.filter((obj) => {
    //   return obj.user.includes(this.mySelf._id) && obj.active;
    // });
    //await this.getUserDevices();

    // await this.getSmApi({});
    await this.getGlobal();
    console.log("Got Global");

    // const heatingType = this.Global.deviceTypes.find( obj => {
    //   return obj.value == "heat"
    // });
    // const electrityMeterType = this.Global.deviceTypes.find( obj => {
    //   return obj.value == "electricity meter"
    // });
    //await this.getPowerDevicesOnly({type: "heat control"});
    //console.log("Devices loaded");

    await this.getLocation();
    console.log("Got Location");

    await this.generateScreenIcons();
    console.log("Generated ScreenIcons");

    await this.generateHeatingBarElements();
    console.log("Generated Heating Elements");
    
    await this.getAllUserBenchmarks();
    console.log("Got Scores done ");
    if (this.myHeatingScore) {
      let findElement = this.topBarElements.find(obj => {return obj.name == "heatData"});
      if (findElement) {
        findElement.number = this.myHeatingScore.overall;
      }
      findElement = this.topBarElements.find(obj => {return obj.name == "communityData"});
      if (findElement) {
        findElement.number = this.myHeatingScore.overallRank;
      }
      // this.topBarElements[3].number = this.myHeatingScore.overall;
      // this.topBarElements[0].number = this.myHeatingScore.overallRank;
    }
    

    this.refresh();
    this.refreshInterval = setInterval(this.refresh, 10000);
    this.refreshData();
    this.refreshDataInterval = setInterval(this.refreshData, 10000);

    //console.log("Mode: ", process.env.NODE_ENV)
    if (process.env.NODE_ENV != "production") {
      this.loading_data = false; 
    }

  },

  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),
    ...mapActions("user", ["getObsUserData"]),
    // ...mapActions("location", ["getLocation"]),
    // ...mapActions("issue", ["getIssues"]),
    ...mapActions("icon", [
      "getIcons",
      "createIcon",
      "updateIcon",
      "deleteIcon",
    ]),
    ...mapActions("screen", [
      "getScreens",
      "createScreen",
      "updateScreen",
      "deleteScreen",
    ]),
    ...mapActions("forecast", [
      "getWeatherDaily",
      "getSolarFc",
    ]),

    // ...mapActions("sm_api", ["getSmApi"]),
    ...mapActions(
      "location", [
        "getLocation",
        "deleteLocation",
        "updateLocation",
        "createLocation",
        "deactivateLocation"
        ]
      ),
    ...mapActions(
      "global", ["getGlobal"]
    ),    
    ...mapActions("dataset", [
      "getAllUserBenchmarks"
    ]),
    // ...mapActions("userdevice", [
    //   "getUserDevices",
    // ]),
    // ...mapActions("device", [
    //   "getPowerDevicesOnly",
    // ]),

    generateTopBarElements() {
      if (this.ObsUserData.obs_settings) {
        let topBarElements =  [];
        let arr = [ 
          {id: 0, name: "communityData", icon: "community-grey-solo-9", primary: "community_primary", secondary: "community_secondary", number: 0, denominator: "", suffix: "", prefix: "#"},
        //{id: 0, name: "community", icon: "iconpic_groundfloor", primary: "community_primary", secondary: "community_secondary", number: 989, denominator: "", suffix: ""},
          {id: 1, name: "activityData",  icon: "activity-grey-solo-9", primary: "interaction_primary", secondary: "interaction_secondary", number: 0, denominator: "", suffix: ""},
          {id: 2, name: "electricityData", icon: "electricity-grey-solo-9", primary: "energy_primary", secondary: "energy_secondary", number: 0, denominator: "", suffix: ""},
          {id: 3, name: "heatData", icon: "heating-grey-solo-9", primary: "heating_primary", secondary: "heating_secondary", number: 0, denominator: "", suffix: ""}
        ];
        for (let obj of arr) {  
          if (this.ObsUserData.obs_settings[obj.name]) {
            topBarElements.push(obj);
          }
        }
        this.topBarElements = _.sortBy(topBarElements, "id");
      } 
      
    },

    generateMainBarElements() {
      if (this.ObsUserData.obs_settings) {
        let mainBarElements =  [];
        let arr = [ 
          {id: 0, name: "leavesData", icon: "leaves-grey-solo-9", primary: "leaves_primary", secondary: "leaves_secondary", number: 77, denominator: "", suffix: ""},
          {id: 1, name: "savingsData", icon: "coins", primary: "energy_primary", secondary: "energy_secondary", number: 12, denominator: "", suffix: "€"}
        ];
        for (let obj of arr) {  
          if (this.ObsUserData.obs_settings[obj.name]) {
            mainBarElements.push(obj);
          }
        }
        this.mainBarElements = _.sortBy(mainBarElements, "id");
      } 
    },

    generateRankingBarElements() {
      if (this.ObsUserData.obs_settings) {
        let rankingBarElements =  [];
        let arr = [ 
          {id: 0, name: "community", icon: "community-grey-solo-rad", primary: "community_primary", secondary: "community_secondary", number: 989, denominator: "/ 1219", suffix: ""},
          {id: 1, name: "activity", icon: "activity-grey-solo-rad", primary: "interaction_primary", secondary: "interaction_secondary", number: 177, denominator: "/ 200", suffix: ""},
        ];
        for (let obj of arr) {  
          if (this.ObsUserData.obs_settings[obj.name]) {
            rankingBarElements.push(obj);
          }
        }
        this.rankingBarElements = _.sortBy(rankingBarElements, "id");
      } 
    },    

    generatePerformanceBarElements() {
      if (this.ObsUserData.obs_settings) {
        let performanceBarElements =  [];
        let arr = [ 
          // {id: 0, name: "electricity", icon: "electricity-grey-solo-rad", primary: "energy_primary", secondary: "energy_secondary", number: 989, denominator: "", suffix: "%"},
          // {id: 1, name: "heat", icon: "heating-grey-solo-rad", primary: "heating_primary", secondary: "heating_secondary", number: 177, denominator: "", suffix: "kWh"},
          {id: 0, name: "electricityPerformance", icon: "electricity-grey-solo-rad", primary: "energy_primary", secondary: "energy_secondary", number: 989, denominator: "", suffix: "%"},
          {id: 1, name: "heatingPerformance", icon: "heating-grey-solo-rad", primary: "heating_primary", secondary: "heating_secondary", number: 177, denominator: "", suffix: "kWh"},
        ];
        for (let obj of arr) {  
          if (this.ObsUserData.obs_settings[obj.name]) {
            performanceBarElements.push(obj);
          }
        }
        this.performanceBarElements = _.sortBy(performanceBarElements, "id");
      } 
    },

    generateHeatingBarElements() {
      return new Promise((resolve, reject) => {
        try {
          const heatingScreen = _.find(this.Screens, {name: "heatingScreen"});
          //Find only Objects with no child 
          //const rooms = _.filter(heatingScreen.icons, {})
          
        
          let haType = _.find(this.Global.locationTypes, {value: "heatarea"});
          if (haType) {
            haType = haType._id;
          }
        
          let heatareas = [];
          for (let icon of heatingScreen.icons){
            let loc = _.find(this.Locations, {_id: icon.location});
            //console.log("loc: ", loc, icon);
            if (loc.type == haType) {
              heatareas.push(icon);
            }
          }

          // console.log("HAs: ", heatareas);
              
          let _arr = [];
          // if (heatareas.length > 0) {
            let _len = 6;
            if (heatareas.length < _len) {
              _len = heatareas.length;
            }
            for (let i = 0; i < _len; i++) {  
              let _obj = {};
              //if (heatingScreen.icons[i]) {
                const icon = _.find(this.Icons, {_id: heatareas[i].icon});
                if (icon) {
                  _obj.icon = icon.icon;           
                } else {
                  _obj.icon = this.Icons[0].icon;
                }
                //console.log("heatarea: ", heatareas[i], _obj)
                _obj.actTemp = heatareas[i].actTemp;
                _obj.actHum = heatareas[i].actHum;
                _obj.bat = heatareas[i].bat;
                _obj.actCo2 = heatareas[i].actCo2;
                _obj.actTvoc = heatareas[i].actTvoc;
                _obj.actLux = heatareas[i].actLux;
                _obj.roomname = heatareas[i].roomname;
                _obj.timestamp = heatareas[i].timestamp;
                _arr.push(_obj);
              //}
            }
            //console.log(_arr);
            this.$store.commit("SET_ELEMENTS_HEATING", _arr);
          // }
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },

    topMenuClick(obj) {
      // let rnd = Math.random(0,1);
      // if(rnd >= 0.5) this.topBarElements[payload.id].number += 100; 
      // else this.topBarElements[payload.id].number -= 100; 
      // console.log(obj.name)
      const icon = _.find(this.Icons, {name: obj.name});
      //console.log("topMenuClick ", icon, this.Icons, obj.name);
      if (icon) {
        const page =  _.find(this.Screens, {_id: icon.screen});
        // console.log("topMenuClick page ",page, icon);
        this.radialIcon = icon;

        if (page) {
          //this.$store.state.selectedScreen = page;
          this.$store.commit("SET_SELECTED_SCREEN", page);
        }
      }
    },

    // mainMenuClick(payload) {
    //   // let rnd = Math.random(0,1);
    //   // if(rnd >= 0.5) this.mainBarElements[payload.id].number += 100; 
    //   // else this.mainBarElements[payload.id].number -= 100; 
    // },

    changesInElectricityScreen(payload) {
      // console.log(payload)
      let findElement = this.topBarElements.find(obj => {return obj.name == "electricityData"});
      if (findElement) {
        findElement.number = payload;
      }
      // findElement = this.topBarElements.find(obj => {return obj.name == "activityData"});
      // if (findElement) {
      //   //findElement.number += 1;
      // }

      // this.topBarElements[2].number = payload;
      // this.topBarElements[1].number += 1;
      // this.mainBarElements[0].number += parseInt(payload / 10);
    },

    changesInHeatingScreen(payload) {
      // let findElement = this.topBarElements.find(obj => {return obj.name == "activityData"});
      // if (findElement) {
      //   findElement.number += 1;
      // }
      // this.topBarElements[1].number += 1;
    },

    changesInShadingScreen(payload) {
      // let findElement = this.topBarElements.find(obj => {return obj.name == "activityData"});
      // if (findElement) {
      //   findElement.number += 1;
      // }
      // this.topBarElements[1].number += 1;
    },

    changesInSettingsScreen(payload) {
      // 
    },

    radialClick(obj) {    
      
      // this.showMsgBox = false;
      //console.log(obj)
      const page =  _.find(this.Screens, {_id: obj.screen});
      this.radialIcon = obj;

      if (page) {
        // this.$store.state.selectedScreen = page;
        this.$store.commit("SET_SELECTED_SCREEN",page);
      }
    },

    generateIssues(screen) {
      //console.log("generateIssues ", screen)
      const iconsScreen = screen.icons;
      const sortIconsScreen = _.sortBy(iconsScreen, "sortorder");
      let myIssues = [];
      
      for (let screenIcon of sortIconsScreen) {
        // if (screenIcon.name)
        //console.log("generateIssues ", screenIcon);
        
        let icon = {};
        try {
          icon = JSON.parse(JSON.stringify(_.find(this.Icons, {_id: screenIcon.icon})));
        } catch (err) {
          console.log(err);
          icon = JSON.parse(JSON.stringify(this.Icons[0]));
        }
        icon.parent = screenIcon.parent;
        icon.sortorder = screenIcon.sortorder;
        icon.screenname = screen.name;
        icon.location = screenIcon.location;
        icon.values = {};
        icon.actTemp = screenIcon.actTemp;
        icon.actHum = screenIcon.actHum;
        icon.bat = screenIcon.bat;
        icon.actCo2 = screenIcon.actCo2;
        icon.actTvoc = screenIcon.actTvoc;
        icon.actLux = screenIcon.actLux;
        icon.roomname = screenIcon.roomname;
        //console.log("generateIssues ", icon.name)
        
        //Filter settings
        if (this.ObsUserData.obs_settings) {
          if (this.ObsUserData.obs_settings[icon.name] || typeof this.ObsUserData.obs_settings[icon.name] == "undefined") {
            myIssues.push(icon);
          }
        } 
        // else {
        //   myIssues.push(icon);
        // }
        
      }
      //console.log("myIssues  ", myIssues)
      this.myIssues = JSON.parse(JSON.stringify(myIssues));
    },

    async refresh() {
      // return setInterval(async () => {

        let alerts = [];
        try {
          this.WeatherDaily[0].data.alerts;
        } catch(err) {
          console.log(err);
        }
        if (alerts.length > 0) {
          let msg = ["Weather Warning: "];
          for (let _msg of alerts) {
            msg.push(_msg.description);
          }
          // console.log(msg);
          this.info_message = msg;
        } else {
          // this.info_message=["Another Message #" + parseInt(Math.random() * 100)];
          //this.info_message = [this.energySavingTips[parseInt(Math.random() * 20)]];
          
          if (this.energySavingTips[this.actualMessage].len == 1) {
            this.info_message = [this.energySavingTips[this.actualMessage].text1];
          } else {
            this.info_message = [this.energySavingTips[this.actualMessage].text1, this.energySavingTips[this.actualMessage].text2];
          }
          this.actualMessage++;
          //console.log(this.actualMessage, this.energySavingTips.length);
          if (this.actualMessage >= this.energySavingTips.length) {
            this.actualMessage = 0;
          }
        }

        let findElement = this.mainBarElements.find(obj => {return obj.name == "savingsData"});
        if (findElement) {
          findElement.number = this.myHeatingScore.overallRank;
          const _add = 0.03;
          const _number = Math.round((Number(findElement.number) + _add) * 100) / 100;
          // let _sNumber = _number.toFixed(2);
          //console.log("0: ",findElement.number, _number)
          if (_number % 1 == 0) {
            findElement.number = _number;
          } else {
            findElement.number = _number;
          }
          //console.log(findElement.number, _number)
        }
      // }, _interval);
    },

    async refreshData() {
      this.$store.commit("SET_RADIAL_LOADING", true);
      try {
      //return setInterval(async () => {
        //console.log("Start refreshData Interval")
        await this.getObsUserData();
        let findElement = this.topBarElements.find(obj => {return obj.name == "activityData"});
        if (findElement && this.ObsUserData.obs_data) {
          const user_activity = this.ObsUserData.obs_data.find(obj => {return obj.monthlyActivity >= 0});
          //console.log("user_activity: ",user_activity, this.ObsUserData.obs_data)
          if (user_activity) {
            findElement.number = user_activity.monthlyActivity;
          } else {
            findElement.number = 0;
          }
        }

        await this.getWeatherDaily({"data.start": {$gte: parseInt(this.$moment().startOf("day").format("X"))}, "data.end": {$lte: parseInt(this.$moment().add(0,"days").endOf("day").format("X"))}});
        if (this.ForeCast.status == "failure") {
          this.logout();
        }
        await this.getSolarFc({"timestamp": {$gte: this.$moment().startOf("day").format("X")}});
        await this.calcSolarFcLong();
        
        await this.getLocation();

        //await this.getIcons();
        await this.getScreens();
        //console.log("SCREEN: ", JSON.parse(JSON.stringify(this.Screens)));
        await this.generateScreenIcons();
        //console.log("SCREEN with Icons: ", JSON.parse(JSON.stringify(this.Screens)));
        await this.generateHeatingBarElements();

        this.$store.commit("SET_RADIAL_LOADING", false);
      //}, _interval);
      } catch (err) {
        //
      }
      this.$store.commit("SET_RADIAL_LOADING", false);
    },


    async calcSolarFcLong() { 
      let forecast = [];
      let count = 0;
      let last_count = 0;
      for (let idx in this.EnergyBarTitles) { 
        const _hour = parseInt(this.EnergyBarTitles[idx].substring(0,2));
        last_count = count;
        count = (_hour * 4);   
        let sum = 0;
        for (let counter = last_count; counter < count; counter++) {
          sum += this.SolarFc[0].data[counter].value / 100;
        }
        let arr =[parseInt(sum),0];
        forecast.push(arr);
      }
      //this.$store.state.elements.energyBarElementsLong = forecast;
      this.$store.commit("SET_ELEMENTS_ENERGY_LONG", forecast);
    },

    generateScreenIcons() {
      return new Promise((resolve, reject) => {
        try {
         
        let haType = _.find(this.Global.locationTypes, {value: "heatarea"});
        if (haType) {
          haType = haType._id;
        }
        let roomType = _.find(this.Global.locationTypes, {value: "room"});
        if (roomType) {
          roomType = roomType._id;
        }
      
        let floorType = _.find(this.Global.locationTypes, {value: "floor"});
        if (floorType) {
          floorType = floorType._id;
        }
        // console.log("roomType ",roomType);
        let buildingType = _.find(this.Global.locationTypes, {value: "building"});
        if (buildingType) {
          buildingType = buildingType._id;
        }
        
        let electricityDeviceType = _.find(this.Global.locationTypes, {value: "electricity device"});
        if (electricityDeviceType) {
          electricityDeviceType = electricityDeviceType._id;
        }
        for (let screen of this.Screens) {

          let rooms = [];     

          if (screen.name == "heatingScreen") {   
            screen.icons = [];     
            rooms = _.filter(this.Locations, (obj) => {
              //return (obj.type == haType || obj.type == floorType) && obj.active == true;
              return (obj.type == haType || obj.type == floorType) && obj.active == true;
            });
            //console.log("Rooms: ", rooms, haType)
          } else if (screen.name == "shadingScreen") {   
            screen.icons = [];     
            rooms = _.filter(this.Locations, (obj) => {
              return (obj.type == roomType || obj.type == floorType) && obj.active == true;
            });
          } else if (screen.name == "electricityScreen") {
            screen.icons = [];
            let electricityDevices = _.filter(this.Locations, (obj) => {
              return (obj.type == electricityDeviceType && obj.active == true) ;
            });

            let elArr = _.map(electricityDevices, "parent");
            
            rooms = _.filter(this.Locations, (obj) => {
              return (obj.type == floorType || (obj.type == roomType && elArr.includes(obj._id))) && obj.active == true;
            });

            rooms = rooms.concat(electricityDevices);
            //console.log("elArr: ", elArr, electricityDevices, rooms);
          }

          
          for (let room of rooms) {
            if (room.active) {
              let parent = _.find(this.Locations, {_id: room.parent});
              if (parent.type == buildingType) { parent.icon = null; }

              if (screen.name == "heatingScreen") {
                if (parent.type == roomType) {    //jump over room
                  parent = _.find(this.Locations, {_id: parent.parent});
                }
              }

              if (room.values && screen.name == "heatingScreen") {
                //console.log("Room: ", room, JSON.parse(JSON.stringify(this.Locations)))
                screen.icons.push({icon: room.icon, parent: parent.icon, sortorder: room.sortorder, location: room._id, actTemp: room.values.actTemp, actHum: room.values.actHum, bat: room.values.bat, actCo2: room.values.actCo2, actTvoc: room.values.actTvoc, actLux: room.values.actLux, roomname: room.name, timestamp: room.values.timestamp});
              } else {
                screen.icons.push({icon: room.icon, parent: parent.icon, sortorder: room.sortorder, location: room._id, actTemp: null, actHum: null, bat: null, actCo2: null, actTvoc: null, actLux: null, roomname: room.name, timestamp: null});
              }
            }
          }
          
          // console.log(screen.icons)
        }
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },

  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      // Locations: ({ location }) => location.Locations,
      // Locations: ({ auth }) => auth.mySelf.locations,
      // Issues: ({ issue }) => issue.Issues,
      Icons: ({ icon }) => icon.Icons,
      IconState: ({ icon }) => icon,
      Screens: ({ screen }) => screen.Screens,
      ScreenState: ({ screen }) => screen,
      AllLoaded: ( state ) => state.allLoaded,
      SelectedScreen: ( state ) => state.selectedScreen,
      SolarFc: ({ forecast }) => forecast.SolarFc,
      EnergyBarTitles: ( state ) => state.elements.energyBarElementsLongTitles,
      WeatherDaily: ({ forecast }) => forecast.WeatherDaily,
      ForeCast: ({ forecast }) => forecast,
      // Locations: ({ sm_api }) => sm_api.Locations,
      // Global: ({ sm_api }) => sm_api.Global,
      Global: ({ global }) => global.Global,
      Locations: ({ location }) => location.Locations,
      Benchmarks : ({ dataset }) => dataset.AllUserBenchmarks,
      ObsUserData : ({ user }) => user.obsUserData,
    }),
    myHeatingScore() {
      const heatingType = this.Global.deviceTypes.find( obj => {
        return obj.value == "heat"
      })
      const myBenchmark = this.Benchmarks.find( obj => {
        return obj.user_id == this.mySelf._id && obj.type == heatingType._id
      })
      //console.log("myHeatingScore: ",heatingType, myBenchmark, JSON.stringify(this.mySelf._id))
      return myBenchmark;
    }
    
  },
  watch: {
    AllLoaded() {
      this.loading_data = false;
    },
    SelectedScreen(page) {
      //console.log("SelectedScreen ",  page)
      this.showMsgBox = false;
      this.generateIssues(page);
      
      if (page.name == 'mainScreen') {     
        this.showMsgBox = true;
      }
      else if (page.name == 'electricityScreen') {
        this.radialIcon = this.myIssues[this.myIssues.length - 1];
      }
      else if (page.name == 'heatingScreen') {
        this.radialIcon = this.myIssues[this.myIssues.length - 1];
      }
      else if (page.name == 'shadingScreen') {
        this.radialIcon = this.myIssues[this.myIssues.length - 1];
      }
    }
  },
};
</script>


<style lang='scss'>
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3a3a3a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
}
.box {
  border: solid 1px red;
}
.greybox {
  border: solid 1px #5a5a5a;
}

.bottom-menu {
  position: absolute;
  bottom: 0px;
  // left: 45%;
}

.bottom-menu-left {
  position: absolute;
  bottom: 1%;
  left: 2%;
}

// .bottom-menu-center {
//   position: absolute;
//   bottom: 0px;
//   center: 0%;
// }

.bottom-menu-right {
  position: absolute;
  bottom: 5px;
  right: 2%;
}

.logo {
  position: fixed;
  z-index: 9998;
  width: 100px;
  top: 50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -50px;
}

.logo-progress {
  position: fixed;
  z-index: 9998;
  width: 100px;
  top: 75%;
  margin-top: -100px;
  left: 50%;
  margin-left: -50px;
}

.logo-console {
  position: fixed;
  z-index: 9998;
  bottom: 1%;
  left: 1%;
}

// .refresh-bar {
//  position: fixed;
//  top: 1%;
// }

.logo-overlay {
  position: fixed;
  z-index: 9997;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: black;
}

.logo-button {
  position: fixed;
  z-index: 9999;
  bottom: 1%;
  right: 1%;
}

.radial-menu-main {
    visibility: visible !important;
    position: absolute !important;
}


</style>

