const REQUEST_WEATHERDAILY = state => {
  state.statusWeather = "fetching";
};

const SUCCESS_REQUEST_WEATHERDAILY = (state, { body }) => {
  state.WeatherDaily = body;
  state.statusWeather = "success";
};

const FAILURE_REQUEST_WEATHERDAILY = state => {
  state.statusWeather = "failure";
};

const REQUEST_SOLARFC = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_SOLARFC = (state, { body }) => {
  state.SolarFc = body;
  state.status = "success";
};

const FAILURE_REQUEST_SOLARFC = state => {
  state.status = "failure";
};


export default {
  REQUEST_WEATHERDAILY,
  SUCCESS_REQUEST_WEATHERDAILY,
  FAILURE_REQUEST_WEATHERDAILY,
 
  REQUEST_SOLARFC,
  SUCCESS_REQUEST_SOLARFC,
  FAILURE_REQUEST_SOLARFC,
};
