import api from "../../../api";

export default {
  async getIcons({ commit }, filter) {
    commit("REQUEST_ICONS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_ICONS",
        await api.getIcons(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_ICONS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteIcon({ commit }, id) {
    commit("REQUEST_DELETE_ICON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_ICON",
        await api.deleteIcon(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_ICON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateIcon({ commit }, item ) {
    commit("REQUEST_UPDATE_ICON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_ICON",
        await api.updateIcon(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_ICON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createIcon({ commit }, item ) {
    commit("REQUEST_CREATE_ICON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_ICON",
        await api.createIcon( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_ICON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
