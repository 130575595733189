import api from "../../../api";

export default {
  async getDatasets({ commit }, filter) {
    commit("REQUEST_DATASETS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DATASETS",
        await api.getDatasets(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DATASETS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  
  // async deleteDataset({ commit }, id) {
  //   commit("REQUEST_DELETE_DATASET");
  //   commit("SET_LOADING", true, { root: true });
  //   try {
  //     commit(
  //       "SUCCESS_REQUEST_DELETE_DATASET",
  //       await api.deleteDataset(id)
  //     );
  //   } catch (err) {
  //     commit("FAILURE_REQUEST_DELETE_DATASET");
  //   } finally {
  //     commit("SET_LOADING", false, { root: true });
  //   }
  // },
  async updateDataset({ commit }, item ) {
    commit("REQUEST_UPDATE_DATASET");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_DATASET",
        await api.updateDataset(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_DATASET");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createDataset({ commit }, item ) {
    commit("REQUEST_CREATE_DATASET");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_DATASET",
        await api.createDataset( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_DATASET");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },

  async getDatasetScore({ commit }, filter) {
    commit("REQUEST_DATASETGETSCORE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DATASETGETSCORE",
        await api.getDatasetScore(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DATASETGETSCORE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },

  async getAllUserBenchmarks({ commit }, filter) {
    commit("REQUEST_DATASETGETALLUSERBENCHMARKS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DATASETGETALLUSERBENCHMARKS",
        await api.getAllUserBenchmarks(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DATASETGETALLUSERBENCHMARKS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
