<template>
  <v-container class="pa-0 ma-0 " :style="cssVars" >
    <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3" >  
        Votings
      </v-col>
    </v-row>
    <v-row class="ml-0 mr-3 mt-3"> 
      <v-col class="text-center my-n3">  
        Already voted today?
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 mt-5 mb-n1 rankingStyle" >
      <v-col class="">
        <v-row v-for="vote in myVotesList" :key="vote.id" class="voteBox my-1 ">     
          <v-col cols="7" :class="[arrayOfColours[findVoteColor(vote.closed)]]" @click="openVotingDetails(vote)">
            <div class="pb-2">Nr. {{vote.id}}</div>
            <div class="font-weight-black" >      
              {{vote.title}}
            </div>
            <div class="pt-2">Start: {{vote.start}}</div>
            <div>End: {{vote.end}}</div>
            <div class="pt-2">Details
              <v-icon v-if="vote.showDescription">expand_less</v-icon>
              <v-icon v-else>expand_more</v-icon>
            </div>
          </v-col>
          <v-col cols="5" :class="[arrayOfColours[findVoteColor(vote.closed)]]">
            <v-radio-group v-model="vote.vote">
              <v-radio
                :readonly="vote.closed"
                v-for="n in 3"
                :key="n"
                :label="voteLabel[n-1]"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="vote.showDescription">
            <div>69% approve<br>23% disapprove<br>8% abstain<br>Status: ongoing</div>
            <div class="pt-2" >{{vote.description}}</div>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>

   
   
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";


export default {
  name: "VotingScreen",
  props: { },
  components: {
   
  },
  data() {
    return {
      myVotesList: [
        {id: 69, title: "PV system extension", description: "The PV system has shown a 10% drop in yield over the last few months. The cause is that 4 panels are damaged. As a delegated body we recommend 'PV-Express' - 1350€ to do the replacement.", vote: 0, closed: false, start: "01.05.2023", end: "01.08.2023", showDescription: false},
        {id: 68, title: "Liability Insurance", description: "blbla", vote: 0, closed: false, start: "15.05.2023", end: "15.07.2023", showDescription: false},
        {id: 67, title: "New cleaning company for PV", description: "blbla", vote: 1, closed: true, start: "01.04.2023", end: "15.05.2023", showDescription: false},
      ],
      voteLabel: ["approve", "disapprove", "abstain"],
      arrayOfColours: ['greenClass', 'greyClass'],
    };
  },
  created: function () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  },
  async mounted() { 

  },
  methods: {
    findVoteColor(vote) {
      return vote ? 1 : 0
    }, 
    openVotingDetails(vote) {
      vote.showDescription = ! vote.showDescription;
    }
  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      Global: ({ global }) => global.Global,
      // Votings : ({ votes }) => dataset.Votings,
    }),

    
   
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    },

    themeMode() {
      if (this.$vuetify.theme.isDark) {
        return "dark";
      } else {
        return "light"
      }
    }
  },
  watch: {
    themeMode(theme) {
      // console.log("Theme changed ", theme);
      
    }
  },
};
</script>

<style lang='scss'>

.rankingStyle {
  width: 80%;
}

.greenClass {
  color: lightgreen !important;
}

.greenClass .v-label {
  color: lightgreen !important;
}

.greyClass {
  color: grey !important;
}

.greyClass .v-label {
  color: grey !important;
}

.greyClass .v-icon {
  color: grey !important;
}

.voteBox {
  border-radius: 4px;
  border: solid 1px #5a5a5a;
}

.v-icon {
  font-size: 20px;
}

.v-label {
  font-size: 15px;
}
</style>