<template>
  <v-container class="pa-0 ma-0" :style="cssVars">
    <div class="logo-overlay-loading" v-if="radialLoading">
    </div>
    <v-row class=" ml-0 mr-3 mb-0 pt-2">
      <v-col cols="8" class="pa-0">
        <v-row v-if="selectedRadio == radioGroup[0]" class="align-center pa-0 ">
          <v-col class="d-flex  my-n3" cols="auto">
            <v-icon @click="dayPrev" x-large>mdi-chevron-left</v-icon>
          </v-col>
          <v-col class="text-center my-n3" v-if="isStandard" @click="setToday">  
            {{formatedDate}}
          </v-col>
          <v-col class="text-center my-n3 red--text" v-else @click="setToday">  
            {{formatedDate}}
          </v-col>
          <v-col class="d-flex  my-n3" cols="auto">
            <v-icon @click="dayNext" x-large>mdi-chevron-right</v-icon>
          </v-col>
        </v-row>
        <v-row  v-else>
          <v-btn-toggle
            v-model="selectedDaysIndex"
            mandatory
          >
            <v-btn v-for="item of weekDayGroup" :key="item">
              {{item}}
            </v-btn> 
          </v-btn-toggle>
        </v-row>  
      </v-col>
      <v-col cols="4" class="pa-0">
        <!-- <v-btn @click="individualSelected = !individualSelected">
          <div v-if="individualSelected">Edit Std</div>
          <div v-else>Edit individualSelected</div>
        </v-btn> -->
        <v-radio-group dense hide-details=false mandatory class="pa-0 ma-0 mt-n4" v-model="selectedRadio">
          <!-- <v-radio 
            v-for="n of radioGroup"
            :key="n"
            :label="`${n}`"
            :value="n"
            color="blue"
          ></v-radio> -->
          <v-radio :value="radioGroup[0]"
            key="0"      
            color="red"
          >
            <template v-slot:label>
              <div class="red--text">{{radioGroup[0]}}</div>
            </template>
          </v-radio>
          <v-radio :value="radioGroup[1]"
            key="1"     
          >
            <template v-slot:label>
              <div>{{radioGroup[1]}}</div>
            </template>          
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 mb-2 pt-0">
      <v-col cols="12" class="ml-n2 pb-6 mt-n4">
        <v-row > 
          <v-col style="font-size: 25px">
            {{radialIcon.roomname}}
          </v-col>
        </v-row>

        <v-row class="pa-0">
          <v-col cols="4" class="caption ml-2 pt-0 text-center">
            <div style="font-size: 10px;">Temperature</div>
            <div style="font-size: 20px;">{{fix_temp(radialIcon.actTemp, 1)}}°C</div>
          </v-col>
          <v-col cols="4" class="caption ml-n1 pt-0 text-center" v-if="radialIcon.actHum" >
            <div style="font-size: 10px;">Humidity</div>
            <div style="font-size: 20px;">{{fix_temp(radialIcon.actHum, 0)}}%</div>
          </v-col>
          <v-col cols="4" class="caption ml-n1 pt-0 text-center" v-if="radialIcon.bat" > 
            <div style="font-size: 10px;">Voltage</div>
            <div style="font-size: 20px;">{{fix_temp(radialIcon.bat,2)}} V</div>
          </v-col>
          <v-col cols="4" class="caption ml-n1 pt-0 text-center" v-else-if="radialIcon.actCo2" > 
            <div style="font-size: 10px;">CO2 (ppm)</div>
            <div v-if="radialIcon.actCo2 > 2000" style="font-size: 20px;" class="red--text">{{radialIcon.actCo2}}</div>
            <div v-else-if="radialIcon.actCo2 > 1000" style="font-size: 20px;" class="orange--text">{{radialIcon.actCo2}}</div>
            <div v-else style="font-size: 20px;">{{fix_temp(radialIcon.actCo2,0)}}</div>
          </v-col>
        </v-row>

        <v-row class="pa-0">       
          <v-col cols="4" class="caption text-center pt-0">Flexibility
            <!-- : {{flexibility}} -->
            <v-progress-linear 
              color="energy_primary"
              v-model="flexibility"
              :height="10">
              <template v-slot:default="{ value }" >
                <strong>{{ Math.ceil(value) }}</strong>
              </template>
            </v-progress-linear>
          </v-col>
          <v-col cols="4" class="caption text-center pt-0">Demand
            <!-- : {{energyDemand}} -->
            <v-progress-linear 
              color="heating_primary"
              v-model="energyDemand"
              :height="10">
              <template v-slot:default="{ value }" >
                <strong>{{ Math.ceil(value) }}</strong>
              </template>
            </v-progress-linear>
          </v-col>
          <v-col cols="4" id="score_element" class="caption text-center pt-0">Score
            <!-- : {{toDayEnergyScore}} -->
            <v-progress-linear 
              color="leaves_primary"
              v-model="toDayEnergyScore"
              :height="10">
              <template v-slot:default="{ value }" >
                <strong>{{ Math.ceil(value) }}</strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="caption blue--text" v-if='selectedRadio == "standard"'> Standard settings can only be changed for future days</v-col>
          <v-col class="caption blue--text" v-else>Only future settings can be changed</v-col>
        </v-row> -->
      </v-col>
    </v-row>

    <v-row no-gutters class="" v-for="y of heatingBarY.length" :key="y" >
      <v-col cols="1" style="font-size: 9px;" class="font-weight-black grey--text graphbar mt-2">
        <v-row class="mt-1">
          <v-col cols="12" class="pa-0 text-center">
            {{heatingBarY[y-1]}}
          </v-col>
          <v-col cols="12" class="pa-0 text-center">
            -
          </v-col>
          <v-col cols="12"  class="pa-0 text-center">
            {{calcHeatingBarY[y]}}
          </v-col>
        </v-row>        
      </v-col>
      <v-col v-if="loaded" :cols="heatingBarX.length - 1" style="font-size: 9px;" class="bg-grid font-weight-black grey--text ml-5">
        <vue-slider 
          v-model="sliderValue[y-1]"
          :min="heatingBarX[0]"
          :max="heatingBarX[heatingBarX.length - 1]"
          adsorb
          lazy
          :enableCross="false"
          :minRange="1"
          dotSize="25"
          tooltip="none"
          :height="cssVars.wHeight"
          :width="cssVars.bgSize"
          :interval="1"
          @change="sliderValueChanged"
        >
        <!-- :height="cssVars.wHeight" -->
          <template v-slot:dot="{ value, index }">
            <!-- <div :class="['custom-dot']" :style="'background-color: ' + getDotColor(value, index) + ';'"></div> -->
            <ShowSVG
              :id="index"
              name="iconpic_sliderbubble"
              :title="'heatDot_' + y + index"
              :primary="getDotColor(value, index)"
              secondary="black"
              size="25"
              text=""
              glow="false"
            />
          </template>
          <!-- 
          <template v-slot:process="{ start, end, style, index }">
            <div class="vue-slider-process custom-class" :style="[style, customStyle]">
              a
            </div>
          </template> -->
        </vue-slider>
      </v-col>
    </v-row>
    <v-row no-gutters class="ml-2 mt-3">
      <v-col cols="1">
      </v-col>
      <v-col cols="1" style="font-size: 9px;" class="text-center font-weight-black grey--text graphbar"  v-for="x of heatingBarX.length" :key="x" >
        {{heatingBarX[x-1]}}°C
      </v-col>
    </v-row>  
     <v-alert dismissible prominent :type="alertType" :value="alertOn" @click="alertOn = false"
        >{{alertMsg}}</v-alert
      >
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import ShowSVG from "../components/ShowSVG";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'


export default {
  name: "heatingScreen",
  props: { radialIcon: null },
  components: {
    VueSlider,
    ShowSVG,
  },
  data() {
    return {
      selectedDate: new Date(),
      heatingBarY: ["04:00","07:00","11:00","14:00","18:00","22:00"],
      utcHeatingBarY: [2,5,9,12,16,20],
      heatingBarX: [18,19,20,21,22,23,24,25],
      sliderValue: [],

      individualSelected: true,
      selectedDaysIndex: null,
      weekDayGroup: ["Mon-Thu", "Fri", "Sat-Sun"],
      radioGroup: ["individual","standard"],
      selectedRadio: "individual",
      dateFormat: "DD.MM.YY",
      // watchSlider: false,
      heatingStandard: [],
      isStandard: false,
      deviceTypeId: null,
      loaded: false,
      flexibility: 0,
      energyDemand: 0,
      toDayEnergyScore: 0,
      alertType: "error",
      alertMsg: "",
      alertOn: false,

      scoreElementBottom: 0
    };
  },

  created: async function () {
    for (let temp of this.heatingBarY) {
      this.sliderValue.push([this.heatingBarX[0],this.heatingBarX[this.heatingBarX.length - 1]])
    }
    this.deviceTypeId = (_.find(this.Global.deviceTypes, {value: "heat"}))._id;
    this.createHeatingBarY();
    await this.setToday();  
    
  },

  async mounted() { 
    //await this.getDatasetScore({location: this.radialIcon.location});
    this.loaded = true;
    this.scoreElementBottom = document.getElementById('score_element').getBoundingClientRect().bottom
  },

  methods: {
    ...mapActions("dataset", [
      "getDatasets",
      "createDataset",
      "updateDataset",
      "deleteDataset",
      "getDataSetScore"
    ]),

    fix_temp(temp, digits) {
      if (temp) {
        return temp.toFixed(digits);
      } else {
        //ToDo: Change to N/A
        return "23"
        //return "N/A";
      }
    },

    async setToday() {
      //console.log("SetToday");
      this.selectedDate = this.$moment().format(this.dateFormat);
      await this.loadDatasets();
    },

    sliderValueChanged() {
      this.$emit("menuEvent", 0);
      this.saveGroupDays();
    },

    getDotColor(value, index) {
      if(index % 2 != 0) {
        return "#AA0000";
      } else {
        return "#0000AA";
      }
    },

    createHeatingBarY() {
      this.heatingBarY = [];
      
      for (let i of this.utcHeatingBarY) {
        let hour = String(i + (this.$moment().utcOffset() / 60));
        hour = hour + ":00";
        if (hour.length < 5) {
          hour = "0" + hour;
        }
        this.heatingBarY.push(hour);
      }
    },

    calcHeatingBarY(index) {
      if (this.heatingBarY.length <= index) {
        return this.heatingBarY[0];
      } else {
        return this.heatingBarY[index];
      }
    },

    async dayPrev() {
      // this.watchSlider = false;
      this.selectedDate = this.$moment(this.selectedDate,this.dateFormat).subtract(1, "day").format(this.dateFormat);
      await this.loadDatasets();
    },

    async dayNext() {
      // this.watchSlider = false;
      this.selectedDate = this.$moment(this.selectedDate,this.dateFormat).add(1, "day").format(this.dateFormat);
      await this.loadDatasets();
    },

    async loadDatasets() {
      this.$store.commit("SET_RADIAL_LOADING", true);
      // this.watchSlider = false;
      // ToDO: Loading several times (ind loc, std loc, std) should be optimized
      //console.log("loadDatasets 0 ",  this.radialIcon.location);
      if (this.selectedRadio == "individual") {
        const _filter = {from: this.$moment(this.selectedDate,this.dateFormat).startOf("day").format('X'), to: this.$moment(this.selectedDate,this.dateFormat).endOf("day").format('X'), profile: this.selectedRadio, data_type: this.deviceTypeId, location: this.radialIcon.location};
        await this.getDatasets(_filter);
        //console.log("loadDatasets 99 ",  this.Datasets, _filter);
        if (this.Datasets.length > 0 ) {
          this.isStandard = false;
        } else {
          this.isStandard = true;
        }
      }
      if (this.selectedRadio == "standard") {
        //load Standard for selectedDay   
        let _selectedDateStart = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(this.calcDays.start, "days");
        let _selectedDateEnd = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(this.calcDays.end, "days").endOf("day");
        await this.getDatasets({from: _selectedDateStart.format('X'), to: _selectedDateEnd.format('X'), profile: "standard", data_type: this.deviceTypeId, location: this.radialIcon.location});
        // console.log("loadDatasets 2 S ",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        // if (this.Datasets.length == 0) {
        //   await this.getDatasets({from: _selectedDateStart.format('X'), to: _selectedDateEnd.format('X'), profile: "standard", data_type: this.deviceTypeId, location: ''});
        //   // console.log("loadDatasets 3 S ",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        // }
      } 
      // console.log("Load DataSets ", this.Datasets)
      if (this.selectedRadio == "individual" && this.Datasets.length == 0) {
        //load Standard for selected Day
        // this.isStandard = true;
        let _day = this.$moment(this.selectedDate,this.dateFormat).isoWeekday() - 1;
        let _selectedDate = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(_day, "days");
        //console.log("loadDatasets 1 I ",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        await this.getDatasets({requestedDay: this.$moment(this.selectedDate,this.dateFormat).format('X'), from: _selectedDate.startOf("day").format('X'), to: _selectedDate.endOf("day").format('X'), profile: "standard", data_type: this.deviceTypeId, location: this.radialIcon.location} );
        //console.log("loadDatasets 2 I",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        // if (this.Datasets.length == 0) {
        //   await this.getDatasets({from: _selectedDate.startOf("day").format('X'), to: _selectedDate.endOf("day").format('X'), profile: "standard", data_type: this.deviceTypeId, location: ''} );
        //   //console.log("loadDatasets 3 I",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        // }
        //console.log("Load Standard DataSets ", this.Datasets, _selectedDate, _day)
      } 
      //else {
      //   this.isStandard = false;
      // }  

      // console.log("loadDatasets 4",  this.Datasets.length, this.Datasets, this.radialIcon.location);
      
      this.sliderValue = [];
      if (this.Datasets.length == 0) { 
        
        for (let temp of this.heatingBarY) {
          let arr = [this.heatingBarX[0],this.heatingBarX[this.heatingBarX.length - 1]];
          this.sliderValue.push(arr);
          // console.log("sliderVAlue: " , arr,this.heatingBarX.length )
        }
        // await this.saveGroupDays();
      } else {
        for (let data of this.Datasets) {
          // console.log(data)
          let arr = [data.value_from, data.value_to];
          this.sliderValue.push(arr);
        }
      }
      
      // this.$nextTick(() => {
      //   this.watchSlider = true;
      // });
      this.$store.commit("SET_RADIAL_LOADING", false);
      this.calcEnergyAndFlexibility();
    },

    async saveGroupDays() {
      //Schleife über zusammengefasste Tage
      let saveSuccess = false;
      this.$store.commit("SET_RADIAL_LOADING", true);
      if (this.selectedRadio == "standard") {
        // const _selectedDate = this.selectedDate;
        for (let _day = this.calcDays.start; _day <= this.calcDays.end; _day++) {
          let _selectedDate = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(_day, "days").format(this.dateFormat);
          try {
            saveSuccess = await this.saveDataSet(_selectedDate);
          } catch (err) {
            console.log(err);
          }
        }
        // this.selectedDate = _selectedDate;
      } else {
        try {
          saveSuccess = await this.saveDataSet(this.selectedDate);
        } catch (err) {
          console.log(err);
        }
      }
      if (!saveSuccess) {
        await this.loadDatasets();
      } 
      this.$store.commit("SET_RADIAL_LOADING", false);
      this.calcEnergyAndFlexibility();
    },

    async saveDataSet(_date) {
      
      //ToDo: By Individual check if not standard, else no save
      let dataset = {};
      let saveSuccess = true;

      dataset.user_id = this.mySelf._id;
      dataset.data_type = this.deviceTypeId;
      dataset.location = this.radialIcon.location;
      
      dataset.profile = this.selectedRadio; //"individual";

      let is_new_element = false;
      for (let timeslot = 0; timeslot < this.heatingBarY.length; timeslot++) {
        
        const _times = this.calcTimeSlots(timeslot);
        
        dataset.start = this.$moment.utc(_date, this.dateFormat).add(_times.hours, "hours").add(_times.min, "minutes").toDate();    
        dataset.end = this.$moment.utc(_date, this.dateFormat).add(_times.hours_next, "hours").add(_times.min_next, "minutes").subtract(1, "seconds").toDate();
        
        dataset.value_from = this.sliderValue[timeslot][0];
        dataset.value_to = this.sliderValue[timeslot][1];

        // console.log(this.heatingBarY[timeslot], _times.hours, _times.min, _times.hours_next, _times.min_next, dataset.start, dataset.end, dataset.value_from, dataset.value_to);
        // let localDataset = _.find(this.Datasets, {start: dataset.start, end: dataset.end});
        //console.log("Datasets0: ", this.Datasets)
        let localDataset = this.Datasets.find(obj => {
          //console.log("LocalDataset: ", new Date(obj.start) == new Date(dataset.start), new Date(obj.end) == new Date(dataset.end))
          //console.log("LocalDataset2: ", this.$moment(obj.start), this.$moment(dataset.start), this.$moment(obj.end), this.$moment(dataset.end))
          return this.$moment(obj.start).format('X') == this.$moment(dataset.start).format('X') && this.$moment(obj.end).format('X') == this.$moment(dataset.end).format('X')
                  && obj.location == dataset.location;
        });
        //console.log("Save Dataset ", _date, timeslot, localDataset);
        //console.log("OBS Dataset Save ", (localDataset.value_from != dataset.value_from || localDataset.value_to != dataset.value_to))
        if (localDataset) {
          if (localDataset.value_from != dataset.value_from || localDataset.value_to != dataset.value_to) {
            //console.log("New Dataset: ", dataset.value_from, dataset.value_to, dataset.start, dataset.end)
            // console.log("   Old Dataset: ", localDataset.value_from, dataset.value_to, this.$moment(dataset.start).toDate(), this.$moment(dataset.end).toDate())
            
            dataset._id = localDataset._id;
            await this.updateDataset(dataset);
            //console.log(this.DatasetState.statusUpdate);
            if (this.DatasetState.statusUpdate != "success") {
              //retry
              // await this.updateDataset(dataset);
              //   if (this.DatasetState.statusUpdate != "success") {
              //     saveSuccess = false;
              //   }
              //console.log("Updated Dataset error: ")
              this.alertType = "error";
              this.alertMsg = "Past Settings are not allowed to change"
              this.alertOn = true;
              saveSuccess = false;
            } else {
              //console.log("Updated Dataset: ", this.DatasetState.UpdatedDataset)
              // localDataset.value_from = dataset.value_from;
              // localDataset.value_to = dataset.value_to;
              for (let key in this.DatasetState.UpdatedDataset) {
                //console.log(key)
                localDataset[key] = this.DatasetState.UpdatedDataset[key];
              }
            }
          }
        } else {

          //console.log("Create New Dataset: ", dataset.value_from, dataset.value_to, dataset.start, dataset.end)
          await this.createDataset(dataset);
          if (this.DatasetState.statusCreate != "success") {
            //retry
            // await this.createDataset(dataset);
            //   if (this.DatasetState.statusCreate != "success") {
            //     saveSuccess = false;
            //   }
            this.alertType = "error";
            this.alertMsg = "Create failed"
            this.alertOn = true;
            saveSuccess = false;
          } else {
              is_new_element = true;
            }
        }   
      }
      if (is_new_element) {
        await this.loadDatasets();
      }
      return saveSuccess;
      // await this.getDatasets({from: this.$moment(this.selectedDate,this.dateFormat).startOf("day").format('X'), to: this.$moment(this.selectedDate,this.dateFormat).endOf("day").format('X'), profile: "individual", data_type: "heating"});
      // this.watchSlider = true;
    },

    calcTimeSlots(timeslot) {
      // const hours = parseInt(this.heatingBarY[timeslot].substring(0,2));
      // const min = parseInt(this.heatingBarY[timeslot].substring(3,5));
      const hours = this.utcHeatingBarY[timeslot];
      const min = 0;

      let next = timeslot + 1;
      let addDay = 0;
      // console.log(this.heatingBarY[next], next, timeslot)
      if (!this.utcHeatingBarY[next]) {
        next = 0;
        addDay = 24;
      }
      const hours_next = this.utcHeatingBarY[next] + addDay;
      const min_next = 0;

      return {hours: hours, min: min, hours_next: hours_next, min_next: min_next};
    },

    calcEnergyAndFlexibility() {
      
      const min = this.heatingBarX[0];
      const max = this.heatingBarX[this.heatingBarX.length - 1];
      const range100 = (max - min) * (this.heatingBarY.length + 1);
      let flex = 0;
      let demand = 0;
      for (let timeslot = 0; timeslot < this.heatingBarY.length; timeslot++) {
        const set_min = this.sliderValue[timeslot][0];
        const set_max = this.sliderValue[timeslot][1];
        let factor = 1;
        // Last slider longest duration -> 2x
        if (timeslot == this.heatingBarY.length - 1) {
          factor = 2;
        }
        flex += (set_max - set_min) * factor;
        demand += (set_min - min) * factor;

        //console.log("calcEnergyAndFlexibility: ", set_min, set_max, min, max, range100);
      }
      this.flexibility = (flex / range100 * 100).toFixed(0);
      //console.log("calcEnergyAndFlexibility: ", demand, this.heatingBarY.length, this.heatingBarX.length);
      this.energyDemand = (100 - ((demand / (this.heatingBarY.length + 1) / (this.heatingBarX.length - 1)) * 100)).toFixed(0);
      this.toDayEnergyScore = ((Number(this.flexibility) + (2 * Number(this.energyDemand))) / 3).toFixed(0);

      
    }

  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      Datasets: ({ dataset }) => dataset.Datasets,
      DatasetState: ({ dataset }) => dataset,
      Locations: ({ location }) => location.Locations,
      Global: ({ global }) => global.Global,
      radialLoading: (state) => state.radialLoading,
    }),

    formatedDate() {
      let _return;
      if (this.selectedDate == this.$moment().format(this.dateFormat)) {
        _return = "ToDay";
      } else {
        _return =  this.$moment(this.selectedDate, this.dateFormat).format("dd DD.MM.YY");
      }
      
      return _return;
    },
   
    calcDays() {
    //Mo-Do
      let day_start = 0;
      let day_end = 3;       
      if (this.selectedDaysIndex == 1) {  //Fr
        day_start = 4;
        day_end = 4;
      } else if (this.selectedDaysIndex == 2) { //Sa-So
        day_start = 5;
        day_end = 6;
      }
      return {start: day_start, end: day_end};
    },
   
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,     
          '--bgSize': (window.innerWidth / 116) + "em",
          'bgSize': (window.innerWidth / 116 * (this.heatingBarX.length - 1)) + "em",
          'wHeight': (window.innerHeight - this.scoreElementBottom*1.75) / 7 + "px",
          'sHeight': (window.innerHeight) + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--bgSize': (window.innerWidth / 116) + "em",
          'bgSize': (window.innerWidth / 116 * (this.heatingBarX.length - 1)) + "em",
          'wHeight': (window.innerHeight - this.scoreElementBottom*1.75) / 7 + "px",
          'sHeight': (window.innerHeight) + "px",
        }
      }
    }
  },
  watch: {
    async selectedRadio(obj) {
      await this.loadDatasets();
    },
    async selectedDaysIndex() {
      await this.loadDatasets();
    },
    async radialIcon(obj) {
      //console.log("RadialIcon Changed ", obj);
      await this.loadDatasets();
    }
  },
};
</script>

<style lang='scss'>

.vue-slider-rail {
  background-color: rgba(0, 0, 0, 0) !important;
}

.vue-slider-process {
  background-color: rgba(100,100,100,0.3) !important;
  height: 50% !important;  
  top: 1em !important;
}

.custom-dot {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

.bg-grid {
  background: rgba(0,0,0,0);
  background-image: linear-gradient(90deg, rgba(150, 150, 150, .3) .1em, transparent .1em);
  background-size: var(--bgSize) 2em;
}

.logo-overlay-loading {
  position: fixed;
  z-index: 9997;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.0;
  background: black;
}

.v-alert {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
}

</style>