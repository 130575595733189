const REQUEST_SCREENS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_SCREENS = (state, { body }) => {
  //console.log("SCREEN: ", JSON.parse(JSON.stringify(body)));
  state.Screens = body;
  state.status = "success";
};

const FAILURE_REQUEST_SCREENS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_SCREEN = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_SCREEN = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_SCREEN = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_SCREEN = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_SCREEN = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_SCREEN = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_SCREEN = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_SCREEN = (state, { body }) => {
  state.CreatedScreen = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_SCREEN = state => {
  
  state.statusCreate = "failure";
};


export default {
  REQUEST_SCREENS,
  SUCCESS_REQUEST_SCREENS,
  FAILURE_REQUEST_SCREENS,
  REQUEST_CREATE_SCREEN,
  SUCCESS_REQUEST_CREATE_SCREEN,
  FAILURE_REQUEST_CREATE_SCREEN,
  REQUEST_DELETE_SCREEN,
  SUCCESS_REQUEST_DELETE_SCREEN,
  FAILURE_REQUEST_DELETE_SCREEN,
  REQUEST_UPDATE_SCREEN,
  SUCCESS_REQUEST_UPDATE_SCREEN,
  FAILURE_REQUEST_UPDATE_SCREEN,

};
