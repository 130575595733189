<template>
  <v-container class="pa-0 ma-0" :style="cssVars" >
    <!-- <div v-if="!loading_data"> -->
      <ScoreBar class="RankingBarMain  mt-6" :elements="RankingBarElements" title="RANK" glow="true" size="25" @menuEvent="rankingMenuClick" />
      <ScoreBar class="PerformanceBarMain  mt-3" :elements="PerformanceBarElements" title="PERF" glow="false" size="25"  @menuEvent="performanceMenuClick" />
      
    <v-row class="status-bar ml-n1 mr-3 mt-7" v-if="checkPvMain">
      <v-col class="">
        <v-row class="">
          <v-col class="caption pb-0" cols="7">
            Todays Production
          </v-col>
          <v-col class="caption pb-0" cols="3">
            83,0 kWh
          </v-col>
        </v-row>
        <v-row>
          <v-col class="grey--text caption py-0">
            Self Consumption Rate
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="3" class="pa-0">
            <apexchart
                type="radialBar"
                :options="radialBarOptions"
                :series="selfConsumptionSeries"         
              ></apexchart>
          </v-col>
          <v-col class="caption px-0">
            <v-row>
              <v-col class="pb-0">
                Self Consumption: 29,0 kWh
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                Grid Feed-In: 54,0 kWh
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ml-n2 mr-3 mt-7" v-if="checkHeatMain">
      <!-- <v-col cols="6"  v-for="room in compHeatingScreen.icons" :key="room.roomname">
        <v-row class="">
          <v-col class="caption pb-0" cols="12">
            {{room.roomname}}
          </v-col>
          <v-col class="caption pb-0" cols="3">
            {{room.actTemp}}
          </v-col>
          <v-col class="caption pb-0" cols="3">
            {{room.actHum}}
          </v-col>
          <v-col class="caption pb-0" cols="3">
            {{room.bat}}
          </v-col>
        </v-row>
       
      </v-col> -->
       <v-col class="ml-5 mb-2" cols="5" v-for="room in compHeatinScreenOnlyTempAvailable.icons" :key="room.roomname" >
          <!-- <v-card
            v-if="room.actTemp"
            class="my-1"
          > -->
          <v-row class="infobar" align="center" >
            <v-col class="xsText font-weight-bold py-0" cols="12">
              
              <div v-if="$moment().diff($moment(room.timestamp, 'X'),'hours') > 12" class="red--text">{{room.roomname}} (-{{$moment().diff($moment(room.timestamp, 'X'),'hours')}}h)</div>
              <div v-else-if="$moment().diff($moment(room.timestamp, 'X'),'hours') > 3" class="orange--text">{{room.roomname}}</div>
              <div v-else >{{room.roomname}}</div>
            </v-col>
            <v-col class="xsText py-0" cols="4" v-if="room.actTemp">
              {{room.actTemp}}°C
            </v-col>
            <v-col class="xsText py-0 " cols="3" v-if="room.actHum">
              {{room.actHum}}%
            </v-col>
            <v-col class="xsText py-0" cols="5" v-if="room.bat">
              {{room.bat}}V
            </v-col>
            <v-col align="center" class="xxsText py-0" cols="5" v-else-if="room.actCo2">
              <div v-if="room.actCo2 > 2000" class="red--text">{{room.actCo2}}ppm</div>
              <div v-else-if="room.actCo2 > 1000" class="orange--text">{{room.actCo2}}ppm</div>
              <div v-else >{{room.actCo2}}ppm</div>
            </v-col>
            <!-- <v-col class="xsText py-0" cols="4" v-if="room.timestamp">
              <div v-if="$moment().diff($moment(room.timestamp, 'X'),'hours') > 2" class="red--text">{{$moment().diff($moment(room.timestamp, 'X'),'minutes')}}</div>
              <div v-else-if="$moment().diff($moment(room.timestamp, 'X'),'hours') > 1" class="orange--text">{{$moment().diff($moment(room.timestamp, 'X'),'minutes')}}</div>
              <div v-else class="green--text">{{$moment().diff($moment(room.timestamp, 'X'),'minutes')}}</div>
            </v-col> -->
          </v-row>
          <!-- </v-card> -->
      </v-col>
    </v-row>

    <StatusBar class="StatusBarMain ml-5 " :energyBarElements="EnergyBarElements" :energyBarTitles="energyBarTitles" :weatherBarElements="WeatherBarElements" :heatingBarElements="HeatingBarElements"/>

    <ScoreBar class="StatusActualMain ml-5" :elements="BarActualElements" title="NOW" :titlesOff="false" glow="false" size="25" @menuEvent="barActualClick" />
    <!-- </div> -->
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";

import ScoreBar from "../components/ScoreBar";
import StatusBar from "../components/StatusBar";

export default {
  name: "MainScreen",
  props: { },
  components: {
    ScoreBar,
    StatusBar
  },
  data() {
    return {

      //ToDo: For Demo only?
      selfConsumptionSeries: [35],
      selfSufficiencySeries: [80],
      radialBarOptions:{   
        colors: ["#20E647"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "50%"
            },       
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 4,
                color: "#fff",
                fontSize: "10px",
                show: true
              }
            }
          }
        },

        stroke: {
          lineCap: "round",
        }
      },


      loading_data: true,
      weatherDailyReady: false,
      solarFcReady: false,
      //renderComponent: true,
      // rankingBarElements: [
      //   {id: 0, name: "community-grey-solo-rad", primary: "community_primary", secondary: "community_secondary", number: 989, denominator: "/ 1219", suffix: ""},
      //   {id: 1, name: "activity-grey-solo-rad", primary: "interaction_primary", secondary: "interaction_secondary", number: 177, denominator: "/ 200", suffix: ""},
     
      // ],
      // performanceBarElements: [
      //   {id: 0, name: "electricity-grey-solo-rad", primary: "energy_primary", secondary: "energy_secondary", number: 989, denominator: "", suffix: "%"},
      //   {id: 1, name: "heating-grey-solo-rad", primary: "heating_primary", secondary: "heating_secondary", number: 177, denominator: "", suffix: "kWh"},
      
      // ],

      // energyBarElements: [0,3,7,9,4,0],
      // energyBarElements: [[0,0],[3,2],[7,4],[9,5],[4,1],[0,0]],
      //energyBarTitles: ["04:00-07:00","04:00-07:00","04:00-07:00","04:00-07:00","04:00-07:00","04:00-07:00","04:00-07:00","04:00-07:00","04:00-07:00"]
      energyBarTitles: ["04:00","07:00","11:00","14:00","18:00","22:00"],
      energyBarElementsCounter: [true,true,true,true,true,true],

      // barActualElements: [
      //   {id: 0, name: "iconpic_strom", primary: "energy_primary", secondary: "energy_secondary", number: 120, denominator: "", suffix: "kWh"},
      //   {id: 1, name: "iconpic_cashback", primary: "grey", secondary: "black", number: 25, denominator: "", suffix: "°C"},
      //   {id: 2, name: "iconpic_heizen", primary: "heating_primary", secondary: "heating_secondary", number: 0, denominator: "", suffix: "kWh"}
      // ],

      // weatherBarElements: [],

    };
  },
  created: function () {
    
  },
  async mounted() {
    
    // if (this.ForeCast.status == "success") {
      // await this.calcWeather();
      // await this.calcSolarFc();
    // }
 
  },
  methods: {
   

    async calcWeather() {
      // await this.getWeatherDaily({"data.start": {$gte: parseInt(this.$moment().startOf("day").format("X"))}, "data.end": {$lte: parseInt(this.$moment().add(0,"days").endOf("day").format("X"))}});
      
      // if (this.ForeCast.status == "success") {
        // console.log("this.getWeatherDaily: ", this.ForeCast)
        // if (this.ForeCast.WeatherDaily[0].data.hourly.length > 0) {   
          let weatherBarElements = [];    
          for (let idx in this.energyBarTitles) {           
            const time = parseInt(this.$moment().startOf('day').add(parseInt(this.energyBarTitles[idx].substring(0,2)), "hours").format('X'));
            //console.log(this.energyBarTitles[idx].substring(0,2), time)
            
            let obj = _.find(this.ForeCast.WeatherDaily[0].data.hourly, {dt: time});
            // console.log(title.substring(0,2), time, obj)
            if (obj) {
              weatherBarElements[idx] = obj;
              //console.log("this.weatherBarElements from hourly: ", this.weatherBarElements)
            } else {
              let temp = 0;
              if (idx == 0) temp = this.ForeCast.WeatherDaily[0].data.temp.min;
              if (idx == 1) temp = this.ForeCast.WeatherDaily[0].data.temp.morn;
              if (idx == 2) temp = this.ForeCast.WeatherDaily[0].data.temp.day;
              if (idx == 3) temp = this.ForeCast.WeatherDaily[0].data.temp.max;
              if (idx == 4) temp = this.ForeCast.WeatherDaily[0].data.temp.eve;
              if (idx == 5) temp = this.ForeCast.WeatherDaily[0].data.temp.night;
              weatherBarElements[idx] = {
                dt: time,
                temp: temp,
                weather: this.ForeCast.WeatherDaily[0].data.weather
              }
              //console.log("this.weatherBarElements from daily: ", this.weatherBarElements)
            }
          }
        // }  
        //console.log("this.weatherBarElements: ", this.weatherBarElements);
        //this.$store.state.elements.weatherBarElements = JSON.parse(JSON.stringify(weatherBarElements));
        this.$store.commit("SET_ELEMENTS_WEATHER", JSON.parse(JSON.stringify(weatherBarElements)));
      // }

      const actualHour = Math.round((parseInt(this.$moment().format('X')) - parseInt(this.$moment().startOf('day').format('X'))) / 60 / 60);
      const time = parseInt(this.$moment().startOf('day').add(actualHour, "hour").format('X'));
      
      const nowTemp = _.find(this.WeatherDaily[0].data.hourly, {dt: time});
      //console.log("Weather daily: ",actualHour, time, nowTemp.temp)
      this.BarActualElements[1].number = nowTemp.temp;
      this.weatherDailyReady = true;
    },

    async calcSolarFc() {
      // await this.getSolarFc({"timestamp": {$gte: this.$moment().startOf("day").format("X")}});
      // if (this.ForeCast.status == "success") {
        let forecast = [];
        
        //console.table(this.ForeCast.SolarFc[0].data)
        // for (let fc of this.ForeCast.SolarFc[0].data) {
        //   console.log(this.$moment(fc.time, "X").toDate(), Number(fc.value.toFixed(0)));
        // }
        
        //console.table(this.energyBarTitles)
        let sumArray = [];
        let startHour = 0;
        let endHour = 0;
        let el1,el2,el3;

        for (let idx = 0; idx < this.energyBarTitles.length; idx++) {
          
          el2 = parseInt(this.energyBarTitles[idx].substring(0,2));

          if (idx == 0) {
            el1 = parseInt(this.energyBarTitles[this.energyBarTitles.length - 1].substring(0,2));
            el3 = parseInt(this.energyBarTitles[idx + 1].substring(0,2));
          }
          else if (idx == this.energyBarTitles.length - 1){
            el1 = parseInt(this.energyBarTitles[idx - 1].substring(0,2));
            el3 = parseInt(this.energyBarTitles[0].substring(0,2));
          } 
          else {
            el1 = parseInt(this.energyBarTitles[idx - 1].substring(0,2));
            el3 = parseInt(this.energyBarTitles[idx+1].substring(0,2));
          }

          //console.log(el1,el2,el3);
          // console.log(el1,el2,el3);

          let gap1 = 0;
          if (el1 > el2) {
            gap1 = Math.floor( (24 - el1 + el2) / 2);
          } else {
            gap1 = Math.floor( (el2 - el1) / 2);
          }
          startHour = el2 - gap1;

          let gap2 = 0;
          if (el2 > el3) {
            gap2 = Math.ceil( (24 - el2 + el3) / 2);
          } else {
            gap2 = Math.ceil( (el3 - el2) / 2);
          }
          endHour = el2 + gap2;

          //console.log(gap1,gap2);
          sumArray.push([startHour, endHour]);
        }

        //console.table(sumArray);

        let solarTable = [];
        for (let idx = 0; idx < sumArray.length; idx++) {
          const _start = parseInt(this.$moment().startOf('day').add(sumArray[idx][0], "hours").format('X'));
          const _end = parseInt(this.$moment().startOf('day').add(sumArray[idx][1], "hours").format('X'));
          //console.log(_start,_end);
          
          let sumSolar = 0;
          for (let fc of this.ForeCast.SolarFc[0].data) {
            //console.log(this.$moment(fc.time, "X").toDate(), Number(fc.value.toFixed(0)));
            if (Number(fc.time) >= _start && Number(fc.time) < _end) {
              sumSolar += Number(fc.value);
            }
          }
          solarTable[idx] = [parseInt(sumSolar),0];
          
        }

        
        //console.table(solarTable);
        const maxSolarValue = Math.max(...(solarTable.map(el => el[0])));
        //const maxSolarValue = 5000;
        //console.table(maxSolarValue, solarTable);
        for (let value of solarTable) {
          value.push(value[0]);
          value[0] = Math.ceil(value[0] * 17 / maxSolarValue);
        }
        
        this.$store.commit("SET_ELEMENTS_ENERGY_SHORT", solarTable);

        // let _hour = 0;
        // let count = _hour * 4;
        // let last_count = 0;
    
        // for (let idx in this.energyBarTitles) {
        //   //console.log(idx)
        //   if (idx < this.energyBarTitles.length - 1) {
        //     //console.log(this.energyBarTitles.length, this.energyBarTitles[Number(idx) + 1]);
        //     _hour = parseInt(this.energyBarTitles[Number(idx) + 1].substring(0,2));
        //   } else {
        //     _hour = this.energyBarTitles.length-1;
        //   }
        //   //const time = parseInt(this.$moment().startOf('day').add(_hour, "hours").format('X'));
        //   last_count =  count;
        //   count = _hour * 4;
        //   //console.log(_hour, count, last_count);     
        //   let sum = 0;
        //   for (let counter = last_count; counter < count; counter++) {
        //     //console.log("counter: ", counter);
        //     sum += this.ForeCast.SolarFc[0].data[counter].value;
        //   }
        //   // let obj = {};
        //   // obj.time = time;
        //   // obj.value = sum;
        //   let arr =[parseInt(sum),0];
        //   //let a = _.find(this.ForeCast.SolarFc[0], {time: time});
        //   //console.log(obj) //, a.value);
        //   forecast.push(arr);
        // }

        // const maxSolarValue = Math.max(...(forecast.map(el => el[0])));
        // for (let value of forecast) {
        //   value[0] *= 10 / maxSolarValue;
        // }
        // //this.$store.state.elements.energyBarElementsShort = forecast;
        // this.$store.commit("SET_ELEMENTS_ENERGY_SHORT", forecast);
      // }
      //ToDo: Mit echten Produktivwert ersetzen
      const actualQuarter = Math.round((parseInt(this.$moment().format('X')) - parseInt(this.$moment().startOf('day').format('X'))) / 60 / 15);
      //console.log("actualQuarter ",actualQuarter)
      const time = parseInt(this.$moment().startOf('day').add(actualQuarter * 15, "minutes").format('X'));
      const nowSolar = _.find(this.SolarFc[0].data, {time: String(time)});
      //console.log(time, nowSolar)
      let actSolar = nowSolar.value;
      if (actSolar < 0) {
        actSolar = 0;
      }
      this.BarActualElements[0].number = actSolar;
      this.solarFcReady = true;
    },

    rankingMenuClick(payload) {
      // let rnd = Math.random(0,1);
      // if(rnd >= 0.5) this.$store.state.elements.rankingBarElements[payload.id].number += 100; 
      // else this.$store.state.elements.rankingBarElements[payload.id].number -= 100; 
    },
    performanceMenuClick(payload) {
      // let rnd = Math.random(0,1);
      // if(rnd >= 0.5) this.$store.state.elements.performanceBarElements[payload.id].number += 100; 
      // else this.$store.state.elements.performanceBarElements[payload.id].number -= 100; 
    },
    barActualClick(payload) {
      // let rnd = Math.random(0,1);
      // if(rnd >= 0.5) this.$store.state.elements.barActualElements[payload.id].number += 100; 
      // else this.$store.state.elements.barActualElements[payload.id].number -= 100; 
    },
   
  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      ForeCast: ({ forecast }) => forecast,
      SolarFc: ({ forecast }) => forecast.SolarFc,
      WeatherDaily: ({ forecast }) => forecast.WeatherDaily,
      RankingBarElements: ( state ) => state.elements.rankingBarElements,
      PerformanceBarElements: ( state ) => state.elements.performanceBarElements,
      BarActualElements: ( state ) => state.elements.barActualElements,
      EnergyBarElements: ( state ) => state.elements.energyBarElementsShort,
      WeatherBarElements: ( state ) => state.elements.weatherBarElements,
      HeatingBarElements: ( state ) => state.elements.heatingBarElements,
      Screens: ({ screen }) => screen.Screens,
      ObsUserData : ({ user }) => user.obsUserData,
    }),

    compHeatingScreen() {
      let heatingScreen = JSON.parse(JSON.stringify(this.Screens.find(obj => {return obj.name == "heatingScreen"})));
      for (let room of heatingScreen.icons) {
        if (room.actTemp) {
          room.actTemp = room.actTemp.toFixed(1);
        }
        if (room.actHum) {
          room.actHum = room.actHum.toFixed(0);
        }
        if (room.bat) {
          room.bat = room.bat.toFixed(1);
        }
      }
      return heatingScreen;
    },

    compHeatinScreenOnlyTempAvailable() {
      let heatingScreen = this.compHeatingScreen;
      let icons = [];
      for (let idx in heatingScreen.icons) {
        if (heatingScreen.icons[idx].actTemp) {
          icons.push(heatingScreen.icons[idx]);
        }
      }
      heatingScreen.icons = icons; 
      return heatingScreen;
    },

    checkPvMain() {
      if (this.ObsUserData.obs_settings) {
        if (this.ObsUserData.obs_settings.pvMain) {
          return true;
        }
      }
      return false;
    },

    checkHeatMain() {
      if (this.ObsUserData.obs_settings) {
        if (this.ObsUserData.obs_settings.heatMain && !this.checkPvMain) {
          return true;
        }
      }
      return false;
    },

    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    }
  },
  watch: {
    SolarFc() {
      // console.log("SolarFc changed")
      this.calcSolarFc();
      console.log("Solar ForeCast Generated " + String(this.weatherDailyReady)  + " " + String(this.$store.state.allLoaded));
      if (this.weatherDailyReady) {
        //this.$store.state.allLoaded = true;
        this.$store.commit("SET_ALL_LOADED", true);
      }
    },
    WeatherDaily() {
      // console.log("WeatherDaily changed")
      this.calcWeather();
      console.log("Weather ForeCast Generated " + String(this.solarFcReady) + " " + String(this.$store.state.allLoaded));
      if (this.solarFcReady) {
        //this.$store.state.allLoaded = true;
        this.$store.commit("SET_ALL_LOADED", true);
      }
    },

    // barActualElements(el) {
    //   console.log(el)
    //   this.loading_data = false;
    // }
    // elements() {
    //   //console.log("elements changed");
    //   //this.$forceUpdate();
    //   this.renderComponent = false;
    //   this.$nextTick(() => {
    //     this.renderComponent = true;
    //   });
    // }
  },
};
</script>

<style lang='scss'>


.graphbar {
  background: var(--bg) !important;
  border-radius: 4px;
  height:  var(--size);
}


.StatusBarMain {
  position: absolute;
  left: 0px;
  bottom: 16%;
}

.StatusActualMain {
  position: absolute;
  bottom: 11%;
  left: 0px;
}

.xsText {
  font-size: 11px;
}

.xxsText {
  font-size: 9px;
  font-weight: bold;
}

.infobar {
  background: var(--bg) !important;
  border-radius: 4px;
}

</style>