/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    async updated() {
      console.log("New content is available; refreshing");
      // Add a unique query parameter to the URL to bust the cache
      //const cacheBustUrl = `${window.location.href}?v=${Date.now()}`;
      //await new Promise(r => setTimeout(r, 5000));
      //window.location.href=window.location.href;
      //setTimeout(window.location.reload(true));
      setTimeout(() => {
        //window.location.href = 'http://obs.schwarzl-it.com';
        window.location.reload(true);
      }, 1000);
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
