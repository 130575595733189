

const generateIconSvg = async (filename, primary, internalId, size, secondary, text) => {

  let defTagNames = [];

  const recurseDomChildren  = (start, output) =>
  {
      let nodes;
      if(start.childNodes)
      {
          nodes = start.childNodes;
          loopNodeChildren(nodes, output);
      }
  }

  const outputNode = (node) =>
  {
      try {
        let fillAttr = node.getAttribute("fill");
        if (fillAttr) {
          for (let tag of defTagNames) {
            if(fillAttr == "url(#" + tag.old + ")") {
              node.setAttribute(
                "fill",
                "url(#" + tag.new + ")"
              );
            }
          }
        }
        
      } catch (err) {
        //console.log(err);
      }
  }

const loopNodeChildren = (nodes, output) =>
  {
      let node;
      for(var i=0;i<nodes.length;i++)
      {
          node = nodes[i];
          if(output)
          {
              outputNode(node);
          }
          if(node.childNodes)
          {
              recurseDomChildren(node, output);
          }
      }
  }

  //console.log("text: ", text);

  let _file = filename + ".svg";
  //this.mSVG = await import("!!raw-loader!../assets/iconpic_heizen.svg");  //funkt
  let mSVG = await import(`!!raw-loader!../assets/${_file}`);

  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(mSVG.default, "image/svg+xml");
  
  const iconWidth = xmlDoc.documentElement.getAttribute("width");
  const iconHeight = xmlDoc.documentElement.getAttribute("height");

  //Set Size
  xmlDoc.documentElement.setAttribute("width", iconWidth/iconHeight * size);
  xmlDoc.documentElement.setAttribute("height", size);

  //Parse Defs - radialGradient 


  for (let node of xmlDoc.documentElement.childNodes[1].childNodes) {
    if (node.tagName == "radialGradient" || node.tagName == "linearGradient") {
      const old_id = node.id;
      node.id = "radial-gradient_" + internalId + "_" + defTagNames.length;
      defTagNames.push({old: old_id, new: node.id});
      if (defTagNames.length == 1) {
        node.childNodes[3].setAttribute("stop-color", primary);
      } else if (defTagNames.length == 2 && secondary.length > 0) {
        node.childNodes[3].setAttribute("stop-color", secondary);
      }
    }
  }

  if (text.length > 0) {
    const x = "20%";
    const y = "100%";
    var svgNS = "http://www.w3.org/2000/svg";
    var newText = document.createElementNS(svgNS,"text");
    newText.setAttributeNS(null,"x",x);     
    newText.setAttributeNS(null,"y",y); 
    newText.setAttributeNS(null,"font-size","15");
    newText.setAttributeNS(null,"fill","orange");
    newText.innerHTML = text;
    //var textNode = document.createTextNode(val);
    //newText.appendChild(textNode);
    //console.log(newText);
    //
    //console.log(xmlDoc.getElementsByTagName("g"));
    xmlDoc.getElementsByTagName("svg")[0].appendChild(newText);
    //console.log(xmlDoc.documentElement);
  }

  recurseDomChildren(xmlDoc.documentElement, true);

  let serializer = new XMLSerializer();
  const xmlStr = serializer.serializeToString(xmlDoc.documentElement);

  return xmlStr;
  //let _div = document.getElementById(this.internalId);
  //_div.innerHTML = xmlStr; //funkt

   // add Glow   
  // if (this.glow == "true") {
    
  //   _file = "glow-bubble-mainicons.svg";

  //   //this.mSVG = await import("!!raw-loader!../assets/iconpic_heizen.svg");  //funkt
  //   let glowFile = await import(`!!raw-loader!../assets/${_file}`);
    
  //   let xmlDoc2 = parser.parseFromString(glowFile.default, "image/svg+xml");
  //   //Set Size
  //   xmlDoc2.documentElement.setAttribute("width", this.size*1.2);
  //   xmlDoc2.documentElement.setAttribute("height", this.size*1.2);
   
  //   const xmlStr2 = serializer.serializeToString(xmlDoc2.documentElement);

  //   let _div2 = document.getElementById(this.internalId2);
  //   _div2.innerHTML = xmlStr2; //funkt
  // }
}

export { generateIconSvg };
