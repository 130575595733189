<template>
  <v-container class="pa-0 ma-0" :style="cssVars">
    <v-row class="status-bar ml-0 mr-3">
      <!-- <v-col class="d-flex justify-start align-center my-n3" cols="auto">
        <v-icon>mdi-chevron-left</v-icon>
      </v-col> -->
      <v-col class="text-center my-n3">  
        {{formatedDate}}
      </v-col>
      <!-- <v-col class="d-flex justify-end align-center my-n3" cols="auto">
        <v-icon>mdi-chevron-right</v-icon>
      </v-col> -->
    </v-row>

    <v-row class="pa-0 pt-2">      
      <v-col cols="6" style="font-size: 18px">
        {{radialIcon.roomname}}
      </v-col> 
      <!-- <v-col cols="4" class="caption text-center pt-0">Flexibility: {{flexibility}}
        <v-progress-linear 
          color="energy_primary"
          v-model="flexibility"
          :height="10">
        </v-progress-linear>
      </v-col>
      <v-col cols="4" class="caption text-center pt-0">Demand: {{energyDemand}}
        <v-progress-linear 
          color="heating_primary"
          v-model="energyDemand"
          :height="10">
        </v-progress-linear>
      </v-col> -->
      <v-col cols="4" class="caption text-center pt-0">Score: {{points}}
        <v-progress-linear 
          color="leaves_primary"
          v-model="points"
          :height="10">
        </v-progress-linear>
      </v-col>
    </v-row>

    <v-row class="justify-start">
      <v-col cols="auto" class="" v-if="renderComponent">
        <GraphBar v-if="showGraphBar" class="EnergyBar" :elements="energyBarElementsCalc" :titles="EnergyBarTitles" :titlesOff="true" :size="graphSize" />     
      </v-col>
      <v-col class="ma-0 pa-0 mt-n4 mr-15 ml-n2">
        <FullCalendar  ref="fullCalendarRef" :options="calendarOptions" />
      </v-col>
    </v-row>  
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GraphBar from "../components/GraphBar";
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import Locale from "@fullcalendar/core/locales/de";
import { generateIconSvg } from "../utils/generateIconSvg";


export default {
  name: "ElectricityScreen",
  props: { radialIcon: null, myIssues: null},
  components: {
    GraphBar,
    FullCalendar
  },
  data() {
    return {
      points: 0,
      renderComponent: true,
      // energyBarElements: [[0,0],[1,0],[3,0],[5,0],[7,0],[8,0],[8,0],[10,0],[10,0],[7,0],[4,0],[6,0],[6,0],[4,0],[3,0],[1,0],[0,0]],
      //energyBarTitles: ["05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00"],
      energyBarElementsCalc: [],
      showGraphBar: true,
      calendarOptions: {},

      calendar: {},
      event: {},
      select: {},
      slotDuration: 60,
      //select_selected: false,
      //event_selected: false,
     
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrapPlugin,
      ],

      calendarHeader: {
        left: "",
        center: "",
        right: "",
      },
      calendarView: "timeGridDay",
      calendarApi: {},
      calendarDateStart: "",
      calendarDateEnd: "",
      teamViewDateStart: "",
      locations: ["Cal1"],
      actual_location: 0,
      fullCalendarName: ["fullCalendar"],
      last_event: {
        name: "",
      },

      maxSelectHours: 24,
      Locale: Locale,
      order_number: "",
      delivery_company: "",

      headers_events: [
        {
          text: "Order Number",
          align: "start",
          sortable: true,
          value: "order_number",
        },
        { text: "Status", value: "status" },
        { text: "Company", value: "company" },
        { text: "From", value: "starttime" },
        { text: "To", value: "endtime" },
        { text: "Commit Arrival", value: "actions", sortable: false },
      ],
      minTime: { hours: 5 },
      maxTime: { hours: 22 },
      windowWidth: 0,
      windowHeight: 0,
      event_color_selected: "blue",
      event_border_color_selected: "white",
      event_border_color_unselected: "#555",

      selectedDate: new Date(),
      lastCreateUpdateTime: new Date(),
      delayMsForCreateUpdate: 200,

      icon: "",
      loadedIcons: []
    };
  },
  created: async function () {
    // console.log("EnergyScreen created");
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.minTime.hours = parseInt(this.$moment(this.EnergyBarTitles[0], "HH:mm").format('HH'));
    this.maxTime.hours = parseInt(this.$moment(this.EnergyBarTitles[this.EnergyBarTitles.length - 1], "HH:mm").format('HH')) + 1;

    this.calendarOptions = {
      plugins: this.calendarPlugins,
      initialView: this.calendarView,
      //dateClick: this.handleDateClick,
      events: [],
      headerToolbar: this.calendarHeader,
      weekends: true,
      selectable: true,
      nowIndicator: true,
      slotDuration: { minutes: this.slotDuration },
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
      },
      slotLabelInterval: { hours: 1 },
      locale: this.Locale,
      unselectAuto: true,
      selectOverlap: true,

      eventConstraint: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [ 0, 1, 2, 3, 4, 5, 6 ], // Monday - Sunday

        startTime: this.minTime, // a start time (10am in this example)
        endTime: this.maxTime, // an end time (6pm in this example)
      },

      slotMinTime: this.minTime,
      slotMaxTime: this.maxTime,
      allDaySlot: false,
      dayHeaderFormat: { weekday: "short" },
      firstDay: 1,

      dateClick: this.dateClick,
      eventClick: this.eventClick,
      select: this.dateSelect,
      eventDrop: this.eventDrop,
      eventResizeStart: this.eventResizeStart,
      eventResize: this.eventResize,
      eventDragStart: this.eventDragStart,
      eventDragStop: this.eventDragStop,
      // eventLeave: this.eventLeave,

      //draggable: true,
      dragRevertDuration: 1000,
      //themeSystem: "default",
      themeSystem: "standard",
      displayEventTime: false,
      longPressDelay: 200,
      editable: true,
      //eventOverlap: this.overlapAlarm,
      eventOverlap: true,
      eventTextColor: "white",
      eventBorderColor: this.event_border_color_unselected,
      //eventRender="customEventRender"
      eventContent: this.customEventRender,
      scrollTimeReset: false,
    };
    this.calendarOptions.height = window.innerHeight * 0.6 + this.graphSize;
 

  },
  async mounted() {

    // console.log("EnergyScreen mounted");
    //this.calcSolarFc();
    
    this.calendar = this.$refs.fullCalendarRef;
    this.calendarApi = this.calendar.getApi();
    this.calendarApi.updateSize();

    this.$moment.locale("en");


    // while (document.getElementsByClassName("fc-timegrid-slot-label").length > 0) {
    //   let _elements =document.getElementsByClassName("fc-timegrid-slot-label");
    //   for( let el of _elements) {
    //     el.remove();
    //     //console.log(el.innerHTML);
    //   }
    // }
   
    this.loadEvents();

  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),
    ...mapActions("event", [
      "getEvents",
      "deleteEvent",
      "updateEvent",
      "createEvent",
    ]),
    

    calcEnergyConsumptionPerHour() {
      // console.log("calcEnergyConsumptionPerHour ", this.EnergyBarElements);
      this.energyBarElementsCalc = JSON.parse(JSON.stringify(this.EnergyBarElements));
      for (let event of this.calendarOptions.events) {  
        // let userdevice = _.find(this.UserDevices, {icon: event.issue});
        let userdevice = _.find(this.Locations, {_id: event.location});
        // console.log("event: ", event, userdevice, userdevice.attributes)
        let startHour = parseInt(this.$moment.utc(event.start).local().format('HH')) - this.minTime.hours;
        let endHour = parseInt(this.$moment.utc(event.end).local().format('HH')) - this.minTime.hours - 1;
        // console.log(startHour,endHour, parseInt(userdevice.attributes.wh / 1000));
        for (let i = startHour; i <= endHour; i++) {
          this.energyBarElementsCalc[i][1] = this.energyBarElementsCalc[i][1] + parseInt(userdevice.attributes.wh / 1000);
        }
      }

      //console.log("energyBarTitles: ",this.energyBarTitles)
      const barLength = 10;
      let points = 0;
      //ToDo: Prozentuell zur Summe der möglichen Wh der vorhanden Geräte
      for (let timeslot of this.energyBarElementsCalc) {
        //let p = timeslot[0] - timeslot[1];
        //console.log("POINTS: ",points, timeslot, barLength)
     
        if (timeslot[0] >= timeslot[1]) {
          points += timeslot[1] * 3;
        } else {
          points += timeslot[0] * 3;
          if (timeslot[1] <= barLength) {
            points += (timeslot[1]-timeslot[0]);
          } else {
            points += (barLength-timeslot[0]);
          }
        }
        
      }

      
      // let diff = points - this.points;
      
      // this.points = points;
      // //console.log(points);
      // if (diff > 0) {
      //   this.$emit("menuEvent", Math.abs(diff));
      // } else if (diff < 0) {
      //   this.$emit("menuEvent", -Math.abs(diff));
      // }
      this.$emit("menuEvent", points);
      this.points = points;

      // this.$store.state.elements.energyBarElementsLong = this.energyBarElementsCalc;
      
    },

    customEventRender(obj) {
      //console.log("CUSTOM RENDER: ",obj.event.extendedProps)
      let durationMin = this.$moment
        .duration(obj.event.end - obj.event.start)
        .minutes();
      let durationHours = this.$moment
        .duration(obj.event.end - obj.event.start)
        .hours();
      //console.log(obj.event, obj.el, obj.el.getElementsByClassName('fc-time')[0]);
      //obj.el.getElementsByClassName('fc-time')[0].append(document.createTextNode(" (" + durationHours + "h " + durationMin + "min)") );
      let html = "";
      //console.log(this.$moment.duration(obj.event.end - obj.event.start)._milliseconds, this.slotDuration * 60 * 1000)
      // if (
      //   this.$moment.duration(obj.event.end - obj.event.start)._milliseconds >
      //   this.slotDuration * 60 * 1000
      // ) {
      //   html =
      //     "<div><b>" +
      //     obj.timeText +
      //     " (" +
      //     durationHours +
      //     "h " +
      //     durationMin +
      //     "min)</b></div><div style='font-size: 1.0em; margin-top: -1px; line-height: 125%;'>" +
      //     obj.event.title +
      //     "</div>";
      // } else {
      //   html =
      //     "<div><b>" +
      //     obj.timeText +
      //     " (" +
      //     durationHours +
      //     "h " +
      //     durationMin +
      //     "min)</b> " +
      //     obj.event.title +
      //     "</div>";
      // }
      //durationMin, durationHours, this.graphSize
      //
 
      html = "<div style='padding-top: " + ( (durationHours - 1) * this.graphSize / 2 ) + "px;'>" + obj.event.extendedProps.svg + "</div>";
      //console.log(html)
      //if (this.slotDuration)
      return {
        html: html,
      };
    },
     eventClick(info) {
      //console.log("Event Select ", info.event);
      if (!this.teamView) {
        if (this.mySelf) {
          this.recolorEvent();
          this.eventMoved(info);
        } else {
        }
      }
      //console.log("Select Finished")
    },
    async eventMoved(info) {
      if (info.event.id != "") {
        //console.log("Event Moved ", info.event);
        this.last_event = this.calendarOptions.events.find((el) => {
          return el.id == info.event.id;
        });
        //console.log(info.event.start, this.last_event);
        this.last_event.start = info.event.start;
        this.last_event.end = info.event.end;
        this.selectedCostcenter = this.last_event.costcenter;
        //this.last_event.backgroundColor = this.event_color_selected;
        this.last_event.borderColor = this.event_border_color_selected;
        await this.commitEvent(this.last_event, false);
      }
    },

    async eventResize(info) {
      //console.log("Event Resize ");
      this.recolorEvent();
      this.eventMoved(info);
      this.dragging = false;
      //await this.commitEvent(this.last_event, false);
      this.calcEnergyConsumptionPerHour();
    },

    eventResizeStart(e) {
      //console.log("Event resizeStart")
      this.dragging = true;
    },

    eventDragStart(e) {
      //console.log("Event dragStart")
      this.dragging = true;
    },

    async eventDragStop(e) {
      //console.log("Event dragStop");
      // let trashEl = document.getElementById('fcTrash') //as HTMLElement;

      //console.log(this.calendar.$el.offsetLeft);
      // console.log(this.calendar);
      let x1 = this.calendar.$el.offsetLeft;
      let x2 = this.calendar.$el.offsetLeft + this.calendar.$el.offsetWidth;
      let y1 = this.calendar.$el.offsetTop;
      let y2 = this.calendar.$el.offsetTop + this.calendar.$el.offsetHeight;

      let page = e.jsEvent;
      if (!page.pageX) {
        page = e.jsEvent.changedTouches[0];
      }
      //console.log(x1,x2,y1,y2, page.pageX, page.pageY);

      if (page.pageY < y1 || page.pageY > y2 || page.pageX < x1 || page.pageX > x2) {
          let event = JSON.parse(JSON.stringify(e.event));
          event._id = event.id;
          //console.log("Event delete ", event)
          this.cancelEvent(event);
      }
      
    },

    eventDrop(info) {
      //console.log("Event Dropped");
      this.dragging = false;
      this.recolorEvent();
      this.eventMoved(info);
      this.calcEnergyConsumptionPerHour();
    },


    dateClick(event) {},

    async dateSelect(new_event) {
      let duration = new Date() - this.lastCreateUpdateTime;
      //console.log("duration: ", duration)
      if (duration > this.delayMsForCreateUpdate) {
        // console.log("new_event", new_event, this.radialIcon)
        //await this.getMySelf();
        //console.log(this.statusMySelf);
        if (this.statusMySelf === "failure") {
          //this.$router.push({path: "cryptologin"})
          this.logout();
        }

        //console.log("New Event / Date Select", new_event);
        // if (!this.description) {
        //   this.description = "";
        // }
        // this.description = this.description.replace("|", "/");
        

        let event = {
          user_id: this.selectedUser,
          start: new_event.start,
          end: new_event.end,
          //name: this.compIssueLabel + this.description,
          name: this.radialIcon.name,
          issue: this.radialIcon._id,
          costcenter: null,
          location: this.radialIcon.location,
        };
        //console.log(event)
        await this.createEvent(event);
        if (this.Event.statusCreate == "success") {
         

          this.lastCreateUpdateTime = new Date();
          //console.log("Create New success ", event);

          let created_event = JSON.parse(
            JSON.stringify(this.Event.CreatedEvent)
          );
          //created_event.title = this.compIssueLabel2(event) + event.name;

          created_event.borderColor = this.event_border_color_selected;
          created_event.id = created_event._id;

          // const issue = _.find(this.myIssues, { _id: created_event.issue });
          // created_event.backgroundColor = issue.primary;

          let setColors = {};
          if (this.$vuetify.theme.isDark) {
            setColors.textcolor = this.$vuetify.theme.themes.dark[this.radialIcon.textcolor];
            setColors.primary = this.$vuetify.theme.themes.dark[this.radialIcon.primary];
            setColors.secondary = this.$vuetify.theme.themes.dark[this.radialIcon.secondary];
          } else {
            setColors.textcolor = this.$vuetify.theme.themes.light[this.radialIcon.textcolor];
            setColors.primary = this.$vuetify.theme.themes.light[this.radialIcon.primary];
            setColors.secondary = this.$vuetify.theme.themes.light[this.radialIcon.secondary];
          }
          //console.log(this.radialIcon, setColors)
          created_event.svg = await generateIconSvg(this.radialIcon.icon, setColors.primary , created_event._id, 30, setColors.secondary, "");


          this.recolorEvent();
          this.calendarOptions.events.push(created_event);

          // this.last_event = _.find(this.calendarOptions.events, {id: created_event.id});
          this.last_event = created_event;
          // await this.readUser({
          //   id: this.selectedUser,
          //   location_id: null //this.selectedLocation._id,
          // });
        } else {
          this.snackbar_text = "Create failed";
          this.snackbar_error = true;
          this.snackbar = true;
        }
      } else {
        this.loadEvents(this.isWeekView);
      }
      this.calcEnergyConsumptionPerHour();
    },

    checkCalendarNext() {
      this.swipeDirection = 1;
      if (this.isTimeTracking) {
        this.showTrackingAlert = true;
      } else {
        this.calendarSwitch();
      }
    },

    checkCalendarPrev() {
      this.swipeDirection = 2;
      if (this.isTimeTracking) {
        this.showTrackingAlert = true;
      } else {
        this.calendarSwitch();
      }
    },
    async loadEvents(week) {
      //let st = new Date();
      //console.log(" Start load Events ", st);
      let today_start = this.$moment(this.selectedDate)
        .startOf("day")
        .format("X");
      let today_end = this.$moment(this.selectedDate).endOf("day").format("X");
      if (week) {
        today_start = this.$moment(this.selectedDate)
          .startOf("week")
          .format("X");
        today_end = this.$moment(this.selectedDate).endOf("week").format("X");
      }
      // if (this.mySelf.roles.includes("admin")) {
      //   await this.getEvents({
      //     from: today_start,
      //     to: today_end,
      //     location: this.selectedLocation._id,
      //   });
      // } else {
      await this.getEvents({
        from: today_start,
        to: today_end,
        _id: this.mySelf._id,
      });
      // }

      //console.log(" end load Events ",today_start,today_end);

      let _events = JSON.parse(JSON.stringify(this.Events));

      // if (this.mySelf.roles.includes("admin")) {
      //   _events = _.filter(_events, { user_id: this.selectedUser });
      // }

      for (let _event of _events) {
        try {
          _event.id = _event._id;
          //Compatibility old version
          // if (_event.name.lastIndexOf("|") < 0) {
          //   _event.title = this.compIssueLabel2(_event) + _event.name;
          // } else {
          _event.title = _event.name;
          // }
          //console.log(_event, this.myIssues)
          let issue = JSON.parse(JSON.stringify(_.find(this.myIssues, { _id: _event.issue })));
          //console.log(issue, this.myIssues)
          if (this.$vuetify.theme.isDark) {
            issue.textcolor = this.$vuetify.theme.themes.dark[issue.textcolor];
            issue.primary = this.$vuetify.theme.themes.dark[issue.primary];
            issue.secondary = this.$vuetify.theme.themes.dark[issue.secondary];
          } else {
            issue.textcolor = this.$vuetify.theme.themes.light[issue.textcolor];
            issue.primary = this.$vuetify.theme.themes.light[issue.primary];
            issue.secondary = this.$vuetify.theme.themes.light[issue.secondary];
          }
          //issue = this.getThemeColors(issue);
          
          //_event.backgroundColor = issue.primary;
          _event.svg = await generateIconSvg(issue.icon, issue.primary , _event._id, 30, issue.secondary, "");
        } catch (err) {
          console.log("issue not found");
        }
      }
      this.calendarOptions.events = _events;
      this.calcEnergyConsumptionPerHour();
    },

    // getThemeColors(issue) {
    //   // console.log(issue)
    //   if (this.$vuetify.theme.isDark) {
    //     issue.textcolor = this.$vuetify.theme.themes.dark[issue.textcolor];
    //     issue.primary = this.$vuetify.theme.themes.dark[issue.primary];
    //     issue.secondary = this.$vuetify.theme.themes.dark[issue.secondary];
    //   } else {
    //     issue.textcolor = this.$vuetify.theme.themes.light[issue.textcolor];
    //     issue.primary = this.$vuetify.theme.themes.light[issue.primary];
    //     issue.secondary = this.$vuetify.theme.themes.light[issue.secondary];
    //   }
    //   return issue;
    // },

    async commitEvent(own_event, changeName) {
      let duration = new Date() - this.lastCreateUpdateTime;
      //console.log("duration: ", duration)
      if (duration > this.delayMsForCreateUpdate) {
        //console.log("COMMIT: ", own_event)
        if (own_event.id) {
          let description = own_event.name;
          let event = {};

          event._id = own_event._id;
          event.user_id = own_event.user_id;
          event.start = own_event.start;
          event.end = own_event.end;
          event.name = own_event.name;
          event.issue = own_event.issue;
          event.costcenter = null;
          event.location = own_event.location;

          if (changeName) {
            event.name = description;
            event.issue = null;
          }
          await this.updateEvent(event);

          if (this.Event.statusUpdate == "success") {
           
            this.lastCreateUpdateTime = new Date();

            own_event.name = event.name;
            own_event.costcenter = event.costcenter;
            own_event.issue = event.issue;

          } else {
            this.snackbar_text = "Update failed";
            this.snackbar_error = true;
            this.snackbar = true;
          }
        } else {
          this.snackbar_text = "Please select an event";
          this.snackbar_error = true;
          this.snackbar = true;
        }
      } else {
        this.loadEvents(this.isWeekView);
      }
    },

     async cancelEvent(event) {

      await this.deleteEvent(event._id);

      if (this.Event.statusDelete == "success") {         
        for (let idx in this.calendarOptions.events) {
          if (this.calendarOptions.events[idx]._id == event._id) {
            this.calendarOptions.events.splice(idx, 1);
          }
        }
      }
      this.calcEnergyConsumptionPerHour();
    },

    recolorEvent() {
      if (typeof this.last_event !== "undefined") {
        if (this.last_event.borderColor == this.event_border_color_selected) {      
          this.last_event.borderColor = this.event_border_color_unselected;
        }
      }
    },
  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      Events: ({ event }) => event.Events,
      Event: (state) => state.event,
      // UserDevice: ({ userdevice }) => userdevice,
      // UserDevices: ({ userdevice }) => userdevice.UserDevices,
      ForeCast: ({ forecast }) => forecast,
      SolarFc: ({ forecast }) => forecast.SolarFc,
      EnergyBarElements: ( state ) => state.elements.energyBarElementsLong,
      EnergyBarTitles: ( state ) => state.elements.energyBarElementsLongTitles,
      Locations: ({ location }) => location.Locations
    }),

    formatedDate() {
      let _return;
      // if (this.selectedDate == this.$moment().format(this.dateFormat)) {
      //   _return = "ToDay";
      // } else {
        _return =  this.$moment(this.selectedDate, this.dateFormat).format("dd DD.MM.YY");
      // }    
      return _return;
    },

    graphSize() {
      let size = window.innerHeight * 0.6;
      return parseInt(size / this.EnergyBarElements.length);
      
    },
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_primary,
          '--bgSecondary': this.$vuetify.theme.themes.dark.background_secondary,
          '--slotHeight': this.graphSize - 0.11 + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_primary,
          '--bgSecondary': this.$vuetify.theme.themes.light.background_secondary,
          '--slotHeight': this.graphSize - 0.11 + "px",
        }
      }
    }
  },
  watch: {
    // SolarFc() {
    //   // console.log("SolarFc changed")
    //   this.calcSolarFc();
    // },
    async EnergyBarElements() {
      // console.log("EnergyBarElements changed");
      this.showGraphBar = false;
      this.$nextTick(() => {
        this.showGraphBar = true;
      });
      
    }
    // elements() {
    //   //console.log("elements changed");
    //   //this.$forceUpdate();
    //   this.renderComponent = false;
    //   this.$nextTick(() => {
    //     this.renderComponent = true;
    //   });
    // }
    // last_event() {
    //   console.log("Event changed")
    // }
  },
};
</script>

<style lang='scss'>

.fc-event {
  border: 2px solid  #FFFFFF; // default BORDER color
  background-color: var(--bgSecondary); // default BACKGROUND color
  color: #fff; // default TEXT color
  font-size: 0.7em; // EDIT HERE
  cursor: default;
  padding-left: 4px;
  width: 45px !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none;
}
.fc-event-main {
  margin-top: -7px;
  // margin-left: 3px;
}



.fc-day-today {
  background-color: var(--bg) !important; //today background
}
.fc-timegrid-slot {
  // height: 26px !important;
  // font-size: 10px;
  height: var(--slotHeight) !important;
  font-size: 10px;
  //background: #FFF !important;
}
.fc-scrollgrid-sync-inner {
  height: 0px !important;
  width: 0px !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #000000 !important; //Gitter
}


.fc-theme-standard {
  // --fc-page-bg-color: rgb(255, 0, 0)  !important;
  // --fc-neutral-bg-color: rgba(255, 0, 0, 0.3) !important;
  // --fc-neutral-text-color: #ca0000 !important;
  --fc-border-color: var(--bg) !important; //Äusserer Rand

}

// .v-sheet {
//   background-color: #000000 !important;
//   color: rgb(203, 203, 203, 0.9) !important; //Schrift
// }
</style>