<template>
  <div>
    <v-container fluid grid-list-xs style="height: 2000px;">
      <v-row row wrap align-center justify-center>
        <v-col xs8 >
          <div
          class="scroll-y"
          style="height: 690px;"
          >
            <v-toolbar color="white" class="elevation-1">
              <v-toolbar-title>Clients</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details    
              ></v-text-field>
              <v-dialog v-model="dialog" max-width="750px">
                <template v-slot:activator="{ on }"> 
                  <v-btn color="green" left fab small dark class="mb-2" v-on="on">
                    <v-icon>
                      add
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
        
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-row wrap>
                        <v-col xs12 sm6 md6>
                          <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                        </v-col>
                        <v-flex xs12 sm6 md4 v-if="Global.locationTypes">
                          <v-select 
                            :items="Global.locationTypes"
                            item-text="value"
                            item-value="_id"                             
                            label="Location Type" 
                            v-model="editedItem.type"
                          
                          >
                          </v-select>
                        </v-flex>
                        <v-col xs12 sm6 md6>
                          <v-text-field v-model="editedItem.user" label="user"></v-text-field>
                        </v-col>

                        <v-col xs12 sm6 md6>
                          <v-select 
                              :items="locationNames" 
                              label="Parent_Location" 
                              v-model="parent_location_index"
                              v-on:change="set_parent_location"
                              item-text="name"
                              item-value="index"
                            >
                            </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
        
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"  @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1"  @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="filteredLocations"
              class="elevation-1"
              :search="search"
              must-sort
            >
            <template v-slot:item.user="{ item }">
               <td  width="180px">
                  <v-list dense >         
                    <draggable 
                     
                      v-model="item.user" 
                      :options="{group:'people'}" 
                      style="min-height: 10px" 
                      @change='logDrop(item._id)'      
                              
                    >
                      <template v-for="user in item.user">
                        <v-list-item :key="user._id" >
                          <v-list-item-content class="caption" >
                            <v-list-item-title>{{comp_user_id(user).fullname}}</v-list-item-title>
                            <v-list-item-subtitle>{{comp_user_id(user).email}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      
                      </template>

                    </draggable>
                  </v-list>
                </td>
            </template>
              <!-- <template v-slot:items="props" class="text-xs-right">
              
                <td>{{ props.item.name }}</td>
                
                <td>{{ comp_locationTypes(props.item.type) }}</td>
             
                <td>
                  <v-list dense>         
                    <draggable 
                      v-model="props.item.user" 
                      :options="{group:'people'}" 
                      style="min-height: 10px" 
                      @change='logDrop(props.item._id)'      
                              
                    >
                      <template v-for="item in props.item.user">
                        <v-list-item :key="item._id" >
                          <v-list-item-content class="caption" >
                            <v-list-item-title>{{comp_user_id(item).fullname}}</v-list-item-title>
                            <v-list-item-subtitle>{{comp_user_id(item).email}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      
                      </template>
                    </draggable>
                  </v-list>
                </td>
        

                <td class="justify-center layout px-0">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(props.item)"
                  >
                    edit
                  </v-icon>
                  <v-icon 
                      small
                      @click="deleteItem(props.item)"
                    >
                      cancel
                    </v-icon>
                </td>
              </template> -->
            </v-data-table>
          </div>
        </v-col>
        <v-col xs2 class="caption elevation-1 pa-0 ma-0">
          <div
            class="scroll-y"
            style="height: 690px;"
          >
            <v-list dense>
              <p class="title">Users</p>
              <v-divider></v-divider>
              <draggable 
                :list="sortedUsersId" 
                :options="{group:{name: 'people', pull: 'clone', put: true}}"
                style="min-height: 10px"
                >
                <template v-for="item in sortedUsers">
                  <v-list-item :key="item._id" >
                    <v-list-item-content>
                      <v-list-item-title class="caption ">{{item.fullname}} ({{item.email}})</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </draggable>
            </v-list>
          </div>
        </v-col>
       
      </v-row>
      <v-row pt-5>
        <v-col xs8>
          <br>
          <p class="title">Hierarchy</p>
          <v-divider></v-divider>
          <!--<nested-draggable  :child="treeLocations" v-model="treeDrop" />-->
          <nested-draggable  :child="treeLocations"/>
        </v-col>
      
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import api from "../api";
import draggable from 'vuedraggable'
import nestedDraggable from "./nested";

export default {
  name: "location",
  components: {
    draggable,
    nestedDraggable
  },
  data() {
    return {
    
      location: {},
      locations: [],
      dialog: false,
      search: '',
      parent_location_index: -1,
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        //{ text: 'Parent', value: 'parent' },
        { text: 'type', value: 'type' },
        { text: 'user', value: 'user' },

        { text: 'Actions', value: 'name', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        parent: null,
        type: '',
        user: []
      },
      defaultItem: {
        name: '',
        parent: null,
        type: '',
        user: []
      },
      sortedUsersId: [],
      sortedUsers: [],
      /*items2: [] ,*/
      treeLocations: [],
      Global: {
        locationTypes: ['root', 'client']
      }
    }
    
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(
      "location", [
        "getLocation",
        "deleteLocation",
        "updateLocation",
        "createLocation",
        "deactivateLocation"
        ]
      ),
    ...mapActions(
      "user", ["getUsers"]
      ),
   
   
    async initData() {
      await this.getLocation({})
      await this.getUsers({})

    },
    editItem (item) {
      this.editedIndex = this.Locations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.parent_location_index = this.Locations.findIndex((loc) => {return loc._id == this.editedItem.parent})
      this.dialog = true
    },

    async deleteItem (item) {
      const index = this.Locations.indexOf(item)
      console.log("ITEM: " + item._id)
      confirm('Are you sure you want to delete this item?') && await this.deleteLocation(item._id)
      this.getLocation({})
    },

    async deactivateItem (item) {
      const index = this.Locations.indexOf(item)
      //console.log("ITEM: " + item._id)
      //confirm('Are you sure you want to deactivate this item?') && 
      await this.deactivateLocation(item._id)
      this.getLocation({})
    },

    async activateItem (item) {
      const index = this.Locations.indexOf(item)
      await this.deactivateLocation(item._id)
      this.getLocation({})
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    async save () {
      console.log(this.editItem)
      if (this.editedIndex > -1) {
        await this.updateLocation(this.editedItem)
      } else {  //new item
        await this.createLocation(this.editedItem)
      }
      this.getLocation({})
      this.close()
    },

    comp_user (users) {
      try {
        let user_array = []
        //console.log(this.Users)
        users.forEach(obj => {
          user_array.push(this.Users.filter(fobj => {return fobj._id == obj})[0].email)
        }) 
        return user_array
      }
      catch {
        return []
      }
    },
    comp_user_id (id) {
      //console.log(id)
      try {
        return {fullname: this.Users.filter(fobj => {return fobj._id == id})[0].fullname , email: this.Users.filter(fobj => {return fobj._id == id})[0].email}
      }
      catch {
        return ""
      }
    },

    comp_location (id) {
      try {
        return this.Locations.filter(obj => {return obj._id == id})[0].name
      }
      catch {
        return ""
      }
    },

    comp_locationTypes (id) {
      //console.log(this.Global.locationTypes)
      try {
         return this.Global.locationTypes.filter(obj => {return obj._id == id})[0].value
         
      }
      catch {
        return ""
      }
    },
    /*findItemNested(arr, itemId, nestingKey) {
      //console.log(arr)
      arr.reduce((a, item) => {
          if (a) return a
          if (item._id === itemId) return item
          if (item[nestingKey]) return this.findItemNested(item[nestingKey], itemId, nestingKey)
      })
    },

    findById(arr, id, nestingKey) {
      
      // if empty array then return
      if(arr.length == 0) return
      
      // return element if found else collect all children(or other nestedKey) array and run this function
      return arr.find(d => d._id == id) 
          || this.findById(arr.flatMap(d => d[nestingKey] || []), id)
          || null
    },*/

    customFilter(object, _id){
      if (object) {
        if(object.hasOwnProperty('_id') && object["_id"] == _id)
            return object

        for(let i=0; i<Object.keys(object).length; i++){
            if(typeof object[Object.keys(object)[i]] == "object"){
                let o = this.customFilter(object[Object.keys(object)[i]], _id)
                if(o != null)
                    return o
            }
        }
      }
      return null
    },

    comp_tree_location () {
        this.treeLocations = _.cloneDeep(this.Locations.filter(obj => {return obj.parent == null}))
        this.treeLocations.forEach(loc => {
          loc.type_name = this.comp_locationTypes(loc.type)
          loc.child = []
        })
        let tree = _.cloneDeep(this.Locations.filter(obj => {return obj.parent != null}))        
        let iteration = 0
        
        while (tree.length > 0) {
          let treeindex = []
          tree.forEach( (loc,index) => {

            let findobj = this.customFilter(this.treeLocations, loc.parent)

            if (findobj) {
              loc.child = []
              loc.type_name = this.comp_locationTypes(loc.type)
              findobj.child.push(loc)
              treeindex.push(index)
            }       
          })
          _.pullAt(tree, treeindex)
          iteration++
          if (iteration > 1000) break
        }
    },

    set_parent_location (index) {
      this.editedItem.parent = this.Locations[index]._id
    },

    cloneUser(item) {
      console.log("Clone")
      //console.log(item)
      //console.log(index)
      /*if(findIndex(this.items2, function(o) { return o._id == item._id; }) == -1) {
        return item._id
      }*/
    },

    logDrop(item) {
      console.log("Drop")
      //console.log(item)
      this.editedIndex = _.findIndex(this.Locations, function(o) { return o._id == item; })
      this.editedItem = this.Locations[this.editedIndex]
      //console.log(this.editedIndex)
      //console.log(this.editedItem)
      this.save()
      
      //console.log(this.editedIndex)
      this.editedIndex = -1
      this.editedItem = []
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId)


    },
   
    logEnd(item) {
      console.log("End")
      console.log(item)
    },

    logMove(item) {
      console.log("Move")
      console.log(item)
    },

    changeTree(item) {
      console.log("TREE")
      console.log(item)
    },
  
  },
  computed: {
    ...mapState({
      Locations: ({ location }) => location.Locations,
      Users: ({ user }) => user.Users,
      moveLocation: ({ location }) => location.moveLocation,

    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    filteredLocations() {
      /*return this.Locations.filter((p) => {
        return p.active === true
      })
      */
      console.log("filteredLocations")
      this.locations = this.Locations
      //this.locations = _.cloneDeep(this.Locations)
      
      return this.locations
    },
    locationNames () {
      let locnames = [{name: "", index: -1}]
      this.Locations.forEach((loc, index) => {
        locnames.push({name: loc.name, index: index})
      })
      return locnames
    },
    compSortedUsers () {
      return  _.sortBy(this.Users, 'fullname')
    },
    compSortedUsersId () {
      let sorted = _.sortBy(this.Users, 'fullname')
      let result = []
      sorted.forEach(obj => {
        result.push(obj._id)
      })
      this.sortedUsersId = _.cloneDeep(result)
      return result
    }
    
    /*,
    filteredUsers() {
      this.users = this.Users
      return this.users
    },*/

  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    locations () {
      this.comp_tree_location()
    },
    Users () {
      //console.log("Users loaded")
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId)
      this.sortedUsers = _.cloneDeep(this.compSortedUsers)
    },

    /*treeDrop: {   //funkt bei root nicht deshalb über store -> moveLocation
      deep: true,

      handler() {
        console.log ("WATCH LastChanged")
        console.log(this.treeDrop)   
        this.treeLocations = _.cloneDeep(this.treeLocations)
      }
    },*/
    moveLocation () {
      this.editedIndex = this.Locations.findIndex((loc) => {return loc._id == this.moveLocation.item})
      this.editedItem = this.Locations[this.editedIndex]
      this.editedItem.parent = this.moveLocation.parent
      this.treeLocations = _.cloneDeep(this.treeLocations)
      this.save()


    }
  }
}

</script>