import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  statusUpdate: void 0,
  statusDelete: void 0,
  statusCreate: void 0,
  statusDeactivate: void 0,
  statusWeather: void 0,
  statusWeatherUpdate: void 0,
  statusWeatherDelete: void 0,
  statusWeatherCreate: void 0,
  WeatherDaily: [],
  SolarFc: []
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
