import Vue from "vue";
import Vuetify from "vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#125a9e",
        secondary: "#a6e3fa",
        anchor: "#8c9eff",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        community_primary: "#E76F27",
        community_secondary: "#006AD5",
        interaction_primary: "#006AD5",
        interaction_secondary: "#F2E263",
        heating_primary: "#B20000",
        heating_secondary: "#E76F27",
        energy_primary: "#006AD5",
        energy_secondary: "#F2E263",
        others_primary: "#D5E0D2",
        others_secondary: "#98D387",
        leaves_primary: "#59C346",
        leaves_secondary: "#98D387",
        ranking_primary: "#6DB972",
        ranking_secondary: "#59C346",
        // inactive_primary: "#E7E7E7",
        // inactive_secondary: "#8D8D8D",
        inactive_primary: "#000000",
        inactive_secondary: "#444444",
        background_primary: "#FFFFFF",
        background_secondary: "#EEEEEE",
        shading_primary: "#F2E263",
        shading_secondary: "#006AD5", 
      },
      dark: {
        primary: "#125a9e",
        secondary: "#a6e3fa",
        anchor: "#8c9eff",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        community_primary: "#E76F27",
        community_secondary: "#006AD5",
        interaction_primary: "#006AD5",
        interaction_secondary: "#F2E263",
        heating_primary: "#B20000",
        heating_secondary: "#E76F27",
        energy_primary: "#F2E263",
        energy_secondary: "#006AD5",
        others_primary: "#D5E0D2",
        others_secondary: "#98D387",
        leaves_primary: "#59C346",
        leaves_secondary: "#98D387",
        ranking_primary: "#6DB972",
        ranking_secondary: "#59C346",
        inactive_primary: "#E7E7E7",
        inactive_secondary: "#8D8D8D",
        background_primary: "#222222",
        background_secondary: "#1B1B1B",
        shading_primary: "#F2E263",
        shading_secondary: "#006AD5",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {},
  },
};

export default new Vuetify(opts);
