import api from "../../../api";
import router from "../../../router";
import types from "./types";

export default {
  async signUp({ commit }, obj) {
    commit("REQUEST_SIGNUP");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_SIGNUP", await api.signUp(obj));
    } catch (err) {
      commit("FAILURE_REQUEST_SIGNUP");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async activateAccount({ commit }, { token }) {
    commit(types.REQUEST_ACTIVATE_ACCOUNT);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_ACTIVATE_ACCOUNT,
        await api.activateAccount({ token })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_ACTIVATE_ACCOUNT);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async forgotPassword({ commit }, { email }) {
    commit(types.REQUEST_FORGOT_PASSWORD);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_FORGOT_PASSWORD,
        await api.forgotPassword({ email })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_FORGOT_PASSWORD);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async resetPassword({ commit }, { token }) {
    commit(types.REQUEST_RESET_PASSWORD);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_RESET_PASSWORD,
        await api.resetPassword({ token })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_RESET_PASSWORD);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateUser({ commit }, user) {
    commit("REQUEST_UPDATE_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_UPDATE_USER", await api.updateUser(user));
      //router.push({ name: "home" });
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_USER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getUsers({ commit }) {
    commit("REQUEST_USERS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_USERS", await api.getUsers());
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_USERS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createUser({ commit }, item) {
    commit("REQUEST_CREATE_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_CREATE_USER", await api.createUser(item));
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_USER", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async readUser({ commit }, obj) {
    commit("REQUEST_READ_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_READ_USER", await api.readUser(obj));
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_READ_USER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getObsUserData({ commit }) {
    commit("REQUEST_GET_OBS_USER_DATA");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_GET_OBS_USER_DATA", await api.obsUserData());
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_GET_OBS_USER_DATA");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateObsSettings({ commit }, obs_settings) {
    commit("REQUEST_UPDATE_OBS_SETTINGS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_UPDATE_OBS_SETTINGS", await api.updateObsSettings(obs_settings));
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_OBS_SETTINGS", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
