import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/user";
import person from "./modules/person";
import event from "./modules/event";
import icon from "./modules/icon";
import screen from "./modules/screen";
// import costcenter from "./modules/costcenter";
import location from "./modules/location";
import userdevice from "./modules/userdevice";
import forecast from "./modules/forecast";
import dataset from "./modules/dataset";
// import sm_api from "./modules/sm_api";
import global from "./modules/global";
import device from "./modules/device";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    radialLoading: false,
    allLoaded: false,
    drawer: true,
    selectedLocation: undefined,
    selectedScreen: "main",
    elements: {
      rankingBarElements: [],
      performanceBarElements: [],
      barActualElements: [],
      weatherBarElements: [],
      energyBarElementsShort: [],
      energyBarElementsLong: [],
      energyBarElementsLongTitles: ["05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00"],
      heatingBarElements: null,
    },
    authInterval: undefined
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_RADIAL_LOADING(state, value) {
      state.radialLoading = value;
    },
    SET_DRAWER(state, _drawer) {
      state.drawer = _drawer;
    },
    TO_LOGIN(state, value) {
      localStorage.removeItem("Authorization");
      state.auth.mySelf = null;
    },
    SET_ALL_LOADED(state, value) {
      state.allLoaded = value;
    },
    SET_SELECTED_SCREEN(state, value) {
      state.selectedScreen = value;
    },
    SET_ELEMENTS_WEATHER(state, value) {
      state.elements.weatherBarElements = value;
    },
    SET_ELEMENTS_ENERGY_SHORT(state, value) {
      state.elements.energyBarElementsShort = value;
    },
    SET_ELEMENTS_ENERGY_LONG(state, value) {
      state.elements.energyBarElementsLong = value;
    },
    SET_ELEMENTS_RANKING(state, value) {
      state.elements.rankingBarElements = value;
    },
    SET_ELEMENTS_PERFORMANCE(state, value) {
      state.elements.performanceBarElements = value;
    },
    SET_ELEMENTS_ACTUAL(state, value) {
      state.elements.barActualElements = value;
    },
    SET_ELEMENTS_HEATING(state, value) {
      state.elements.heatingBarElements = value;
    },
    SET_LOCATION(state, value) {
      state.selectedLocation = value;
    },
  },
  // actions: {
  //   setAllLoaded({ commit }, value) {
  //     commit("SET_ALL_LOADED", value);
  //   },
  // },
  modules: {
    auth,
    user,
    person,
    event,
    icon,
    screen,
    // costcenter,
    location,
    userdevice,
    forecast,
    dataset,
    // sm_api,
    global,
    device
  }
});
