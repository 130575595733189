<template>
  <v-container class="pa-0 ma-0" :style="cssVars">
     <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3">  
        Settings
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <v-btn @click="changeTheme">Switch Theme</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";



export default {
  name: "SettingsScreen",
  props: { },
  components: {
 
  },
  data() {
    return {
      selectedDate: null,
    };
  },
  created: function () {
    this.selectedDate = this.$moment().format("DD MMM YYYY");
  },
  async mounted() {

   
  },
  methods: {
    ...mapActions("user", [
      "updateObsSettings",
    ]),

    changeTheme () {
      this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark
      // Save Settings
      let theme = "light"
      if (this.$vuetify.theme.isDark) {
        theme = "dark"
      }
      this.updateObsSettings({
        theme: theme
      });
    },
  },
  computed: {
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    }
  },
  watch: {
    
  },
};
</script>

<style lang='scss'>



</style>