const REQUEST_ICONS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_ICONS = (state, { body }) => {
  state.Icons = body;
  state.status = "success";
};

const FAILURE_REQUEST_ICONS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_ICON = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_ICON = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_ICON = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_ICON = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_ICON = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_ICON = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_ICON = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_ICON = (state, { body }) => {
  state.CreatedIcon = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_ICON = state => {
  
  state.statusCreate = "failure";
};


export default {
  REQUEST_ICONS,
  SUCCESS_REQUEST_ICONS,
  FAILURE_REQUEST_ICONS,
  REQUEST_CREATE_ICON,
  SUCCESS_REQUEST_CREATE_ICON,
  FAILURE_REQUEST_CREATE_ICON,
  REQUEST_DELETE_ICON,
  SUCCESS_REQUEST_DELETE_ICON,
  FAILURE_REQUEST_DELETE_ICON,
  REQUEST_UPDATE_ICON,
  SUCCESS_REQUEST_UPDATE_ICON,
  FAILURE_REQUEST_UPDATE_ICON,

};
