<template >
  <v-container class="pa-0 ma-0 mb-n5 ml-sm-n1" v-if="elements.length > 0">
    <v-row>
      <v-col cols="12" class="pa-0 ma-0">
        <v-row no-gutters class="justify-start">
          <v-col cols="1" class="d-flex justify-end" v-if="!titlesOff"> 
            <svg
              height="60"
              width="10"
              class="mr-3"
              style="position: relative"
            >
              <text
                class="font-weight-black"
                style="font-size: 7px"
                x="0 0 0 0 0"
                y="8 14 20 26 32"
                fill="grey"
              >
                {{title}}
              </text>
            </svg>
          </v-col>
          <v-col cols="auto" class="ml-n2">
            <v-btn large :width="btnWidth" :height="btnHeight" class="scoreBarButton mr-1" v-for="elem of elements" :key="elem.name" @click="emitClick(elem)">
              <ShowSVG
                class="mr-1"
                id="ScoreBar"
                :name="elem.icon"
                :title="title"
                :primary="elem.primary"
                :secondary="elem.secondary"
                :size="size"
                text=""
                :glow="glow"
              />
              <div :style="textStyle" :class="elem.primary + '--text'">
                <AnimatedNumber :number="elem.number" :denominator="elem.denominator" :suffix="elem.suffix" :prefix="elem.prefix"/>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapState, mapActions } from "vuex";
//import TextToSVG from "text-to-svg";
import ShowSVG from "../components/ShowSVG";
import AnimatedNumber from "../components/AnimatedNumber";

export default {
  name: "ScoreBar",
  props: { elements: [], title: "", glow: false, size: null, titlesOff: false},
  components: {
    ShowSVG,
    AnimatedNumber
  },
  data() {
    return {
      textStyle: "font-size: 20px",
      btnWidth: "90px",
      btnHeight: "50px"
    };
  },
  created: function () {
    this.textStyle = "font-size: " + parseInt(parseInt(this.size) / 3 * 2) + "px";
    //this.btnWidth = 30 + parseInt(this.size) * 2 + "px"
    //this.btnWidth = 130 + parseInt(this.size) * 2 + "px"
    this.btnWidth = (window.innerWidth - 50 - this.elements.length * 3) / this.elements.length;
    // console.log(this.btnWidth, window.innerWidth, this.elements.length);
    this.btnHeight = 10 + parseInt(this.size) + "px"
  },
  async mounted() {
   
  },
  methods: {
   emitClick(payload) {
    //alert(JSON.stringify(payload));
    this.$emit("menuEvent", payload);
   },
   
  },
  computed: {
  //  calcBtnWidth(element) {
  //   //console.log(element.elements[0]);
  //   return "100px"
  //  }
  },
  watch: {},
};
</script>

<style lang='scss'>
// .mySVGText2 {
//   position: absolute;
//   bottom: 50%;
//   right: 50%;
// }
.scoreBarButton {
  min-width: 50px !important;
}

.v-btn {
  text-transform:none !important;
}
</style>