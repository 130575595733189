<template>
  <v-container class="pa-0 ma-0" :style="cssVars">
    <div class="logo-overlay-loading" v-if="radialLoading">
    </div>
    <v-row class=" ml-0 mr-3 mb-3">
      <v-col cols="8" class="pa-0">
        <v-row v-if="selectedRadio == radioGroup[0]" class="align-center pa-0 ">
          <v-col class="d-flex  my-n3" cols="auto">
            <v-icon @click="dayPrev" x-large>mdi-chevron-left</v-icon>
          </v-col>
          <v-col class="text-center my-n3" v-if="isStandard" @click="setToday">  
            {{formatedDate}}
          </v-col>
          <v-col class="text-center my-n3 red--text" v-else @click="setToday">  
            {{formatedDate}}
          </v-col>
          <v-col class="d-flex  my-n3" cols="auto">
            <v-icon @click="dayNext" x-large>mdi-chevron-right</v-icon>
          </v-col>
        </v-row>

        <v-row  v-else>
          <v-btn-toggle
            v-model="selectedDaysIndex"
            mandatory
          >
            <v-btn v-for="item of weekDayGroup" :key="item">
              {{item}}
            </v-btn> 
          </v-btn-toggle>
        </v-row>  
      </v-col>
      <v-col cols="4" class="pa-0">
        <!-- <v-btn @click="individualSelected = !individualSelected">
          <div v-if="individualSelected">Edit Std</div>
          <div v-else>Edit individualSelected</div>
        </v-btn> -->
        <v-radio-group dense hide-details=false mandatory class="pa-0 ma-0 mt-n4" v-model="selectedRadio">
          <!-- <v-radio 
            v-for="n of radioGroup"
            :key="n"
            :label="`${n}`"
            :value="n"
            color="blue"
          ></v-radio> -->
          <v-radio :value="radioGroup[0]"
            key="0"      
            color="red"
          >
            <template v-slot:label>
              <div class="red--text">{{radioGroup[0]}}</div>
            </template>
          </v-radio>
          <v-radio :value="radioGroup[1]"
            key="1"     
          >
            <template v-slot:label>
              <div>{{radioGroup[1]}}</div>
            </template>          
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
   
   <v-row class="ml-0 mr-3 mb-2 pt-0">
      <v-col cols="12" class="ml-n2 pb-6 mt-n4">
        <v-row > 
          <v-col style="font-size: 25px">
            {{radialIcon.roomname}}
          </v-col>
        </v-row>

        <v-row class="pa-0">
          <v-col cols="4" class="caption ml-2 pt-0 text-center">
            <div style="font-size: 10px;">Actual Shading:</div>
            <div style="font-size: 20px;">50%</div>
          </v-col>
          <v-col cols="4" class="caption ml-n1 pt-0 text-center" >
            <div style="font-size: 10px;">Slat Position:</div>
            <div style="font-size: 20px;">10°</div>
          </v-col>
        </v-row>

        <v-row class="pa-0 pt-2">       
          <v-col cols="4" class="caption text-center pt-0">Flexibility: {{flexibility}}
            <v-progress-linear 
              color="energy_primary"
              v-model="flexibility"
              :height="10">
              <!-- <template v-slot:default="{ value }" >
                <strong>{{ Math.ceil(value) }} %</strong>
              </template> -->
            </v-progress-linear>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="caption blue--text" v-if='selectedRadio == "standard"'> Standard settings can only be changed for future days</v-col>
          <v-col class="caption blue--text" v-else>Only future settings can be changed</v-col>
        </v-row> -->
      </v-col>
    </v-row>

   
    <v-row no-gutters class="" v-for="y of shadingBarY.length" :key="y" >
      <v-col cols="1" style="font-size: 9px;" class="font-weight-black grey--text graphbar mt-2">
        <v-row class="mt-1">
          <v-col cols="12" class="pa-0 text-center">
            {{shadingBarY[y-1]}}
          </v-col>
          <v-col cols="12" class="pa-0 text-center">
            -
          </v-col>
          <v-col cols="12"  class="pa-0 text-center">
            {{calcShadingBarY(y)}}
          </v-col>
        </v-row>        
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col :cols="(shadingBarX.length - 1)" style="font-size: 9px;" class="  bg-grid font-weight-black grey--text ml-5">
        <vue-slider 
          v-model="sliderValue[y-1]"
          :min="shadingBarX[0]"
          :max="shadingBarX[shadingBarX.length - 1]"
          adsorb
          lazy
          :enableCross="false"
          :minRange="25"      
          dotSize="25"
          tooltip="none"
          height="35px"
          :width="cssVars.bgSize"
          :interval="25"
          @change="sliderValueChanged"
        >
        <!-- @drag-start="dragStart(y-1)" -->
          <template v-slot:dot="{ value, index }">
            <!-- <div :class="['custom-dot']" :style="'background-color: ' + getDotColor(value, index) + ';'"></div> -->
            <!-- :style="'z-index: 100;'" -->
            <ShowSVG       
              :id="index"
              name="iconpic_sliderbubble"
              :title="'heatDot_' + y + index"
              :primary="getDotColor(value, index)"
              secondary="black"
              size="25"
              text=""
              glow="false"
            />
          </template>
          <!-- 
          <template v-slot:process="{ start, end, style, index }">
            <div class="vue-slider-process custom-class" :style="[style, customStyle]">
              a
            </div>
          </template> -->
        </vue-slider>
      </v-col>
    </v-row>
    <v-row no-gutters class="ml-2 mt-3">
      <v-col cols="2">
      </v-col>

      <v-col cols="1" style="font-size: 9px;" class="text-center font-weight-black grey--text graphbar"  v-for="x of shadingBarX" :key="x" >
        <ShowSVG
          class=""
          :id="x"
          :name="'iconpic_shade_open' + x"
          :title="'shadingIconX_' + x"
          primary="shading_primary"
          secondary="shading_secondary"
          size="30"
          text=""
          glow="false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import ShowSVG from "../components/ShowSVG";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: "shadingScreen",
  props: { radialIcon: null },
  components: {
    VueSlider,
    ShowSVG
  },
  data() {
    return {
      selectedDate: new Date(),
      shadingBarY: ["04:00","07:00","11:00","14:00","18:00","22:00"],
      utcShadingBarY: [2,5,9,12,16,20],
      shadingBarX: [0,25,50,75,100],
      sliderValue: [],

      individualSelected: true,
      selectedDaysIndex: null,
      weekDayGroup: ["Mon-Thu", "Fri", "Sat-Sun"],
      radioGroup: ["individual","standard"],
      selectedRadio: "individual",
      dateFormat: "DD.MM.YY",
      // watchSlider: false,
      shadingStandard: [],
      isStandard: false,
      deviceTypeId: null,
      flexibility: 100,
    };
  },
  created: async function () {
    this.deviceTypeId = (_.find(this.Global.deviceTypes, {value: "shading"}))._id;
    this.createShadingBarY();
    await this.setToday();  
  },
  async mounted() {
  //  console.log(this.$moment(this.selectedDate,this.dateFormat).isoWeekday());
  },
  methods: {
    ...mapActions("dataset", [
      "getDatasets",
      "createDataset",
      "updateDataset",
      "deleteDataset",
    ]),
    async setToday() {
      this.selectedDate = this.$moment().format(this.dateFormat);
      await this.loadDatasets();
    },

    sliderValueChanged() {
      // console.log("slider Value changed");
      //  if (this.watchSlider) {   
      //     console.log("WATCHER ", this.watchSlider)
      //     this.watchSlider = false;
        this.saveGroupDays();
      //   }  
    },
    
    getDotColor(value, index) {
      if(index % 2 != 0) {
        return "#CCCC00";
      } else {
        return "#000000";
      }
    },

    createShadingBarY() {
      this.shadingBarY = [];
      
      for (let i of this.utcShadingBarY) {
        let hour = String(i + (this.$moment().utcOffset() / 60));
        hour = hour + ":00";
        if (hour.length < 5) {
          hour = "0" + hour;
        }
        this.shadingBarY.push(hour);
      }
    },

    calcShadingBarY(index) {
      if (this.shadingBarY.length <= index) {
        return this.shadingBarY[0];
      } else {
        return this.shadingBarY[index];
      }
    },

    async dayPrev() {
      // this.watchSlider = false;
      this.selectedDate = this.$moment(this.selectedDate,this.dateFormat).subtract(1, "day").format(this.dateFormat);
      await this.loadDatasets();
    },

    async dayNext() {
      // this.watchSlider = false;
      this.selectedDate = this.$moment(this.selectedDate,this.dateFormat).add(1, "day").format(this.dateFormat);
      await this.loadDatasets();
    },

    async loadDatasets() {
      this.$store.commit("SET_RADIAL_LOADING", true);
      // this.watchSlider = false;
      // ToDO: Loading several times (ind loc, std loc, std) should be optimized
      // console.log("loadDatasets 0 ",  this.radialIcon.location);
      if (this.selectedRadio == "individual") {
        await this.getDatasets({from: this.$moment(this.selectedDate,this.dateFormat).startOf("day").format('X'), to: this.$moment(this.selectedDate,this.dateFormat).endOf("day").format('X'), profile: this.selectedRadio, data_type: this.deviceTypeId, location: this.radialIcon.location});
        if (this.Datasets.length > 0 ) {
          this.isStandard = false;
        } else {
          this.isStandard = true;
        }
      }
      if (this.selectedRadio == "standard") {
        //load Standard for selectedDay   
        let _selectedDateStart = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(this.calcDays.start, "days");
        let _selectedDateEnd = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(this.calcDays.end, "days").endOf("day");
        await this.getDatasets({from: _selectedDateStart.format('X'), to: _selectedDateEnd.format('X'), profile: "standard", data_type: this.deviceTypeId, location: this.radialIcon.location});
        // console.log("loadDatasets 2 S ",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        if (this.Datasets.length == 0) {
          await this.getDatasets({from: _selectedDateStart.format('X'), to: _selectedDateEnd.format('X'), profile: "standard", data_type: this.deviceTypeId, location: ''});
          // console.log("loadDatasets 3 S ",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        }
      } 
      // console.log("Load DataSets ", this.Datasets)
      if (this.selectedRadio == "individual" && this.Datasets.length == 0) {
        //load Standard for selected Day
        // this.isStandard = true;
        let _day = this.$moment(this.selectedDate,this.dateFormat).isoWeekday() - 1;
        let _selectedDate = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(_day, "days");
        // console.log("loadDatasets 1 I ",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        await this.getDatasets({from: _selectedDate.startOf("day").format('X'), to: _selectedDate.endOf("day").format('X'), profile: "standard", data_type: this.deviceTypeId, location: this.radialIcon.location} );
        // console.log("loadDatasets 2 I",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        if (this.Datasets.length == 0) {
          await this.getDatasets({from: _selectedDate.startOf("day").format('X'), to: _selectedDate.endOf("day").format('X'), profile: "standard", data_type: this.deviceTypeId, location: ''} );
          // console.log("loadDatasets 3 I",  this.Datasets.length, this.Datasets, this.radialIcon.location);
        }
        // console.log("Load Standard DataSets ", this.Datasets, _selectedDate, _day)
      } 
      //else {
      //   this.isStandard = false;
      // }  

      // console.log("loadDatasets 4",  this.Datasets.length, this.Datasets, this.radialIcon.location);
      
      this.sliderValue = [];
      if (this.Datasets.length == 0) { 
        
        for (let temp of this.shadingBarY) {
          let arr = [this.shadingBarX[0],this.shadingBarX[this.shadingBarX.length - 1]];
          this.sliderValue.push(arr);
          // console.log("sliderVAlue: " , arr,this.shadingBarX.length )
        }
        // await this.saveGroupDays();
      } else {
        for (let data of this.Datasets) {
          // console.log(data)
          let arr = [data.value_from, data.value_to];
          this.sliderValue.push(arr);
        }
      }
      
      // this.$nextTick(() => {
      //   this.watchSlider = true;
      // });
      this.$store.commit("SET_RADIAL_LOADING", false);
    },

    async saveGroupDays() {
      //Schleife über zusammengefasste Tage
      let saveSuccess = false;
      this.$store.commit("SET_RADIAL_LOADING", true);
      if (this.selectedRadio == "standard") {      
        // const _selectedDate = this.selectedDate;
        for (let _day = this.calcDays.start; _day <= this.calcDays.end; _day++) {
          let _selectedDate = this.$moment("01.03.20", this.dateFormat).startOf("isoWeek").add(_day, "days").format(this.dateFormat);
          try {
            saveSuccess = await this.saveDataSet(_selectedDate);
          } catch (err) {
            console.log(err);
          }
        }
        // this.selectedDate = _selectedDate;
      } else {
        try {
          saveSuccess = await this.saveDataSet(this.selectedDate);
        } catch (err) {
          console.log(err);
        }
      }
      if (!saveSuccess) {
        await this.loadDatasets();
      }
      this.$store.commit("SET_RADIAL_LOADING", false);
    },

    async saveDataSet(_date) {
      // console.log("Save Dataset ", _date)
      //ToDo: By Individual check if not standard, else no save
      let dataset = {};
      let saveSuccess = true;

      dataset.user_id = this.mySelf._id;
      dataset.data_type = this.deviceTypeId;
      dataset.location = this.radialIcon.location;
      
      dataset.profile = this.selectedRadio; //"individual";

      let is_new_element = false;
      for (let timeslot = 0; timeslot < this.shadingBarY.length; timeslot++) {
        
        const _times = this.calcTimeSlots(timeslot);
        
        dataset.start = this.$moment.utc(_date, this.dateFormat).add(_times.hours, "hours").add(_times.min, "minutes").toDate();    
        dataset.end = this.$moment.utc(_date, this.dateFormat).add(_times.hours_next, "hours").add(_times.min_next, "minutes").subtract(1, "seconds").toDate();
        
        dataset.value_from = this.sliderValue[timeslot][0];
        dataset.value_to = this.sliderValue[timeslot][1];

        // console.log(this.shadingBarY[timeslot], _times.hours, _times.min, _times.hours_next, _times.min_next, dataset.start, dataset.end, dataset.value_from, dataset.value_to);
        // let localDataset = _.find(this.Datasets, {start: dataset.start, end: dataset.end});
        let localDataset = this.Datasets.find(obj => {
          // console.log(new Date(obj.start) == new Date(dataset.start), new Date(obj.end) == new Date(dataset.end))
          return this.$moment(obj.start).format('X') == this.$moment(dataset.start).format('X') &&  this.$moment(obj.end).format('X') == this.$moment(dataset.end).format('X')
                  && obj.location == dataset.location;
        });

        if (localDataset) {
          if (localDataset.value_from != dataset.value_from || localDataset.value_to != dataset.value_to) {

            // console.log(dataset, dataset.start, dataset.end, localDataset)
            dataset._id = localDataset._id;
            await this.updateDataset(dataset);
            //console.log(this.DatasetState.statusUpdate);
            if (this.DatasetState.statusUpdate != "success") {
              saveSuccess = false;
            } else {
              localDataset.value_from = dataset.value_from;
              localDataset.value_to = dataset.value_to;
            }
          }
        } else {
          await this.createDataset(dataset);
          if (this.DatasetState.statusCreate != "success") {
            saveSuccess = false;
          } else {
            is_new_element = true;
          }
        }     
      }
      if (is_new_element) {
        await this.loadDatasets();
      }
      return saveSuccess;
      // await this.getDatasets({from: this.$moment(this.selectedDate,this.dateFormat).startOf("day").format('X'), to: this.$moment(this.selectedDate,this.dateFormat).endOf("day").format('X'), profile: "individual", data_type: "shading"});
      // this.watchSlider = true;
    },

    calcTimeSlots(timeslot) {
      // const hours = parseInt(this.shadingBarY[timeslot].substring(0,2));
      // const min = parseInt(this.shadingBarY[timeslot].substring(3,5));
      const hours = this.utcShadingBarY[timeslot];
      const min = 0;

      let next = timeslot + 1;
      let addDay = 0;
      // console.log(this.shadingBarY[next], next, timeslot)
      if (!this.utcShadingBarY[next]) {
        next = 0;
        addDay = 24;
      }
      const hours_next = this.utcShadingBarY[next] + addDay;
      const min_next = 0;

      return {hours: hours, min: min, hours_next: hours_next, min_next: min_next};
    }


    // dragStart(sliderY) {
    //   console.log(sliderY, this.sliderValue[sliderY]);
    //   if (this.sliderValue[sliderY][0] == 100) {
    //     this.sliderValue[sliderY][0] = this.shadingBarX[this.shadingBarX.length - 2];
    //   }
    // }
  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      Datasets: ({ dataset }) => dataset.Datasets,
      DatasetState: ({ dataset }) => dataset,
      Global: ({ global }) => global.Global,
      radialLoading: (state) => state.radialLoading,
    }),
    
    formatedDate() {
      let _return;
      if (this.selectedDate == this.$moment().format(this.dateFormat)) {
        _return = "ToDay";
      } else {
        _return =  this.$moment(this.selectedDate, this.dateFormat).format("dd DD.MM.YY");
      }
      
      return _return;
    },
   
    calcDays() {
    //Mo-Do
      let day_start = 0;
      let day_end = 3;       
      if (this.selectedDaysIndex == 1) {  //Fr
        day_start = 4;
        day_end = 4;
      } else if (this.selectedDaysIndex == 2) { //Sa-So
        day_start = 5;
        day_end = 6;
      }
      return {start: day_start, end: day_end};
    },
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
          '--bgSize': (window.innerWidth / 116) + "em",
          'bgSize': (window.innerWidth / 116 * (this.shadingBarX.length - 1)) + "em"
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
          '--bgSize': (window.innerWidth / 116) + "em",
          'bgSize': (window.innerWidth / 116 * (this.shadingBarX.length - 1)) + "em"
        }
      }
    }
  },
  watch: {
    // sliderValue() {
    //   if (this.watchSlider) {   
    //     console.log("WATCHER ", this.watchSlider)
    //     this.watchSlider = false;
    //     this.saveGroupDays();
    //   }
    // },
    async selectedRadio(obj) {
      await this.loadDatasets();
    },
    async selectedDaysIndex() {
      await this.loadDatasets();
    },
    async radialIcon(obj) {
      //console.log("RadialIcon Changed ", obj)
      await this.loadDatasets();
    }
  },
};
</script>

<style lang='scss'>

.vue-slider-rail {
  background-color: rgba(0, 0, 0, 0) !important;
}
.vue-slider-process {
  background-color: rgba(100,100,100,0.3) !important;
  height: 50% !important;  
  top: 1em !important;
}

.custom-dot {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: 1;
  }

.bg-grid {
  background: rgba(0,0,0,0);
  background-image: linear-gradient(90deg, rgba(150, 150, 150, .3) .1em, transparent .1em);
  background-size: var(--bgSize) 2em;
}

.logo-overlay-loading {
  position: fixed;
  z-index: 9997;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.0;
  background: black;
}
</style>