const REQUEST_DEVICE_EVENTS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_DEVICE_EVENTS = (state, { body }) => {
  state.status = "success";
  state.events = body;
};

const FAILURE_REQUEST_DEVICE_EVENTS = state => {
  state.status = "failure";
};

export default {
  REQUEST_DEVICE_EVENTS,
  SUCCESS_REQUEST_DEVICE_EVENTS,
  FAILURE_REQUEST_DEVICE_EVENTS
};