<template>
  <v-container class=" pa-0 ma-0" :style="cssVars">
    <v-row class="justify-start">
      <v-col cols="auto"  v-if="renderComponent">
        <v-row no-gutters v-for="i of elements.length" :key="i" >
          <v-col v-if="!titlesOff" cols="auto" style="font-size: 9px; " class="font-weight-black graphbar grey--text pt-2 mr-1">
            {{titles[i-1]}}
          </v-col>
          <v-col cols="auto" class="graphbar ml-0"  v-for="x of 17" :key="x">
            <!-- <v-btn large :width="btnWidth" :height="btnHeight" class="mr-1" v-for="elem of elements" :key="elem.name" @click="emitClick(elem)"> -->
              <ShowSVG
                v-if="x<=elements[i-1][1] && x<=elements[i-1][0]"
                id="GraphBar"
                name="energybar-full-thin"
                :title="i+'_'+x"
                primary="energy_secondary"
                secondary="energy_primary"
                :size="size"
                text=""
                :glow="false"
              />
              <ShowSVG
                v-else-if="x<=elements[i-1][1]"
                id="GraphBar"
                name="energybar-full-thin"
                :title="i+'_'+x"
                primary="error"
                secondary="#FFFFFF"
                :size="size"
                text=""
                :glow="false"
              />
              <ShowSVG
                v-else-if="x<=elements[i-1][0]"
                id="GraphBar"
                name="energybar-full-thin"
                :title="i+'_'+x"
                primary="energy_primary"
                secondary="energy_secondary"
                :size="size"
                text=""
                :glow="false"
              />
              <!-- <ShowSVG
                v-else
                id="GraphBar"
                name="energybar-empty"
                :title="i+'_'+x"
                primary="background_primary"
                secondary="background_primary"
                :size="size"
                text=""
                :glow="false"
              /> -->
          
            <!-- </v-btn> -->
          </v-col>
          <v-col v-if="elements[i-1][2] > 0" cols="auto" style="font-size: 6px; writing-mode: vertical-rl;" align="center" class=" graphbar grey--text">
            {{((elements[i-1][2])).toFixed(0)}}Wh
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapState, mapActions } from "vuex";
//import TextToSVG from "text-to-svg";
import ShowSVG from "../components/ShowSVG";


export default {
  name: "GraphBar",
  props: { elements: null, size: null, titles: null, titlesOff: false },
  components: {
    ShowSVG
  },
  data() {
    return {
      renderComponent: true
    };
  },
  created: function () {
 
  },
  async mounted() {
   
  },
  methods: {
  
   
  },
  computed: {
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_primary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_primary,
          '--size': this.size + "px",
        }
      }
    }
  },
  watch: {
    elements() {
      //console.log("elements changed");
      //this.$forceUpdate();
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }
  },
};
</script>

<style lang='scss'>


.graphbar {
  background: var(--bg) !important;
  border-radius: 4px;
  height:  var(--size);
}
</style>