<template >
  <div>
    <div>
      {{prefix}}
      {{tweened}}
      {{denominator}}
      {{suffix}}
    </div>
    <div class="animationNumber" :style="cssVars">
      {{sprite}}
    </div>
    
  </div>
</template>

<script>

export default {
  name: "AnimatedNumber",
  props: { number: 0, denominator: "", suffix: "", prefix: ""},
  components: {},
  data() {
    return {
      tweened: 0,
      animationTime: 5,
      sprite: "",
      numOpacity: 0,
      numTop: 0,
      numRight: 0,
      numTopStd: 0,
      numRightStd: -2,
      numSize: 0,
      numSizeStd: 10,
      numBreak: false
    };
  },
  created: function () {
    this.tweened = this.number.toFixed(2);
    if (this.number % 1 == 0) {
      this.tweened = this.number.toFixed(0);
    }
  },
  mounted() {

   
  },
  methods: {
    delay_ms(t, val) {
      return new Promise(resolve => {
          setTimeout(function() {
              resolve(val);
          }, t);
      });
    },

    async startAnimationAdd(actual, last) {
         //let calc = this.denominator.length + this.suffix.length; //
      //let size_of_number = 20;
      
      // console.log(this.tweened.toFixed(0).toString().length);
      
      // let calc = this.tweened.toFixed(0).toString().length * size_of_number;
      // this.numRightStd = calc;

      let counting_gap = actual - last;
      // if (counting_gap > 0) counting_gap = Math.ceil(counting_gap);
      // else if (counting_gap < 0) counting_gap = Math.floor(counting_gap);
      let delay = 100;
      this.numOpacity = 0;
      this.numTop = this.numTopStd;
      this.numRight = this.numRightStd;
      this.numSize = this.numSizeStd;
      if (counting_gap > 0) {
        this.sprite = "+1";
      } else if (counting_gap < 0) {
        this.sprite = "-1";
        counting_gap = -counting_gap;
      }
      //console.log(this.sprite)
      if (counting_gap < 7) counting_gap = 7;

      for (let i=0; i < counting_gap; i++) {
        if (this.numBreak) break;
        this.numTop = this.numTop - 2;
        this.numRight = this.numRight - 1;
        this.numSize = this.numSize + 0.75;
        if (!(i % 10)) {
          this.numOpacity = 0;
          this.numTop = this.numTopStd;
          this.numRight = this.numRightStd;
          this.numSize = this.numSizeStd;
        }
        this.numOpacity = this.numOpacity + 0.1;
        await this.delay_ms(delay);
      }
       
      this.sprite = "";
    },

    async startAnimation(actual, last) {
      this.tweened = last;
      let counting_gap = actual - last;
      let small_amount = false;
      if (counting_gap > 0 && Math.floor(counting_gap) == 0) {
        small_amount = true;
      }
      //console.log("counting_gap ", counting_gap)
      let delay = 0;

      if (counting_gap > 0) {
        for (let i=0; i < counting_gap; i++) {
          if (counting_gap - i > 25) delay = this.animationTime;
          else if (counting_gap - i > 10) delay = this.animationTime * 10;
          else if (counting_gap < 1) delay = this.animationTime * 200;
          else delay = this.animationTime * 40;
          //console.log(delay);
          if (!small_amount) {
            //this.tweened++;  
            this.tweened = Math.round((this.tweened + 1) * 100) / 100;
          } else {
            this.tweened = actual;
          }    
          await this.delay_ms(delay);   
        }
      } else if (counting_gap < 0) {
        for (let i = counting_gap; i <= 0; i++) {
          if (i < -25) delay = this.animationTime;
          else if (i < -10) delay = this.animationTime * 10;
          else if (counting_gap < 1) delay = this.animationTime * 200;
          else delay = this.animationTime * 40;
          if (!small_amount) {
            //this.tweened--;    
            this.tweened = Math.round((this.tweened - 1) * 100) / 100;  
          } else {
            this.tweened = actual;
          }   
          await this.delay_ms(delay);
        }
      }
      this.numBreak = true;
    }
 
  },
  computed: {
    cssVars() {
      return {
        '--numOpacity': this.numOpacity,
        '--numTop': this.numTop + 'px',
        '--numRight': this.numRight + 'px',
        '--numSize': this.numSize + 'px'
      }
    }
  },
  watch: {
    number(actual, last) {
      try {
        //alert(String(actual)+ " " + String(last));
        this.numBreak = false;
        actual = Math.round(actual * 100) / 100;
        last = Math.round(last * 100) / 100;
       
        //console.log("tweened: ", this.tweened)
        //console.log("AN: ", actual, last);
        this.startAnimation(actual, last);
        this.startAnimationAdd(actual, last);
      } catch(err) {
        //
      }
    }
  },
};
</script>

<style lang='scss'>
.animationNumber
{
  position:absolute;
  top: var(--numTop);
  right: var(--numRight);
  //left: var(--numRight);
  opacity: var(--numOpacity);
  font-size: var(--numSize);
  z-index:1;
  // animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  // transform: translate3d(0, 0, 0);
}
// @keyframes animationNumber {
//   10%,
//   90% {
//     transform: translate3d(-1px, 0, 0);
//   }

//   20%,
//   80% {
//     transform: translate3d(2px, 0, 0);
//   }

//   30%,
//   50%,
//   70% {
//     transform: translate3d(-4px, 0, 0);
//   }

//   40%,
//   60% {
//     transform: translate3d(4px, 0, 0);
//   }
// }
</style>