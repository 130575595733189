const REQUEST_USERDEVICES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_USERDEVICES = (state, { body }) => {
  state.UserDevices = body;
  state.status = "success";
};

const FAILURE_REQUEST_USERDEVICES = state => {
  state.status = "failure";
};

const REQUEST_DELETE_USERDEVICE = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_USERDEVICE = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_USERDEVICE = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_USERDEVICE = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_USERDEVICE = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_USERDEVICE = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_USERDEVICE = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_USERDEVICE = (state, { body }) => {
  state.CreatedUserDevice = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_USERDEVICE = state => {
  
  state.statusCreate = "failure";
};


export default {
  REQUEST_USERDEVICES,
  SUCCESS_REQUEST_USERDEVICES,
  FAILURE_REQUEST_USERDEVICES,
  REQUEST_CREATE_USERDEVICE,
  SUCCESS_REQUEST_CREATE_USERDEVICE,
  FAILURE_REQUEST_CREATE_USERDEVICE,
  REQUEST_DELETE_USERDEVICE,
  SUCCESS_REQUEST_DELETE_USERDEVICE,
  FAILURE_REQUEST_DELETE_USERDEVICE,
  REQUEST_UPDATE_USERDEVICE,
  SUCCESS_REQUEST_UPDATE_USERDEVICE,
  FAILURE_REQUEST_UPDATE_USERDEVICE,

};
