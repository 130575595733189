import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  statusOnly: void 0,
  statusUpdateDeviceLocation: void 0,
  statusDevicesAll: void 0,
  powerDevices: [],
  powerDevicesOnly: [],
  powerDevice: {},
  devicesAll: [],
  debugValues: [],
  response: "",
  iframe: null
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
