<template >
  <div>
    <div :id="internalId" />
    <div :id="internalId2" class="oben" />
  </div>
</template>

<script>
import TextToSVG from "text-to-svg";
import { generateIconSvg } from "../utils/generateIconSvg";

export default {
  name: "ShowSVG",
  props: { id: "", name: "", primary: "", size: 0, text: "", glow: false, title: "", secondary: ""},
  components: {},
  data() {
    return {
      mSVG: "",
      xmlDoc: "",
      defTagNames: []
    };
  },
  created: function () {},
  mounted() {
    
    if (this.name) {
      this.generateSVG();
    }

    // <svg height="30" width="200">
    //   <text x="0" y="15" fill="red">I love SVG!</text>
    // </svg>
  },
  methods: {
    toSVG(text) {
      return new Promise((resolve, reject) => {
        try {
          TextToSVG.load("./SourceSansPro-Regular.otf", (error, textToSVG) => {
            const svg = textToSVG.getSVG(text);
            resolve(svg);
          });
        } catch (err) {
          reject(err);
        }
      });
    },
   
    async generateSVG() {

      //console.log("Size: ", this.name,  this.size);

      let setColor = "";
      let setSecondaryColor = "";
      if (this.$vuetify.theme.isDark) {
        setColor = this.$vuetify.theme.themes.dark[this.primary];
        setSecondaryColor = this.$vuetify.theme.themes.dark[this.secondary]
      } else {
        setColor = this.$vuetify.theme.themes.light[this.primary];
        setSecondaryColor = this.$vuetify.theme.themes.light[this.secondary];
      }

      //console.log("ShowSVG 0: ", typeof setColor, setColor)
      if (typeof setColor == "undefined") {
        setColor = this.primary;
      }
      if (typeof setSecondaryColor == "undefined") {
        setSecondaryColor = this.secondary;
      }

      let _file = this.name + ".svg";

      // if (this.name == "icon_ac_cut") {
      //   console.log("ShowSVG: ", this.name, setColor, this.internalId, this.size, setSecondaryColor, this.primary, this.secondary);
      // }
      const xmlStr = await generateIconSvg(this.name, setColor, this.internalId, this.size, setSecondaryColor, this.text);
      //console.log("ShowSVG3: ", xmlStr)

      let _div = document.getElementById(this.internalId);
      
      if (_div) {
        _div.innerHTML = xmlStr; //funkt
      }
       // add Glow   
      let parser = new DOMParser();
      let serializer = new XMLSerializer();
      if (this.glow == "true") {
        
        _file = "glow-bubble-mainicons.svg";

        //this.mSVG = await import("!!raw-loader!../assets/iconpic_heizen.svg");  //funkt
        let glowFile = await import(`!!raw-loader!../assets/${_file}`);
        
        let xmlDoc2 = parser.parseFromString(glowFile.default, "image/svg+xml");
        //Set Size
        // xmlDoc2.documentElement.setAttribute("width", this.size*1.2);
        // xmlDoc2.documentElement.setAttribute("height", this.size*1.2);
      
        xmlDoc2.documentElement.setAttribute("width", this.size);
        xmlDoc2.documentElement.setAttribute("height", this.size);
       
        const xmlStr2 = serializer.serializeToString(xmlDoc2.documentElement);

        let _div2 = document.getElementById(this.internalId2);
        if (_div2) {
          _div2.innerHTML = xmlStr2; //funkt
        }
      }
    }

  },
  computed: {
    internalId() {
      return this.title + this.name + "_" + this.id;
    },
    internalId2() {
      return this.title + this.name + "_" + this.id + "_2";
    },
  },
  watch: {},
};
</script>

<style lang='scss'>

.oben
{
  position:absolute;top:-3px;left:-3px;
  opacity: 0.3;
  z-index:1;
}
</style>