<template>
  <div  v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers_icon"
            :items="Icons"
            sort-by="name"
            class="elevation-1"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Icons</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemIcon"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_icon" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleIcon }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.icon"
                          label="Icon Name"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.textcolor"
                          label="Text Color"
                        ></v-text-field>
                         <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.primary"
                          label="Primary Color"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.secondary"
                          label="Secondary Color"
                        ></v-text-field>
                        <v-select
                          :items="filteredScreens"
                          item-text="name"
                          item-value="_id"
                          label="Screen Route"
                          v-model="editedItemIcon.screen"
                        >
                        <!-- v-on:click="changeFilter(item)" -->
                        </v-select>
                        <v-select
                          :items="Global.locationTypes"
                          item-text="value"
                          item-value="_id"
                          label="Type"
                          v-model="editedItemIcon.type"       
                        >
                        <!-- v-on:click="changeFilter(item)" -->
                        </v-select>
                        <!-- <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.screen"
                          label="Screen"
                        ></v-text-field> -->
                        <!-- <v-text-field
                          dense
                          flat
                          type="Number"
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.sortorder"
                          label="Sort Order"
                        ></v-text-field>

                        <v-select
                          :items="filteredIcons"
                          item-text="name"
                          item-value="_id"
                          label="Parent"
                          v-model="editedItemIcon.parent"
                        > -->
                          <!-- v-on:change="changeFilter" -->
                        <!-- </v-select> -->
                        <!-- <v-checkbox
                          dense
                          v-model="editedItemIcon.active"
                          label="Active"
                        ></v-checkbox> -->
                       
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeIcon"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveIcon"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <!-- <template v-slot:item.parent="{ item }">
              {{ compIconName(item) }}
            </template>
            <template v-slot:item.costcenter="{ item }">
              {{ compCostcenterName(item) }}
            </template>
            <template v-slot:item.user="{ item }">
              {{ compUserName(item) }}
            </template>
            <template v-slot:item.location="{ item }">
              {{ compLocation(item) }}
            </template> -->
             <template v-slot:item.screen="{ item }">
              {{ compScreen(item) }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ compType(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemIcon(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemIcon(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col >
          <v-data-table
            :headers="headers_screen"
            :items="Screens"
            sort-by="name"
            class="elevation-1"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Screens</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemScreen"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_screen" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleScreen }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemScreen.name"
                          label="Name"
                        ></v-text-field>
                       
                        <!-- <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.icon"
                          label="Icon Name"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.textcolor"
                          label="Text Color"
                        ></v-text-field>
                         <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.primary"
                          label="Primary Color"
                        ></v-text-field>
                        <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.secondary"
                          label="Secondary Color"
                        ></v-text-field> -->
                        <!-- <v-text-field
                          dense
                          flat
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.screen"
                          label="Screen"
                        ></v-text-field> -->
                        <!-- <v-text-field
                          dense
                          flat
                          type="Number"
                          :rules="requiredRule"
                          required
                          v-model="editedItemIcon.sortorder"
                          label="Sort Order"
                        ></v-text-field>

                        <v-select
                          :items="filteredIcons"
                          item-text="name"
                          item-value="_id"
                          label="Parent"
                          v-model="editedItemIcon.parent"
                        > -->
                          <!-- v-on:change="changeFilter" -->
                        <!-- </v-select> -->
                        <!-- <v-checkbox
                          dense
                          v-model="editedItemIcon.active"
                          label="Active"
                        ></v-checkbox> -->
                       
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeScreen"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveScreen"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.icons="{ item }" >
              <div v-on:click="editedItemScreen = item; editedIndexScreen = Screens.indexOf(item);">
                <v-data-table
                  :headers="headers_icons"
                  :items="item.icons"
                  sort-by="icon"
                  dense
                  must-sort
                  disable-pagination  
                  hide-default-footer
                  
                >
                  <template v-slot:item.icon="{ item }">
                    {{compIconName(item)}}
                  </template>
                  <template v-slot:item.parent="{ item }">
                  <v-select
                    :items="filteredIcons"
                    item-text="name"
                    item-value="_id"
                    label="Parent"
                    v-model="item.parent"
                    
                    v-on:change="saveScreen()"
                  >
                  <!-- v-on:click="changeFilter(item)" -->
                  </v-select>
                  </template>
                  <template v-slot:item.sortorder="{ item }">
                    <v-text-field
                      dense
                      flat
                      type="Number"
                      :rules="requiredRule"
                      required
                      v-model="item.sortorder"
                      label="Sort Order"
                      v-on:change="saveScreen()"
                    ></v-text-field>
                  </template>
                  <!-- <template v-slot:item.location="{ item }">
                     <v-select
                      :items="Locations"
                      item-text="name"
                      item-value="_id"
                      label="Location"
                      v-model="item.location"       
                      v-on:change="saveScreen()"
                    >
                  
                    </v-select>
                  </template> -->
                  <template v-slot:item.actions="{ item }">
                    <!-- <v-icon small class="mr-2" @click="editScreenIcon(item)"
                      >mdi-pencil</v-icon
                    > -->
                    <v-icon small @click="removeScreenIcon(item)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </div>
            </template>
            <!-- <template v-slot:item.costcenter="{ item }">
              {{ compCostcenterName(item) }}
            </template>
            <template v-slot:item.user="{ item }">
              {{ compUserName(item) }}
            </template>
            <template v-slot:item.location="{ item }">
              {{ compLocation(item) }}
            </template> -->

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="changeScreenIcons(item)"
                >mdi-plus</v-icon
              >
              <v-icon small class="mr-2" @click="editItemScreen(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemScreen(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog_addIcon" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">Icon</span>
        </v-card-title>

        <v-card-text>
          <v-form
            v-model="valid"
            style="width: 100%; height: 100%"
            class="compact-form"
          >

            <v-data-table
              class="pa-2 pt-5"
              :headers="headers_addIcons"
              :items="Icons"
              sort-by="name"
              dense
              must-sort
            >
             
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="addScreenIcon(item)"
                  >mdi-plus</v-icon
                >
             
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddIcon">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveAddIcon">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule } from "../utils/rules";

export default {
  name: "icon",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,

      dialog_icon: false,
      dialog_screen: false,
      dialog_addIcon: false,

      headers_icon: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "ID", value: "_id" },
        { text: "Icon Name", value: "icon" },
        { text: "Textcolor", value: "textcolor" },
        { text: "Primary", value: "primary" },
        { text: "Secondary", value: "secondary" },
        { text: "Screen Route", value: "screen" },
        { text: "Icon Type", value: "type" },
       
        { text: "Actions", value: "actions", sortable: false },
      ],

      headers_icons: [
        {
          text: "Icon",
          align: "start",
          sortable: true,
          value: "icon",
        },
        { text: "Parent", value: "parent" },
        { text: "Sort Order", value: "sortorder" },
        // { text: "Location", value: "location" },
       
       
        { text: "Actions", value: "actions", sortable: false },
      ],
      
      editedIndexIcon: -1,
      defaultItemIcon: {
        name: "",
        icon: null,
        textcolor: "",
        primary: "",
        secondary: "",
        screen: null
      },
      editedItemIcon: {},

      headers_screen: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Icons", value: "icons" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndexScreen: -1,
      defaultItemScreen: {
        name: "",
        icons: [],
        
      },
      editedItemScreen: {},

      headers_addIcons: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
       
        { text: "Actions", value: "actions", sortable: false },
      ],

      snackbar: false,
      snackbar_text: "",

      noParent: "No Parent",
      myLocations: []
    };
  },
  created() {
    this.editedItemIcon = JSON.parse(JSON.stringify(this.defaultItemIcon));
    this.editedItemScreen = JSON.parse(JSON.stringify(this.defaultItemScreen));
  },

  async mounted() {

    await this.getIcons({});
    await this.getScreens({});
    await this.getLocation();
    await this.getGlobal();
    //await this.getUsers();
    //await this.getSmApi({});
    await this.generateScreens();
    
  },
  computed: {
    ...mapState({
      status: ({ icon }) => icon.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Icons: ({ icon }) => icon.Icons,
      IconState: ({ icon }) => icon,
      Screens: ({ screen }) => screen.Screens,
      ScreenState: ({ screen }) => screen,
      Global: ({ global }) => global.Global,
      Locations: ({ location }) => location.Locations,
      //Users: ({ user }) => user.Users,
      //Devices: ({ device }) => device.Devices,
    }),

    formTitleIcon() {
      return this.editedIndexIcon === -1 ? "New Icon" : "Edit Icon";
    },
    formTitleScreen() {
      return this.editedIndexIcon === -1 ? "New Screen" : "Edit Screen";
    },
    filteredIcons() {
      let obj = JSON.parse(JSON.stringify(this.Icons));
      obj.push({name: this.noParent});
      return obj;  
    },
    filteredScreens() {
      let obj = JSON.parse(JSON.stringify(this.Screens));
      obj.push({name: this.noParent});
      return obj;
    }
  },
  methods: {
    ...mapActions("icon", [
      "getIcons",
      "createIcon",
      "updateIcon",
      "deleteIcon",
    ]),
     ...mapActions("screen", [
      "getScreens",
      "createScreen",
      "updateScreen",
      "deleteScreen",
    ]),
    //...mapActions("sm_api", ["getSmApi"]),
    // ...mapActions("costcenter", ["getCostcenters"]),
    //...mapActions("user", ["getUsers"]),
    ...mapActions("location", ["getLocation"]),
    ...mapActions(
      "global", ["getGlobal"]
    ),

    async generateScreens() {
      let roomType = _.find(this.Global.locationTypes, {value: "room"});
      if (roomType) {
        roomType = roomType._id;
      }
      let floorType = _.find(this.Global.locationTypes, {value: "floor"});
      if (floorType) {
        floorType = floorType._id;
      }
      // console.log("roomType ",roomType);
      let buildingType = _.find(this.Global.locationTypes, {value: "building"});
      if (buildingType) {
        buildingType = buildingType._id;
      }
      let electricityDeviceType = _.find(this.Global.locationTypes, {value: "electricity device"});
      if (electricityDeviceType) {
        electricityDeviceType = electricityDeviceType._id;
      }
      for (let screen of this.Screens) {

        if (screen.name == "heatingScreen" || screen.name == "shadingScreen") {
          screen.icons = [];
          const rooms = _.filter(this.Locations, (obj) => {
            return obj.type == roomType || obj.type == floorType;
          });
          
          for (let room of rooms) {
            if (room.active) {
              let parent = _.find(this.Locations, {_id: room.parent});
              if (parent.type == buildingType) { parent.icon = null; }
              screen.icons.push({icon: room.icon, parent: parent.icon, sortorder: room.sortorder});
            }
          }
        }
      }
    },
    // changeFilter(item) {
    //   //console.log("FILTER: ", item)
    //   this.filteredIcons = _.filter(this.Icons, (obj) => {
    //     return obj._id != item.icon;
    //   });
    //   this.filteredIcons.push({name: this.noParent});
    // },
    compIconName(item) {
      try {
        return _.find(this.Icons, { _id: item.icon }).name;
      } catch (err) {
        return "";
      }
    },
    // compCostcenterName(item) {
    //   if (item.costcenter == null) {
    //     return "";
    //   }
    //   try {
    //     return _.find(this.Costcenters, { _id: item.costcenter }).name;
    //   } catch (err) {
    //     return "";
    //   }
    // },

    compIcons(item) {
      
      try {
        let res = [];
        for (item of item.icons) {
          item.iconname = _.find(this.Icons, { _id: item.icon }).name;
          if (item.parent) {
            item.parentname = _.find(this.Icons, { _id: item.parent }).name;
          }
          res.push(item);
        }
        
        // return JSON.stringify(item.icons);
        return res;
      } catch (err) {
        return "";
      }
    },

    compScreen(item) {
      if (item.screen == null) {
        return "";
      }
      try {
        return _.find(this.Screens, { _id: item.screen }).name;
      } catch (err) {
        return "";
      }
    },

    compType(item) {
      if (item.type == null) {
        return "";
      }
      try {
        // return(item.type)
        return _.find(this.Global.locationTypes, { _id: item.type }).value;
      } catch (err) {
        return "";
      }
    },

    // compUserName(item) {
    //   //console.log(item)
    //   if (item.user) {
    //     let arr = [];
    //     for (let user of item.user) {
    //       let u = undefined;
    //       if (this.mySelf.roles.includes("admin")) {
    //         u = _.find(this.Users, { _id: user });
    //       } else {
    //         if (this.mySelf._id == user) {
    //           u = this.mySelf;
    //         }
    //         //console.log(u, user, this.mySelf)
    //       }
    //       if (u) {
    //         arr.push(u.fullname);
    //       }
    //     }
    //     return arr;
    //   } else {
    //     return "";
    //   }
    // },
    // compLocation(item) {
    //   if (item.location == null) {
    //     return "";
    //   }
    //   try {
    //     return _.find(this.Locations, { _id: item.location }).name;
    //   } catch (err) {
    //     return "";
    //   }
    // },
    // loadIcons(a) {
    //   //console.log(a);
    //   this.companyIcons = _.filter(this.Icons, { location: a._id });
    // },

    async createItemIcon() {
      this.editedItemIcon = Object.assign({}, this.defaultItemIcon);
      this.editedIndexIcon = -1;
      this.editedItemIcon.textcolor = "inactive_primary";
      this.editedItemIcon.primary = "inactive_primary";
      this.editedItemIcon.secondary = "inactive_secondary";
      //this.changeFilter(null);
      this.dialog_icon = true;
    },

    async editItemIcon(item) {
      //console.log("editItem ",item);

      this.editedIndexIcon = this.Icons.indexOf(item);
      this.editedItemIcon = Object.assign({}, item);
      //this.changeFilter(item._id);
      this.dialog_icon = true;
    },

    async deleteItemIcon(item) {
      //const index = this.Icons.indexOf(item);
      //console.log("Delete Icon ",item)
      await this.deleteIcon(item._id);
      if (this.IconState.statusDelete == "success") {
        //this.Icons.splice(index, 1);
        await this.getIcons();
      } else {
        // this.snackbar_text = "Already deleted";
        // this.snackbar = true;
      }
    },

    closeIcon() {
      this.dialog_icon = false;
      this.$nextTick(() => {
        this.editedItemIcon = Object.assign({}, this.defaultItemIcon);
        this.editedIndexIcon = -1;
      });
    },

    async saveIcon() {
      //console.log("saveIcon");

      //console.log(this.editedItemIcon)
      //this.editedItemIcon.location = this.mySelf.location;
   
      if (this.editedItemIcon.screen == this.noParent) {
        this.editedItemIcon.screen = null;
      }
     

      if (this.editedIndexIcon > -1) {
        await this.updateIcon(this.editedItemIcon);
        if (this.IconState.statusUpdate == "success") {
          Object.assign(
            this.Icons[this.editedIndexIcon],
            this.editedItemIcon
          );
        } else {
          //this.snackbar_text = this.IconState.Icon.message;
          this.snackbar_text = "Update failed";
          this.snackbar = true;
        }
      } else {
        await this.createIcon(this.editedItemIcon);
        if (this.IconState.statusCreate == "success") {
          this.Icons.push(this.editedItemIcon);
        } else {
          //this.snackbar_text = this.snackbar_text = this.IconState.Icon.message;
          this.snackbar_text = "Create failed";
          this.snackbar = true;
        }
      }
      await this.getIcons();
      this.closeIcon();
    },

    async createItemScreen() {
      this.editedItemScreen = Object.assign({}, this.defaultItemScreen);
      this.editedIndexScreen = -1;
      //this.changeFilter(null);
      this.dialog_screen = true;
    },

    async editItemScreen(item) {
      //console.log("editItem ",item);

      this.editedIndexScreen = this.Screens.indexOf(item);
      this.editedItemScreen = Object.assign({}, item);
      //this.changeFilter(item._id);
      this.dialog_screen = true;
    },

    async deleteItemScreen(item) {
      //const index = this.Screens.indexOf(item);
      await this.deleteScreen(item._id);
      if (this.ScreenState.statusDelete == "success") {
        //this.Screens.splice(index, 1);
        await this.getScreens();
      } else {
        // this.snackbar_text = "Already deleted";
        // this.snackbar = true;
      }
    },


    closeScreen() {
      if (this.dialog_screen) {
        this.dialog_screen = false;
        this.$nextTick(() => {
          this.editedItemScreen = Object.assign({}, this.defaultItemScreen);
          this.editedIndexScreen = -1;
        });
      }
    },

    async saveScreen() {
      //console.log("saveScreen");

      //console.log(this.editedItemScreen)
      //this.editedItemScreen.location = this.mySelf.location;
      for (let el of this.editedItemScreen.icons) {
        if (el.parent == this.noParent) {
          el.parent = null;
        }
      }
      if (this.editedIndexScreen > -1) {
        //console.log("UPDATE")
        await this.updateScreen(this.editedItemScreen);
        if (this.ScreenState.statusUpdate == "success") {
          Object.assign(
            this.Screens[this.editedIndexScreen],
            this.editedItemScreen
          );
        } else {
          //this.snackbar_text = this.ScreenState.Screen.message;
          this.snackbar_text = "Update failed";
          this.snackbar = true;
        }
      } else {
        //console.log("CREATE")
        await this.createScreen(this.editedItemScreen);
        if (this.ScreenState.statusCreate == "success") {
          this.Screens.push(this.editedItemScreen);
        } else {
          //this.snackbar_text = this.snackbar_text = this.ScreenState.Screen.message;
          this.snackbar_text = "Create failed";
          this.snackbar = true;
        }
      }
      await this.getScreens();
      this.closeScreen();
    },

    async changeScreenIcons(item) {
      //console.log(item)
      this.editedIndexScreen = this.Screens.indexOf(item);
      this.editedItemScreen = item;
      this.dialog_addIcon = true;
    },
    closeAddIcon() {
      try {
        this.dialog_addIcon = false;
      } catch (err) {
        this.snackbar_text = err;
        this.snackbar = true;
      }
    },
    async saveAddIcon(item) {
      try {
        await this.saveScreen();
        this.dialog_addIcon = false;
      } catch (err) {
        this.snackbar_text = err;
        this.snackbar = true;
      }
    },
    addScreenIcon(item) {
      if (
        !_.find(this.editedItemScreen.icons, (obj) => {
          return obj == item;
        })
      ) {
        this.editedItemScreen.icons.push({icon: item._id, parent: item.parent, sortorder: item.sortorder});
      }
    },
    // editScreenIcon(item) {
    //   if (
    //     !_.find(this.editedItemScreen.icons, (obj) => {
    //       return obj._id == item._id;
    //     })
    //   ) {
    //     this.editedItemScreen.icons.push({icon: item._id, parent: item.parent, sortorder: item.sortorder});
    //   }
    // },
    removeScreenIcon(item) {
   
      const check = _.find(this.editedItemScreen.icons, (obj) => {
        return obj._id == item._id;
      });
      //console.log(item, this.editedItemScreen.icons, check);
      if (check   
      ) {
        //console.log("UPDATE SCREEN")
        const index = this.editedItemScreen.icons.indexOf(item);
        this.editedItemScreen.icons.splice(index, 1);    
        this.saveScreen();
      }

    },
     
    
  },
  watch: {

  },
};
</script>

<style scoped>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
</style>