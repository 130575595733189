<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width:500px">
        <v-card flat color="transparent">
          <v-card-text>
            <v-card elevation="1">
              <v-card-title class="body-1">Login</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'">E-Mail or password incorrect.</v-alert>
              </v-card-text>
              <v-card-text>
                <v-form v-model="valid" style="width:100%;height:100%;" class="compact-form">
                  <v-text-field
                  
                    dense
                    flat
                    outlined
                   
                    required
                    id="email"
                    v-model="email"
                    label="E-Mail"
                  ></v-text-field>
                  <v-text-field
                  dense
                  flat
                  outlined
                    type="password"
                    @keydown.enter="authenticate({email, password})"
                    id="password"
                    v-model="password"
                    label="Password"
                  ></v-text-field>
                  <v-checkbox
                    v-model="autoLogin"
                    label="Remember Password">
                    
                  </v-checkbox>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
               <!--<v-btn
                  rounded
                  outlined
                  color="primary"
                  @click="$router.push('home')"
                >Zurück</v-btn>-->
                <v-spacer></v-spacer>
                <v-btn
                  
                  :disabled="!valid"
                  outlined
                  color="primary"
                  :loading="loading"
                  @click="authenticate({email, password})"
                >Login</v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <p align="center">
                <br />
                <router-link :to="{name: 'resetPassword'}">{{$t("Forgot password?")}}</router-link>
                <br />
                <br />
                <router-link
                  :to="{name: 'signUp'}"
                >{{$t("You don't have an account yet and you want to sign up?")}}</router-link>
                <br />
                <br />
              </p>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { emailRule } from "../utils/rules";

export default {
  name: "Login",
  data() {
    return {
      password: "",
      email: "",
      valid: false,
      emailValid: false,
      emailRule,
      autoLogin: false,
      authData: {}
    };
  },
  mounted() {
    try {
      this.authData = JSON.parse(localStorage.getItem("authData"));
        this.autoLogin = this.authData.autoLogin;
        this.email = this.authData.email;
        this.password = this.authData.password;
        if (this.autoLogin) {
          this.authenticate({email: this.authData.email,password: this.authData.password});
        }
    }
    catch (err) {
      // console.log(err)
    }
    
  },
  methods: {
    ...mapActions("auth", ["authenticate", "getMySelf"]),
    ...mapActions("user", ["getObsUserData"]),
  },
  computed: {
    ...mapState({
      status: ({ auth }) => auth.status,
      loading: state => state.loading,
      tokenExpiresAt: ({ auth }) => auth.tokenExpiresAt
    })
  },
  watch: {
    async status(val) {
      //console.log(val)
      if (val === "success") {
        console.log("Success Login");
        //this.$store.state.selectedLocation = undefined;
        this.$store.commit("SET_LOCATION", undefined);
        if (this.autoLogin) {
          this.authData = {};
          this.authData.email = this.email;
          this.authData.password = this.password;
          this.authData.autoLogin = true;
          localStorage.setItem("authData", JSON.stringify(this.authData));
        }
        this.$store.authInterval = setInterval(async () => {
          //console.log("Auth Check Interval ", Number(this.$moment().format('X')), this.tokenExpiresAt );
          if (Number(this.$moment().format('X')) >= this.tokenExpiresAt) {
            this.getMySelf();
          }
        }, 2 * 1000); //tokenDurationInSeconds * 1000);
        
        this.$router.push({ name: "home" });
      }
    }
  }
};
</script>

<style scoped>

.compact-form {
  transform: scale(0.9);
  transform-origin: center;
}

</style>