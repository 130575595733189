const REQUEST_DATASETS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_DATASETS = (state, { body }) => {
  state.Datasets = body;
  state.status = "success";
};

const FAILURE_REQUEST_DATASETS = state => {
  state.status = "failure";
};



// const REQUEST_DELETE_DATASET = state => {
//   state.statusDelete = "fetching";
// };

// const SUCCESS_REQUEST_DELETE_DATASET = state => {
//   state.statusDelete = "success";
// };

// const FAILURE_REQUEST_DELETE_DATASET = state => {
//   state.statusDelete = "failure";
// };

const REQUEST_UPDATE_DATASET = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_DATASET = (state, { body }) => {
  state.UpdatedDataset = body;
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_DATASET = state => {
  state.statusUpdate = "failure";
};

const REQUEST_CREATE_DATASET = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_DATASET = (state, { body }) => {
  state.CreatedDataset = body;
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_DATASET = state => { 
  state.statusCreate = "failure";
};

const REQUEST_DATASETGETSCORE = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_DATASETGETSCORE = (state, { body }) => {
  state.DatasetGetScore = body;
  state.status = "success";
};

const FAILURE_REQUEST_DATASETGETSCORE = state => {
  state.status = "failure";
};

const REQUEST_DATASETGETALLUSERBENCHMARKS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_DATASETGETALLUSERBENCHMARKS = (state, { body }) => {
  state.AllUserBenchmarks = body;
  state.status = "success";
};

const FAILURE_REQUEST_DATASETGETALLUSERBENCHMARKS = state => {
  state.status = "failure";
};

export default {
  REQUEST_DATASETS,
  SUCCESS_REQUEST_DATASETS,
  FAILURE_REQUEST_DATASETS,
  REQUEST_CREATE_DATASET,
  SUCCESS_REQUEST_CREATE_DATASET,
  FAILURE_REQUEST_CREATE_DATASET,
  // REQUEST_DELETE_DATASET,
  // SUCCESS_REQUEST_DELETE_DATASET,
  // FAILURE_REQUEST_DELETE_DATASET,
  REQUEST_UPDATE_DATASET,
  SUCCESS_REQUEST_UPDATE_DATASET,
  FAILURE_REQUEST_UPDATE_DATASET,
  REQUEST_DATASETGETSCORE,
  SUCCESS_REQUEST_DATASETGETSCORE,
  FAILURE_REQUEST_DATASETGETSCORE,
  REQUEST_DATASETGETALLUSERBENCHMARKS,
  SUCCESS_REQUEST_DATASETGETALLUSERBENCHMARKS,
  FAILURE_REQUEST_DATASETGETALLUSERBENCHMARKS
};
