import api from "../../../api";

export default {
  async getGlobal({ commit }) {
    commit("REQUEST_GLOBAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_GLOBAL",
        await api.getGlobal({})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_GLOBAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getGlobalAll({ commit }) {
    commit("REQUEST_GLOBAL_ALL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_GLOBAL_ALL",
        await api.getGlobalAll({})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_GLOBAL_ALL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateGlobal({ commit }, item ) {
    commit("REQUEST_UPDATE_GLOBAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_GLOBAL",
        await api.updateGlobal(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_GLOBAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createGlobal({ commit }, item ) {
    commit("REQUEST_CREATE_GLOBAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_GLOBAL",
        await api.createGlobal( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_GLOBAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
