<template>
  <v-container class="pa-0 ma-0" :style="cssVars" >
    <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3">  
        Environment Overview
      </v-col>
    </v-row>  
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";


export default {
  name: "EnvironmentDataScreen",
  props: { },
  components: {
   
  },
  data() {
    return {
      
    };
  },
  created: function () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  },
  async mounted() {

  },
  methods: {
    
  },
  computed: {
    ...mapState({
      
    }),

   
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    },

    themeMode() {
      if (this.$vuetify.theme.isDark) {
        return "dark";
      } else {
        return "light"
      }
    }
  },
  watch: {
    themeMode(theme) {
      // console.log("Theme changed ", theme);
      
    }
  },
};
</script>

<style lang='scss'>

.status-bar-eds {
  background: #1af;//var(--bg) !important;
  border-radius: 4px;
}

</style>