<template>
  <div>
    <survey :survey="survey"></survey>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
// import "survey-vue/defaultV2.css";
// SurveyVue.StylesManager.applyTheme("defaultV2");
import "survey-vue/modern.css";
SurveyVue.StylesManager.applyTheme("modern");

var Survey = SurveyVue.Survey;

export default {
  components: {
    Survey
  },
  data() {
    var json = {
     elements: [
      { type: "text", name: "customerName", title: "What is your name?", isRequired: true}
     ],
     questions: [
        {
        type: "rating",
        name: "satisfaction",
        title: "How satisfied are you with the Product?",
        minRateDescription: "Not Satisfied",
        maxRateDescription: "Completely satisfied"
        }
    ]
    };
    var model = new SurveyVue.Model(json);
    return {
      survey: model
    };
  }
};
</script>