import api from "../../../api";

export default {
  async getUserDevices({ commit }, filter) {
    commit("REQUEST_USERDEVICES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_USERDEVICES",
        await api.getUserDevices(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_USERDEVICES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteUserDevice({ commit }, id) {
    commit("REQUEST_DELETE_USERDEVICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_USERDEVICE",
        await api.deleteUserDevice(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_USERDEVICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateUserDevice({ commit }, item ) {
    commit("REQUEST_UPDATE_USERDEVICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_USERDEVICE",
        await api.updateUserDevice(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_USERDEVICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createUserDevice({ commit }, item ) {
    commit("REQUEST_CREATE_USERDEVICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_USERDEVICE",
        await api.createUserDevice( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_USERDEVICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
