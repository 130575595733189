<template >
  <v-container class="radial-menu" :style="cssVars">
    <v-row>
      <v-col class="info-field pa-0 ml-3 mr-2 d-flex " v-if="showMsgBox">
        <!-- <ShowSVG
          id="infoSVG_"
          name="leaves-grey-solo-9"
          title="radial-menu_"
          primary="leaves_primary"
          secondary="leaves_secondary"
          size="65"
          glow="false"
          text=""
        /> -->
        <!-- <v-img
          max-height="65"
          max-width="120"
          src="../assets/leaf-anim-idle.png"
        >
        </v-img> -->
        <!-- <img src="../assets/leaf-anim-sleepy-opt.png" :height="innerHeight"> -->
        <img class="leafbox" src="../assets/leaf-anim-attention-256-12fps-alpha-animated.png" :height="innerHeight">
        <div class="ml-1 mt-3 mr-5" style="max-height: 10px !important">
          <vue-typed-js :strings="message" :showCursor="false" :typeSpeed="25" v-if="show_info">
            <div class="typing caption"></div>
          </vue-typed-js>
        </div>
      </v-col>
      <v-col class="pa-0 pl-0 mr-5 align-self-center" cols="auto" ref="radRef">
        <div v-if="menuConfig.btns.length > 1">
          <v-btn style="opacity: 0.75;" fab x-large :disabled="m1.visible" @click="showMenu(m1)">
            <v-progress-circular
              v-if="radialLoading"
              class="progress-radial-menu"
              :size="70"
              color="leaves_primary"
              indeterminate
            ></v-progress-circular>
            <ShowSVG
              v-if="showMainSVG"
              id="radialSVG"
              :name="radialIcon.icon"
              title="radial-submenu_"
              :primary="radialIcon.primary"
              :secondary="radialIcon.secondary"
              size="50"
              glow="false"
              text=""
            />
          </v-btn>
          <radial-menu
            id="radId"
            v-if="m1.visible"
            style="z-index: 100"
            :conf="m1.conf"
            :pos="m1.pos"
            :visible="m1.visible"
            @reduceMenu="redMenu"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TextToSVG from "text-to-svg";
import RadialMenu from "../utils/toms-radial-menu-obs";
import Icons from "../utils/icons.json";
import ShowSVG from "../components/ShowSVG";
import { generateIconSvg } from "../utils/generateIconSvg";


export default {
  name: "RadialMenuComponent",
  props: { user: {}, myIssues: [], message: null, showMsgBox: null, radialIcon: null },
  components: {
    RadialMenu,
    ShowSVG
  },
  data() {
    return {

      showMainSVG: true,
      closeIcon: "",
      //Radial Menu
      menuConfig: {
        angle: 265,
        aperture: 200,
        radius: 40,
        height: 0,
        btns: [
          {         
            aperture: 0.1,
            height: 75,
            title: "Close",
            icon: Icons.x,
            action: async () => {
              this.m1.visible = false;
              //this.generateMenuButtons();
            },
            backgroundcolor: "#CCCCCC",
            textcolor: "black",
          },
        ],
      },
      m1: {
        conf: {},
        pos: { x: 32, y: -32 },
        visible: false,
      },

      issue_layers: [],
      selectedIssueIdx: 0,
      selectedIssue: {},
      lastIssue: null,

      show_info: true,
      innerHeight: 0
      
    };
  },
  created: async function () {
    this.innerHeight = ((window.innerHeight - 667) / 3.2) + 65;
    this.m1.conf = this.menuConfig;

  },
  mounted() {
    this.loadIssues(null);
    this.generateMenuButtons();
  },
  methods: {
    customFilter(object, _id) {
      if (object) {
        if (object.hasOwnProperty("_id") && object["_id"] == _id) return object;

        for (let i = 0; i < Object.keys(object).length; i++) {
          if (typeof object[Object.keys(object)[i]] == "object") {
            let o = this.customFilter(object[Object.keys(object)[i]], _id);
            if (o != null) return o;
          }
        }
      }
      return null;
    },
    showMenu(instance) {
      
        instance.visible = false;

        this.$nextTick(() => {
          instance.visible = true;
        });
        // setTimeout(() => {
        //   instance.visible = true;
        // }, 200);
  
      return false;
    },
    async redMenu(obj) {
      //console.log("Reduce Menu", obj);
      if (obj.id != 0) {
        // this.generateMenuButtons(obj);
        this.showMenu(this.m1);
      }
    },

    // clickIssue(issue) {
    //   console.log("Click Issue ", issue, this.selectedIssue);
    //   this.selectedIssue = issue;
    //   this.loadIssues(issue._id);
     
    // },
    // backIssues() {
    //   let parent_issue = _.find(this.myIssues, { _id: this.lastIssue });
    //   this.loadIssues(parent_issue.parent);
    //   //console.log("backIssue: ", parent_issue, this.lastIssue);
    //   this.selectedIssue = parent_issue;
    //   this.selectedIssueIdx = _.findIndex(this.issue_layers, {
    //     _id: parent_issue._id,
    //   });
      
    // },
    async loadIssues(parent) {
      //let t = new Date().getTime();
      for (let issue of this.myIssues) {
        //console.log("toSVG ", issue);        
        this.toSVG(issue.name).then((svg) => {
          issue.svg = svg;
        });

      }
      //console.log("toSVG Time needed: ", new Date().getTime() - t, new Date().getTime(), t);

      let issue_layers = _.filter(this.myIssues, {
        parent: parent,
        active: true,
      });

      if (issue_layers.length > 0) {
        this.lastIssue = parent;
        this.issue_layers = issue_layers;
      }
    },

    async generateMenuButtons(root) {
      try {
        let loc = _.find(this.Icons, {name: "close"});
        let closeIcon = await this.generateSvgObj(loc);
        //ToDo: Accelerate Function - Very Slow - Maybe pregenerate svg
        //console.log("start Gen Menu");
        //console.log(new Date() - this.start_time);
        this.start_time = new Date();
        //let obj = { height: 50, title:"Pen", icon: Icons.pen,  action: ()=>{ console.log('action'); this.m1.visible=false; this.generateMenuButtons();}}
        // let user = _.find(this.Users, { _id: this.selectedUser });
        //console.log("generateMenuButtons user ", user);
        //let user = this.user;
        this.menuConfig.btns = [
          {
            aperture: 0.100,
            height: 75,
            title: "Close",
            icon: closeIcon.icon, //this.closeIcon,
            action: () => {
              //this.generateMenuButtons();
              this.m1.visible = false;
            },
            backgroundcolor: "#CCCCCC",
            textcolor: "black",
          },
        ];

        let _treeIssues = [];
        if (root == null) {
          _treeIssues = _.cloneDeep(
            this.myIssues.filter((obj) => {
              return obj.parent == null;
            })
          );
        }
        // only one root
        else {
          _treeIssues = _.cloneDeep(
            this.myIssues.filter((obj) => {
              return obj.name == root.title;
            })
          );
        }

        //console.log("Start Tree 1 ",_treeIssues);
        let treeIssues = [];
        for (let loc of _treeIssues) {
          // console.log(loc);
          // let obj = {};
          // obj.height = 75;
          // let svg = "";

          // if (this.$vuetify.theme.isDark) {
          //   obj.textcolor = this.$vuetify.theme.themes.dark[loc.textcolor];
          //   obj.primary = this.$vuetify.theme.themes.dark[loc.primary];
          //   obj.secondary = this.$vuetify.theme.themes.dark[loc.secondary];
          // } else {
          //   obj.textcolor = this.$vuetify.theme.themes.light[loc.textcolor];
          //   obj.primary = this.$vuetify.theme.themes.light[loc.primary];
          //   obj.secondary = this.$vuetify.theme.themes.light[loc.secondary];
          // }

          // //Name or Icon
          // //svg = await this.toSVG(loc.name);
          // //console.log(svg)
          // //console.log("Start generateIconSvg")
          // try {
          //   svg = await generateIconSvg(loc.icon, obj.primary, loc.name, 30, obj.secondary);
          // } catch(err) {
          //   console.log(err);
          // }
          // //console.log(svg)
       

          // let parser = new DOMParser();
          // let xmlDoc = parser.parseFromString(svg, "image/svg+xml");
          // const defs = xmlDoc.documentElement.getElementsByTagName("defs");
          // const d = xmlDoc.documentElement.getElementsByTagName("g");
          // //const _paths = xmlDoc.getElementsByTagName("path");
          // const svgElement = defs[0].innerHTML + d[0].innerHTML;
          // //console.log(svgElement)
          // // console.log(d[0].innerHTML)
          // const width = parseInt(xmlDoc.documentElement.getAttribute("width"));

          // const height = parseInt(xmlDoc.documentElement.getAttribute("height"));

          // //let d = "";
          // // try {
          // //   d = xmlDoc.documentElement.childNodes[0].getAttribute("d");
          // // } catch (err) {
          // //   try {
          // //     d = xmlDoc.documentElement.childNodes[3].childNodes[1].getAttribute("d");
          // //   } catch (err) {
          // //     console.log(err);
          // //   }
          // // }
          // //d = _paths[0].getAttribute("d");

          // //console.log(width, height, d, "community_primary");


          // let icon = { d: svgElement, size: {}, svg: svg};
          // icon.size.h = height * 0.75;
          // icon.size.w = height * 0.75;
          // // icon.size.w = width;
          // // icon.size.h = height;
          // // obj.icon = svg;
          // obj.icon = icon;


          // let checkChilds = _.find(this.myIssues, {parent: loc._id});
          // if (checkChilds) {
          //   loc.hasNoChilds = false;
          // } else {
          //   loc.hasNoChilds = true;
          // }
          // obj.action = () => {
          //   //console.log(loc, loc.name);
          //   if (loc.hasNoChilds) {
          //     this.selectedIssue = loc;
          //     //this.generateMenuButtons();
          //     this.m1.visible = false;
          //   }
          // };
          // obj.title = loc.name;

          // //obj.backgroundcolor = loc.primary;
          // obj.backgroundcolor = "#333333";

          // obj._id = loc._id;

          // obj.btns = [];
          let obj = await this.generateSvgObj(loc);
          // console.log(obj)
          treeIssues.push(obj);
         
        }

        let tree = _.cloneDeep(
          this.myIssues.filter((obj) => {
            return obj.parent != null;
          })
        );
        let iteration = 0;

        //console.log("Start Tree ", tree);

        while (tree.length > 0) {
          let treeindex = [];
          for (let index in tree) {
            let loc = tree[index];
            let findobj = this.customFilter(treeIssues, loc.parent);

            //console.log(findobj)
            if (findobj) {
              loc.btns = [];
              //findobj.btns.push(loc)
              // let obj = {};
              // obj.height = 75;

              // let svg = "";
              // // if (chat_count > 0) {
              // //   svg = await this.toSVG(loc.name + " " + String(chat_count));
              // // } else {
              // //   svg = loc.svg;
              // // }

              // // //let svg = await this.toSVG(loc.name);
              // // //let svg = loc.svg;

              // // let d = svg.substring(svg.indexOf("<path d=") + 9, svg.length);
              // // d = d.substring(0, d.indexOf('"'));

              // // let width = svg.substring(svg.indexOf("width=") + 7, svg.length);
              // // //width = width.substring(width.indexOf('"') + 1,width.length)
              // // width = parseInt(width.substring(0, width.indexOf('"')));

              // // let height = svg.substring(
              // //   svg.indexOf("height=") + 8,
              // //   svg.length
              // // );
              // // height = parseInt(height.substring(0, height.indexOf('"')));

              // // let icon = { d: d, size: {} };
              // // // icon.size.h = height * 1.5 - 17;
              // // // icon.size.w = height * 1.5;
              // // icon.size.w = width;
              // // icon.size.h = height;

              // // // obj.icon = svg;
              // // obj.icon = icon;
              // // obj.action = () => {
              // //   //console.log(loc.name);
              // //   this.selectedIssue = loc;
              // //   this.selectedCostcenter = loc.costcenter;
              // //   this.generateMenuButtons();
              // //   this.m1.visible = false;
              // // }; //
              // // obj.title = loc.name;
              // // obj._id = loc._id;
              // // obj.textcolor = "white";
              // // obj.backgroundcolor = loc.primary;
              // // obj.btns = [];
              // /////////////////////////////

              // if (this.$vuetify.theme.isDark) {
              //   obj.textcolor = this.$vuetify.theme.themes.dark[loc.textcolor];
              //   obj.primary = this.$vuetify.theme.themes.dark[loc.primary];
              //   obj.secondary = this.$vuetify.theme.themes.dark[loc.secondary];
              // } else {
              //   obj.textcolor = this.$vuetify.theme.themes.light[loc.textcolor];
              //   obj.primary = this.$vuetify.theme.themes.light[loc.primary];
              //   obj.secondary = this.$vuetify.theme.themes.light[loc.secondary];
              // }

              // try {
              //   svg = await generateIconSvg(loc.icon, obj.primary, loc.name, 30, obj.secondary);
              // } catch(err) {
              //   console.log(err);
              // }
              // //console.log(svg)
          
              // let parser = new DOMParser();
              // let xmlDoc = parser.parseFromString(svg, "image/svg+xml");
              // const defs = xmlDoc.documentElement.getElementsByTagName("defs");
              // const d = xmlDoc.documentElement.getElementsByTagName("g");
              // const svgElement = defs[0].innerHTML + d[0].innerHTML;
              // // const width = parseInt(xmlDoc.documentElement.getAttribute("width"));

              // const height = parseInt(xmlDoc.documentElement.getAttribute("height"));

              // let icon = { d: svgElement, size: {}, svg: svg};
              // icon.size.h = height * 0.75;
              // icon.size.w = height * 0.75;
              // obj.icon = icon;
              
              // let checkChilds = _.find(this.myIssues, {parent: loc._id});
              // if (checkChilds) {
              //   loc.hasNoChilds = false;
              // } else {
              //   loc.hasNoChilds = true;
              // }

              // obj.action = () => {
              //   if (loc.hasNoChilds) {
              //     this.selectedIssue = loc;
              //     //this.generateMenuButtons();
              //     this.m1.visible = false;
              //   }
              // };
              // obj.title = loc.name;

              // obj.backgroundcolor = "#333333";

              // obj._id = loc._id;

              // obj.btns = [];

              let obj = await this.generateSvgObj(loc);
              findobj.btns.push(obj);
              treeindex.push(index);
            }
          }
          _.pullAt(tree, treeindex);
          iteration++;
          if (iteration > 1000) break;
        }
        //console.log(treeIssues);

        for (let btn of treeIssues) {
          this.menuConfig.btns.push(btn);
        }

        this.calcChatHeight();

        this.loading_data = false;
      } catch (err) {
        //console.log("generate Menu Error: ", err);
        //this.generateMenuButtons();
      }
    },
    toSVG(text) {
      return new Promise((resolve, reject) => {
        try {
          TextToSVG.load("./SourceSansPro-Regular.otf", (error, textToSVG) => {
            // console.log("TexttoSVG: ", textToSVG)
            const svg = textToSVG.getSVG(text);
            resolve(svg);
          });
        } catch (err) {
          reject(err);
        }
      });
    },

    async generateSvgObj(loc) {

      let obj = {};
      obj.height = 75;
      let svg = "";

      if (this.$vuetify.theme.isDark) {
        obj.textcolor = this.$vuetify.theme.themes.dark[loc.textcolor];
        obj.primary = this.$vuetify.theme.themes.dark[loc.primary];
        obj.secondary = this.$vuetify.theme.themes.dark[loc.secondary];
      } else {
        obj.textcolor = this.$vuetify.theme.themes.light[loc.textcolor];
        obj.primary = this.$vuetify.theme.themes.light[loc.primary];
        obj.secondary = this.$vuetify.theme.themes.light[loc.secondary];
      }

      //Name or Icon
      //svg = await this.toSVG(loc.name);
      //console.log(svg)
      //console.log("Start generateIconSvg ", loc)
      try {
        if (loc.actTemp) {
          svg = await generateIconSvg(loc.icon, obj.primary, loc.name, 30, obj.secondary, loc.actTemp.toFixed(1) + "°C");
        } else {
          svg = await generateIconSvg(loc.icon, obj.primary, loc.name, 30, obj.secondary, "");
        }
      } catch(err) {
        console.log(err);
      }
      //console.log(svg)
    

      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(svg, "image/svg+xml");
      const defs = xmlDoc.documentElement.getElementsByTagName("defs");
      const d = xmlDoc.documentElement.getElementsByTagName("g");
      //const _paths = xmlDoc.getElementsByTagName("path");
      const svgElement = defs[0].innerHTML + d[0].innerHTML;
      //console.log(svgElement)
      // console.log(d[0].innerHTML)
      const width = parseInt(xmlDoc.documentElement.getAttribute("width"));

      const height = parseInt(xmlDoc.documentElement.getAttribute("height"));

      //let d = "";
      // try {
      //   d = xmlDoc.documentElement.childNodes[0].getAttribute("d");
      // } catch (err) {
      //   try {
      //     d = xmlDoc.documentElement.childNodes[3].childNodes[1].getAttribute("d");
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
      //d = _paths[0].getAttribute("d");

      //console.log(width, height, d, "community_primary");


      let icon = { d: svgElement, size: {}, svg: svg};
      icon.size.h = height * 0.75;
      icon.size.w = height * 0.75;
      // icon.size.w = width;
      // icon.size.h = height;
      // obj.icon = svg;
      obj.icon = icon;


      let checkChilds = _.find(this.myIssues, {parent: loc._id});
      if (checkChilds) {
        loc.hasNoChilds = false;
      } else {
        loc.hasNoChilds = true;
      }
      obj.action = () => {
        // console.log("obj_action: ",loc, loc.name);
        if (loc.hasNoChilds) {
          this.selectedIssue = loc;
          //this.generateMenuButtons();
          this.m1.visible = false;
        }
      };
      obj.title = loc.name;

      //obj.backgroundcolor = loc.primary;
      //obj.backgroundcolor = "#333333";
      obj.backgroundcolor = this.cssVars["--bg"];

      obj._id = loc._id;

      obj.btns = [];

      return obj;
    }

    
  },

  computed: {
    ...mapState({
      SelectedScreen: ( state ) => state.selectedScreen,
      Icons: ({ icon }) => icon.Icons,
      radialLoading: (state) => state.radialLoading,
    }),

    
    cssVars() {
      let left = 8;
      if (!this.showMsgBox) {
        left = window.innerWidth - 90;
      }
      //window.innerWidth
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--left': left + 'px',
          '--max-width': (window.innerWidth - 108) + 'px',
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--left': left + 'px',
          '--max-width': (window.innerWidth - 106) + 'px',
        }
      }
    }
  },
  watch: {
    async myIssues(issues) {
      //console.log("issues changed ", issues);
      
      this.loadIssues(null);
      // //console.log("issues changed ", this.issue_layers);
      this.generateMenuButtons();

      // this.selectedIssueIdx = 0;
      // if (this.issue_layers.length > 0) {
      //   this.selectedIssue = this.issue_layers[0];
      // }
    },
    message() {
      this.show_info = false;
      this.$nextTick(() => {
        this.show_info = true;
      });
    },
    selectedIssue(obj) {
      this.$emit("radialClick", obj);
    },
    radialIcon() {
      
      this.showMainSVG = false;
      this.$nextTick(() => {
        this.showMainSVG = true;
      });
    },
    SelectedScreen() {
      this.m1.visible = false;
    }
  },
};
</script>

<style lang='scss'>

.radial-menu {
  position: absolute;
  bottom: 49%;
  left: var(--left);
  z-index: 10;
}


.btn {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  padding: 6px 10px;
  border-radius: 6px;
  border: 2px solid currentColor;
}

.info-field {
  //background: radial-gradient(15px at right, #0000 97%,var(--bg)) right;
  //background-repeat: no-repeat;
  background: var(--bg);
  border-radius: 4px;
  max-width: var(--max-width);
}

.progress-radial-menu {
  position: absolute;
}

.leafbox {
  border: solid 3px var(--bg);
  border-radius: 6px;
}
</style>