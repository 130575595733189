import api from "../../../api";

export default {
  async getScreens({ commit }, filter) {
    commit("REQUEST_SCREENS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SCREENS",
        await api.getScreens(filter)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_SCREENS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteScreen({ commit }, id) {
    commit("REQUEST_DELETE_SCREEN");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_SCREEN",
        await api.deleteScreen(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_SCREEN");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateScreen({ commit }, item ) {
    commit("REQUEST_UPDATE_SCREEN");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_SCREEN",
        await api.updateScreen(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_SCREEN");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createScreen({ commit }, item ) {
    commit("REQUEST_CREATE_SCREEN");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_SCREEN",
        await api.createScreen( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_SCREEN");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
