<template>
  <v-container class="pa-0 ma-0 " :style="cssVars" >
    <v-row class="ml-0 mr-3"> 
      <v-col class="text-center font-weight-bold my-n3">  
        Benchmark Overview
      </v-col>
    </v-row>
    <v-row class="ml-0 mr-3 mt-5"> 
      <v-col class="text-center my-n3">  
        Rank: # {{myHeatingScore.overallRank}}
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 mt-5 mb-n1 rankingStyle" >
      <v-col class="">
        <v-row class="">
          <v-col class="caption pb-0 font-weight-bold" cols="7">
            Overall
          </v-col>
        </v-row>
        <v-row>     
          <v-col class="caption px-0">
            <v-data-table
              :headers="headers_overall"
              :items="benchmarksOverall"   
              disable-pagination
              disable-filtering
              hide-default-footer
              mobile-breakpoint="0"
              dense      
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 rankingStyle" >
      <v-col > 
        <v-row class="">
          <v-col class="caption pb-0 font-weight-bold" cols="7">
            Flexibility
          </v-col>
        </v-row>
        <v-row>     
          <v-col class="caption px-0">
            <v-data-table
              :headers="headers_flexibility"
              :items="benchmarksFlexibility"   
              disable-pagination
              disable-filtering
              hide-default-footer
              mobile-breakpoint="0"
              dense      
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="ml-0 mr-3 rankingStyle" >
      <v-col > 
        <v-row class="">
          <v-col class="caption pb-0 font-weight-bold" cols="7">
            Energy
          </v-col>
        </v-row>
        <v-row>     
          <v-col class="caption px-0">
            <v-data-table
              :headers="headers_energy"
              :items="benchmarksEnergy"   
              disable-pagination
              disable-filtering
              hide-default-footer
              mobile-breakpoint="0"
              dense      
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
   
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";


export default {
  name: "BenchmarkDataScreen",
  props: { },
  components: {
   
  },
  data() {
    return {
      headers_overall: [
        { text: 'Rank', value: 'overallRank', align: 'left' },
        { text: 'User', value: 'user', align: 'center' },
        { text: 'Points', value: 'overall', align: 'right' },
      ],
      headers_flexibility: [
        { text: 'Rank', value: 'flexibilityRank', align: 'left' },
        { text: 'User', value: 'user', align: 'center' },
        { text: 'Points', value: 'flexibility', align: 'right' },
      ],
      headers_energy: [
        { text: 'Rank', value: 'energyRank', align: 'left' },
        { text: 'User', value: 'user', align: 'center' },
        { text: 'Points', value: 'energy', align: 'right' },
      ],
    };
  },
  created: function () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  },
  async mounted() { 
    // await this.getAllUserBenchmarks();
  },
  methods: {
    // ...mapActions("dataset", [
    //   "getAllUserBenchmarks"
    // ]),
  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      Global: ({ global }) => global.Global,
      Benchmarks : ({ dataset }) => dataset.AllUserBenchmarks,
    }),

    benchmarksOverall() {
      return _.orderBy(this.Benchmarks, ["overallRank"], ["asc"])
    },

    benchmarksFlexibility() {
      return _.orderBy(this.Benchmarks, ["flexibilityRank"], ["asc"])
    },

    benchmarksEnergy() {
      return _.orderBy(this.Benchmarks, ["energyRank"], ["asc"])
    },

    myHeatingScore() {
      const heatingType = this.Global.deviceTypes.find( obj => {
        return obj.value == "heat"
      })
      const myBenchmark = this.Benchmarks.find( obj => {
        return obj.user_id == this.mySelf._id && obj.type == heatingType._id
      })
      return myBenchmark;
    },
   
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    },

    themeMode() {
      if (this.$vuetify.theme.isDark) {
        return "dark";
      } else {
        return "light"
      }
    }
  },
  watch: {
    themeMode(theme) {
      // console.log("Theme changed ", theme);
      
    }
  },
};
</script>

<style lang='scss'>

.rankingStyle {
  width: 80%;
}

</style>