<template>
  <div class="report" v-if="mySelf">
    <v-card flat>
      <v-progress-circular
        indeterminate
        :size="50"
        :width="7"
        color="secondary"
        v-if="loading_data"
      >
      </v-progress-circular>
      <v-row v-if="!loading_data">
        <v-col cols="auto" class="pa-7">
          <!-- -->
          <v-row>          
            <v-col>
              <v-text-field
                dense
                type="date"
                v-model="selected_date"
                v-on:click="calcReport = false"
                label="Datum"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
                dense
                v-model="checkbox_month"
                v-on:click="calcReport = false"
                label="Only Set Month"
              ></v-checkbox>
            </v-col>  
            <v-col>
              <v-checkbox
                dense
                v-model="checkbox_project"
                v-on:click="calcReport = false"
                label="Filter Project"
              ></v-checkbox>
            </v-col>
            <v-col v-if="checkbox_project">
              <v-autocomplete
                dense
                :items="Issues"     
                item-text="name"
                item-value="_id"
                v-model="selected_project"
                v-on:click="calcReport = false"
                label="Project"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-checkbox
                dense
                v-model="checkbox_location"
                v-on:click="calcReport = false"
                label="Filter Location"
              ></v-checkbox>
            </v-col>            
            <v-col v-if="checkbox_location">
              <v-autocomplete
                dense
                :items="Locations"     
                item-text="name"
                item-value="_id"
                v-model="selected_location"
                v-on:click="calcReport = false"
                label="Location"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-checkbox
                dense
                v-model="checkbox_costcenter"
                v-on:click="calcReport = false"
                label="Filter Costcenter"
              ></v-checkbox>
            </v-col>            
            <v-col v-if="checkbox_costcenter">
              <v-autocomplete
                dense
                :items="Costcenters"     
                item-text="name"
                item-value="_id"
                v-model="selected_costcenter"
                v-on:click="calcReport = false"
                label="Costcenter"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="mySelf.roles.includes('admin')">
              <v-checkbox
                dense
                v-model="checkbox_user"
                v-on:click="calcReport = false"
                label="Filter User"
              ></v-checkbox>
            </v-col>            
            <v-col v-if="checkbox_user">
              <v-autocomplete
                dense
                :items="Users"     
                item-text="fullname"
                item-value="_id"
                v-model="selected_user"
                v-on:click="calcReport = false"
                label="User"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!calcReport">
              <v-btn color="blue" small dark @click="generateExcel()">
                Generate Detail Report
              </v-btn>
            </v-col>
            <v-col v-if="!calcReport">
              <v-btn color="blue" small dark @click="generateExcel2()">
                Generate Projekt Level Report
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
         <!--   <v-col v-if="!calcReport">
              <v-btn color="blue" small dark @click="generateExcel3()">
                Generate All Projekt Report from Date
              </v-btn>
            </v-col>-->
            

            <v-col v-if="calcReport">
              <download-excel
                :data="json_data"
                :fields="json_fields"
                :header="json_title"
                worksheet="Report"
              >
                <v-btn color="success" small elevation="1"
                  >Download Excel</v-btn
                >
              </download-excel>
            </v-col>
            <v-col v-if="calcReport">
              <v-btn
                color="error"
                small
                elevation="1"
                v-on:click="calcReport = false"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-4 font-weight-bold">
          {{json_title}}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-4">
          <v-data-table :headers="json_header" :items="json_data">
          </v-data-table>
        </v-col>
      </v-row>
      <!-- <v-col cols="12"  v-if="!isCertificate">
          <v-card class="mt-2 pa-4">
            <p class="pl-1 h5">Money Reports</p>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Im Umlauf befindliche Tokens
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="issuedCoinsOptions"
                  :series="issuedCoinsSeries"
                >
                </apexchart>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="!isCertificate">
          <v-card class="mt-2 pa-4">
            <p class="pl-1 h5">User Reports</p>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Bruttoumsatz
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="userOptions"
                  :series="userSeries"
                >
                </apexchart>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Anzahl Buchungen
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="userOptions"
                  :series="userCountSeries"
                >
                </apexchart>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Buchungsdauer in Stunden
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="userOptions"
                  :series="userDurationSeries"
                >
                </apexchart>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12"  v-if="!isCertificate">
          <v-card class="mt-2 pa-4">
            <p class="pl-1 h5">Machine Reports</p>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Bruttoumsatz
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="machineOptions"
                  :series="machineSeries"
                >
                </apexchart>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Anzahl Buchungen
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="machineOptions"
                  :series="machineCountSeries"
                >
                </apexchart>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Buchungsdauer in Stunden
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="machineOptions"
                  :series="machineDurationSeries"
                >
                </apexchart>
              </v-col>
               <v-col cols="12" sm="12" md="5" lg="4" class="pa-5">
                Auslastung in %
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="machineOptions"
                  :series="machineUtilisationSeries"
                >
                </apexchart>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
         <v-col cols="12" v-if="isCertificate">
          <v-card class="mt-2 pa-4">
            <p class="pl-1 h5">Reports</p>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" class="pa-5">
                Umsatz
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="buyableOptions"
                  :series="buyableSeries"
                >
                </apexchart>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="pa-5">
                Anzahl Testungen (negativ/positiv)
                <apexchart
                  class="box-grey"
                  v-if="!loading_data"
                  type="bar"
                  :options="buyableOptions"
                  :series="buyableCountSeries"
                >
                </apexchart>
              </v-col>
              
             
            </v-row>
          </v-card>
        </v-col> -->
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "report",
  components: {},
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      loading_data: true,
      userOptions: {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        chart: {
          id: "user",
        },
        xaxis: {
          categories: [],
        },
      },
      userSeries: [],
      userCountSeries: [],
      userDurationSeries: [],
      machineOptions: {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        chart: {
          id: "machine",
        },
        xaxis: {
          categories: [],
        },
      },
      machineSeries: [],
      machineCountSeries: [],
      machineDurationSeries: [],
      machineUtilisationSeries: [],
      buyableOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        chart: {
          id: "buyable",
        },
        xaxis: {
          categories: [],
        },
      },
      buyableSeries: [],
      buyableCountSeries: [],
      buyableDurationSeries: [],
      buyableUtilisationSeries: [],
      issuedCoinsOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        chart: {
          id: "issuedCoins",
        },
        xaxis: {
          categories: ["Aktuell"],
        },
      },
      issuedCoinsSeries: [],
      machines: [],
      buyables: [],
      isCertificate: false,

      json_data: [],
      json_header: [],
      json_fields: {},
      json_title: "",
      // json_fields: {
      //   "Test Name der Station": "",
      //   "Postleitzahl": "",
      //   "Ort": "",
      //   "Strasse": "",

      //   "Test Aufsichtsperson Name": "",
      //   "Test Aufsichtsperson Mailadresse": "",
      //   "Test Aufsichtsperson Mobilnummer": "",

      //   "Nachname": "",
      //   "Vorname": "",
      //   "SVNR": "",
      //   "Geburtsdatum": "",
      //   "Geschlecht": "",
      //   "Mobilnummer": "",
      //   "Email Adresse": "",
      //   "Postleitzahl": "",
      //   "Ort": "",
      //   "Strasse": "",
      //   "Hausnummer": "",
      //   "Türnummer": "",

      //   "Vorname": "",
      //   "Nachname": "",
      //   "Resultat": ""

      // },
      calcReport: false,
      selected_date: this.$moment().format("YYYY-MM-DD"),
      selected_project: "",
      checkbox_project: false,
      checkbox_month: false,
      checkbox_user: false,
      selected_user: "",
      checkbox_costcenter: false,
      selected_costcenter: "",
      checkbox_location: false,
      selected_location: ""
    };
  },
  async mounted() {
    // await this.loadMachines();
    // await this.loadMachineContracts();
    if (this.mySelf.roles.includes('admin')) {
      await this.getUsers({});
    }

    // this.generateUser();
    // this.generateMachine();
    // this.generateBuyables();
    // this.generateIssuedCoins();

    await this.getIssues();
    await this.getLocation({});
    await this.getCostcenters({active: true});

    this.loading_data = false;
    //
    //console.log(this.Web3.SitesRegistryContract)

    //this.generateExcel();
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Event: (state) => state.event,
      Issues: ({ issue }) => issue.Issues,
      IssueState: ({ issue }) => issue,
      // Web3: (web3) => web3.web3,
      // MachineContracts: ({ machineContract }) =>
      // machineContract.MachineContracts,
      Users: ({ user }) => user.Users,
      // Sites: ({ user }) => user.Sites,
      // selectedSite: ({ user }) => user.selectedSite,
      Costcenters: ({ costcenter }) => costcenter.Costcenters,
      CostcenterState: ({ costcenter }) => costcenter,
      Locations: ({ location }) => location.Locations,
    }),
  },
  methods: {
    // ...mapActions("machineContract", ["getMachineContracts"]),
    ...mapActions("user", ["getUsers"]),
    ...mapActions("event", ["getEventsReport"]),
    ...mapActions("issue", ["getIssues"]),
    ...mapActions("costcenter", ["getCostcenters"]),
    ...mapActions("location", ["getLocation"]),

    // async loadMachineContracts() {
    //   let filter = {};
    //   //filter.startTime = {$lte: this.$moment().format('X'), $gte: this.$moment().startOf('month').subtract('month', 1).format('X')};
    //   filter.startTime = {
    //     $lte: this.$moment().add("days", 30).format("X"),
    //     $gte: this.$moment().subtract("days", 30).format("X"),
    //   };

    //   filter.rentBC = this.Sites[this.selectedSite].rentBC.toLowerCase(),

    //   filter.cancelled = false;
    //   //filter.finalized = true;
    //   await this.getMachineContracts(filter);
    // },

    // async generateUser() {
    //   let contracts = _.groupBy(this.MachineContracts, "renter");
    //   let renter = Object.keys(contracts);
    //   let root = [];

    //   for (let idx in renter) {
    //     const user = _.find(this.Users, { crypto_address: renter[idx] });
    //     try {
    //       root.push({
    //         renter: renter[idx],
    //         name: user.firstname + " " + user.lastname,
    //       });
    //     } catch {
    //       console.log(renter[idx], " not readable - Permission?")
    //     }
    //   }

    //   let filter = _.filter(this.MachineContracts, (obj) => {
    //     return obj.startTime < this.$moment().format("X");
    //   });
    //   contracts = _.groupBy(filter, "renter");
    //   for (let idx in root) {
    //     root[idx].sum1 = 0;
    //     root[idx].count1 = 0;
    //     root[idx].duration1 = 0;
    //     for (let renter in contracts) {
    //       if (renter == root[idx].renter) {
    //         //console.log(contracts[renter]);
    //         root[idx].sum1 = (
    //           _.sumBy(contracts[renter], "gross") / 1000
    //         ).toFixed(2);
    //         root[idx].count1 = contracts[renter].length;
    //         for (let contract of contracts[renter]) {
    //           root[idx].duration1 +=
    //             (contract.endTime - contract.startTime) / 360;
    //         }
    //       }
    //     }
    //     root[idx].duration1 = parseInt(root[idx].duration1 * 100) / 100;
    //   }

    //   filter = _.filter(this.MachineContracts, (obj) => {
    //     return obj.startTime >= this.$moment().format("X");
    //   });
    //   contracts = _.groupBy(filter, "renter");
    //   for (let idx in root) {
    //     root[idx].sum2 = 0;
    //     root[idx].count2 = 0;
    //     root[idx].duration2 = 0;
    //     for (let renter in contracts) {
    //       if (renter == root[idx].renter) {
    //         root[idx].sum2 = (
    //           _.sumBy(contracts[renter], "gross") / 1000
    //         ).toFixed(2);
    //         root[idx].count2 = contracts[renter].length;
    //         for (let contract of contracts[renter]) {
    //           root[idx].duration2 +=
    //             (contract.endTime - contract.startTime) / 360;
    //         }
    //       }
    //     }
    //     root[idx].duration2 = parseInt(root[idx].duration2 * 100) / 100;
    //   }
    //   //console.log(root);

    //   this.userOptions = {
    //     plotOptions: {
    //       bar: {
    //         horizontal: true,
    //       },
    //     },
    //     chart: {
    //       id: "user",
    //     },
    //     xaxis: {
    //       categories: _.map(root, "name"),
    //     },
    //   };

    //   this.userSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "sum1"),
    //     },
    //     { name: "kommende 30 Tage", data: _.map(root, "sum2") },
    //   ];

    //   this.userCountSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "count1"),
    //     },
    //     {
    //       name: "kommende 30 Tage",
    //       data: _.map(root, "count2"),
    //     },
    //   ];

    //   this.userDurationSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "duration1"),
    //     },
    //     {
    //       name: "kommende 30 Tage",
    //       data: _.map(root, "duration2"),
    //     },
    //   ];
    // },

    // async generateMachine() {
    //   let spaceOpenTime = await this.Web3.RentContract.getAllTimeSlots(0);
    //   let maxDuration = 0;
    //   for (let day of spaceOpenTime) {
    //     for (let inDay of day) {
    //       let obj = array2Object(inDay);
    //       maxDuration += obj.stop - obj.start;
    //     }
    //   }
    //   maxDuration = maxDuration / 60 * 30;
    //   //console.log(maxDuration);

    //   let contracts = _.groupBy(this.MachineContracts, "machineId");
    //   let machines = Object.keys(contracts);
    //   let root = [];

    //   for (let idx in machines) {
    //     //console.log(machines[idx])
    //     const machine = _.find(this.machines, {
    //       MachineId: parseInt(machines[idx]),
    //     });
    //     if (machine) {
    //       root.push({
    //         machineId: machines[idx],
    //         name: machine.Name,
    //       });
    //     }
    //   }

    //   let filter = _.filter(this.MachineContracts, (obj) => {
    //     return obj.startTime < this.$moment().format("X");
    //   });
    //   contracts = _.groupBy(filter, "machineId");
    //   //console.log(contracts)
    //   for (let idx in root) {
    //     root[idx].sum1 = 0;
    //     root[idx].count1 = 0;
    //     root[idx].duration1 = 0;
    //     for (let contract in contracts) {
    //       if (contract == root[idx].machineId) {
    //         //console.log("contract: ", contract);
    //         //console.log(contracts[contract]);
    //         root[idx].sum1 = (
    //           _.sumBy(contracts[contract], "gross") / 1000
    //         ).toFixed(2);
    //         root[idx].count1 = contracts[contract].length;
    //         for (let _contract of contracts[contract]) {
    //           root[idx].duration1 +=
    //             (_contract.endTime - _contract.startTime) / 3600;
    //         }
    //       }
    //     }
    //     root[idx].duration1 = parseInt(root[idx].duration1 * 100) / 100;
    //     root[idx].utilisation1 = parseInt(root[idx].duration1 / maxDuration * 10000) / 100;
    //   }

    //   filter = _.filter(this.MachineContracts, (obj) => {
    //     return obj.startTime >= this.$moment().format("X");
    //   });
    //   contracts = _.groupBy(filter, "machineId");
    //   //console.log("contracts: ", contracts);
    //   for (let idx in root) {
    //     root[idx].sum2 = 0;
    //     root[idx].count2 = 0;
    //     root[idx].duration2 = 0;
    //     for (let contract in contracts) {
    //       if (contract == root[idx].machineId) {
    //         root[idx].sum2 = (
    //           _.sumBy(contracts[contract], "gross") / 1000
    //         ).toFixed(2);
    //         root[idx].count2 = contracts[contract].length;
    //         for (let _contract of contracts[contract]) {
    //           //console.log("idx: ",idx, " duration2: ",_contract, " value",root[idx].duration2)
    //           root[idx].duration2 +=
    //             (_contract.endTime - _contract.startTime) / 3600;
    //         }
    //       }
    //     }
    //     root[idx].duration2 = parseInt(root[idx].duration2 * 100) / 100;
    //     root[idx].utilisation2 = parseInt(root[idx].duration2 / maxDuration * 10000) / 100;
    //   }
    //   //console.log(root);

    //   this.machineOptions = {
    //     plotOptions: {
    //       bar: {
    //         horizontal: true,
    //       },
    //     },
    //     chart: {
    //       id: "machine",
    //     },
    //     xaxis: {
    //       categories: _.map(root, "name"),
    //     },
    //   };

    //   this.machineSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "sum1"),
    //     },
    //     { name: "kommende 30 Tage", data: _.map(root, "sum2") },
    //   ];

    //   this.machineCountSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "count1"),
    //     },
    //     {
    //       name: "kommende 30 Tage",
    //       data: _.map(root, "count2"),
    //     },
    //   ];

    //   this.machineDurationSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "duration1"),
    //     },
    //     {
    //       name: "kommende 30 Tage",
    //       data: _.map(root, "duration2"),
    //     },
    //   ];

    //   this.machineUtilisationSeries = [
    //     {
    //       name: "letzen 30 Tage",
    //       data: _.map(root, "utilisation1"),
    //     },
    //     {
    //       name: "kommende 30 Tage",
    //       data: _.map(root, "utilisation2"),
    //     },
    //   ];
    // },

    // async generateBuyables() {

    //   //console.log("GEN BUY ",this.MachineContracts)

    //   let contracts = _.groupBy(this.MachineContracts, "machineId");
    //   let machines = Object.keys(contracts);
    //   let root = [];

    //   for (let idx in machines) {
    //     //console.log(machines[idx])
    //     const machine = _.find(this.buyables, {
    //       MachineId: parseInt(machines[idx]),
    //     });
    //     //console.log(machine)
    //     let subCategory = {};
    //     let m_name = "";
    //     try {
    //       subCategory = JSON.parse(machine.SubCategory);
    //       if (subCategory.flag) {
    //         m_name = " POSITIV"
    //       } else {
    //         m_name = " NEGATIV"
    //       }
    //     } catch(err) {}

    //     root.push({
    //       machineId: machines[idx],
    //       name: machine.Name + m_name
    //     });
    //   }
    //   //console.log(root)
    //   let seriesSum = [];
    //   let seriesCount = [];
    //   let category = [];
    //   for (let idx in root) {
    //     let dataSum = [];
    //     let dataCount = [];
    //     let dataDay = [];
    //     for (let day = 14; day >= 0; day--) {

    //       //console.log(root[idx].machineId)
    //       let filter = _.filter(this.MachineContracts, (obj) => {
    //         return obj.startTime >= this.$moment().startOf("day").subtract(day, "day").format('X') && obj.startTime <= this.$moment().endOf("day").subtract(day, "day").format('X') && obj.machineId == root[idx].machineId;
    //       });

    //       contracts = _.groupBy(filter, "machineId");
    //       // if (filter.length > 0) {
    //       //   console.log("day ", day, this.$moment().startOf("day").subtract(day, "day").format('DD.MMM.YY'), contracts)
    //       // } else {
    //       //   console.log("day ", day, this.$moment().startOf("day").subtract(day, "day").format('DD.MMM.YY'), contracts)
    //       // }

    //       let sum = 0;
    //       let count = 0;

    //       for (let contract in contracts) {

    //           sum = (
    //             _.sumBy(contracts[contract], "gross") / 1000
    //           ).toFixed(2);
    //           count = contracts[contract].length;

    //       }
    //       dataSum.push(sum);
    //       dataCount.push(count);

    //       // console.log("sum: ",sum)
    //       // console.log("count: ",count)
    //       //console.log("filter: ",filter)

    //       //category.push({day: this.$moment().startOf("day").subtract(day, "day").format('DD.MMM.YY')})
    //       //if (filter.length > 0) {
    //         //category.push({day: this.$moment(filter[0].startTime, "X").startOf("day").subtract(day, "day").format('DD.MMM.YY')})
    //         //dataDay.push(this.$moment(filter[0].startTime, "X").format('DD.MMM.YY'));
    //         //dataDay.push({day: this.$moment(filter[0].startTime, "X").startOf("day").subtract(day, "day").format('DD.MMM.YY')});
    //       //}

    //     }
    //     // console.log("name: ",root[idx].name)
    //     // console.log("datasum: ",dataSum)
    //     // console.log("datacount: ",dataCount)
    //     //console.log("dataDay: ",dataDay)
    //     seriesSum.push({name: root[idx].name, data: dataSum});
    //     seriesCount.push({name: root[idx].name, data: dataCount});
    //   }
    //   //console.log(seriesCount)
    //   //category.push(category);
    //   for (let day = 14; day >= 0; day--) {

    //     category.push({day: this.$moment().startOf("day").subtract(day, "day").format('DD.MMM.YY')});
    //     //new_cat.push(cat.day);
    //   };
    //   //category = new_cat;
    //   //console.log("NEW CAT", new_cat)
    //   //console.log("CAT MAP", _.map(category, "day"))

    //   let id = "machine";
    //   if (this.isCertificate) { id = "Zertifikat";}

    //   this.buyableOptions = {
    //     plotOptions: {
    //       bar: {
    //         horizontal: false,
    //       },
    //     },
    //     chart: {
    //       id: id,
    //     },
    //     xaxis: {
    //       categories: _.map(category, "day"),
    //     },

    //   };

    //   this.buyableSeries = seriesSum;

    //   this.buyableCountSeries = seriesCount;

    //   // this.buyableDurationSeries = [
    //   //   {
    //   //     name: "letzen 30 Tage",
    //   //     data: _.map(root, "duration1"),
    //   //   },
    //   //   {
    //   //     name: "kommende 30 Tage",
    //   //     data: _.map(root, "duration2"),
    //   //   },
    //   // ];

    //   // this.buyableUtilisationSeries = [
    //   //   {
    //   //     name: "letzen 30 Tage",
    //   //     data: _.map(root, "utilisation1"),
    //   //   },
    //   //   {
    //   //     name: "kommende 30 Tage",
    //   //     data: _.map(root, "utilisation2"),
    //   //   },
    //   // ];
    // },

    // async loadMachines() {
    //   // console.log("Load Machines ");
    //   const _Machines = await this.Web3.RentContract.getAllMachines();
    //   let _machines = array2ArrayObject(_Machines);
    //   this.machines = [];
    //   this.buyables = [];
    //   if (_machines.length > 0) {
    //     for (let idx in _machines) {
    //       if (_machines[idx].Active && !_machines[idx].Buyable) {
    //         _machines[idx].MachineId = parseInt(idx) + 1;
    //         this.machines.push(_machines[idx]);
    //       } else if (_machines[idx].Active) {
    //         _machines[idx].MachineId = parseInt(idx) + 1;
    //         this.buyables.push(_machines[idx]);
    //         if (_machines[idx].Category == 'certificate') {this.isCertificate = true;};
    //       }
    //     }
    //   }
    // },

    // async generateIssuedCoins() {
    //   let issuedCoins = ethers.utils.formatEther(
    //     await this.Web3.TokenContract.totalSupply()
    //   );
    //   let data = [parseInt(issuedCoins * 100) / 100];
    //   //console.log(data)
    //   this.issuedCoinsSeries = [
    //     {
    //       name: "Aktuell",
    //       data: data,
    //     },
    //   ];
    // },

    // deepEqual(object1, object2) {
    //   const keys1 = Object.keys(object1);
    //   const keys2 = Object.keys(object2);

    //   if (keys1.length !== keys2.length) {
    //     return false;
    //   }

    //   for (const key of keys1) {
    //     const val1 = object1[key];
    //     const val2 = object2[key];
    //     const areObjects = this.isObject(val1) && this.isObject(val2);
    //     if (
    //       areObjects && !deepEqual(val1, val2) ||
    //       !areObjects && val1 !== val2
    //     ) {
    //       return false;
    //     }
    //   }

    //   return true;
    // },
    // isObject(object) {
    //   return object != null && typeof object === 'object';
    // },

    async loadReportEvents(filter) {
      await this.getEventsReport(filter);
      //console.log(this.Event.Events);
    },

    async generateExcel() {
      this.calcReport = false;
      this.json_data = [];
      
      this.json_title = "Detailed Report: " + this.$moment(this.selected_date, "YYYY-MM-DD").format("MMM YYYY");
      let filter = {};
      
      if (this.checkbox_month) {
        
        filter.from = this.$moment(this.selected_date, "YYYY-MM-DD")
          .startOf("month")
          .format("X");
        filter.to = this.$moment(this.selected_date, "YYYY-MM-DD")
          .endOf("month")
          .format("X");
      } else {
        this.json_title += " to " + this.$moment(new Date(), "YYYY-MM-DD").format("MMM YYYY");
        filter.from = this.$moment(this.selected_date, "YYYY-MM-DD").format("X");
      }

      const selected_project_name = _.find(this.Issues, {_id: this.selected_project});
      if (this.checkbox_project) {
        this.json_title += " of Project " + selected_project_name.name;
        filter.issue = this.selected_project;
      }

      const selected_user_name = _.find(this.Users, {_id: this.selected_user});
      if (this.checkbox_user) {
        this.json_title += " and User " + selected_user_name.fullname;
        filter.user_id = this.selected_user;

      }
      const selected_location_name = _.find(this.Locations, {_id: this.selected_location});
      if (this.checkbox_location) {
        this.json_title += " and Location " + selected_location_name.name;
        filter.location = this.selected_location;
      }

      const selected_costcenter_name = _.find(this.Costcenters, {_id: this.selected_costcenter});
      if (this.checkbox_costcenter) {
        this.json_title += " and Costcenter " + selected_costcenter_name.name;
        filter.costcenter = this.selected_costcenter;
      }

      await this.loadReportEvents(filter);

      //let report = [];
      // this.json_fields = {
      //   "Datum des Tests": "date",
      //   "Testart": "m_name",

      //   "Name der Teststation": "o_name",
      //   "PLZ / Ort der Teststation": "o_codeTown",
      //   "Strasse der Teststation": "o_street",

      //   "Verantwortlicher Test Aufsichtsperson Name": "auth_name",
      //   "Verantwortlicher Test Aufsichtsperson Mailadresse": "auth_email",
      //   "Verantwortlicher Test Aufsichtsperson Mobilnummer": "auth_phone",

      //   "Nachname": "lastname",
      //   "Vorname": "firstname",
      //   "SVNR": "user_id",
      //   "Geburtsdatum": "birthday",
      //   "Geschlecht": "gender",
      //   "Mobilnummer": "phone",
      //   "Email Adresse": "email",
      //   "Postleitzahl": "postcode",
      //   "Ort": "town",
      //   "Strasse": "street",
      //   "Hausnummer": "street_number",
      //   "Türnummer": "door_number",

      //   "Test Aufsichtsperson Vorname": "r_firstname",
      //   "Test Aufsichtsperson Nachname": "r_lastname",

      //   "Resultat": "result"

      // }

      
      let report = [];

      //console.log(this.Event.Events)
      // let filteredEvents = _.filter(this.Event.Events.events, (event) => {
      //   return this.$moment(event.start) >= from && this.$moment(event.start) <= to;
      // })
      //console.log(from, to, filteredMCs);

      // const machines = this.buyables;
      // const organisations = array2ArrayObject(await this.Web3.SitesRegistryContract.getAllOrganisations());

      let filteredEvents = _.orderBy(this.Event.Events.events, [
        "user_id.fullname",
        "start",
      ]);

     // console.table(filteredEvents)
      //let obj_old = {};

      for (let event of filteredEvents) {
        //console.log(JSON.stringify(event))

        //let issues = [];
        //issues.push(event.issue.name);

        //let parent = event.issue.parent;
        //console.log("Start parent: ", parent)
        //for (let i = 0; i < 5; i++) {
          //while (parent) {
          //let findParent = _.find(this.Issues, { _id: parent });

          // if (findParent) {
          //   issues.push(findParent.name);
          //   parent = findParent.parent;
          // } else {
          //   break;
          // }
          //console.log("Rek ", findParent, parent)

       // }
        //issues = issues.reverse();

        let obj = {};
        obj.location = event.location.name;
        obj.User = event.user_id.fullname;

        let maxIssue;
        for (maxIssue = 1; maxIssue <= 5; maxIssue++) {
          
          let n2 = "issue" + String(maxIssue);
          //console.log("maxIssue: ", maxIssue, n2, event[n2]);
          if (!event[n2]) {
            maxIssue--;
            break;
          } else if (event[n2].name.length == 0) {
            maxIssue--;
            break;
          } 
          
        }
        //console.log(maxIssue);

        for (let i = maxIssue; i > 0; i--) {
          let n = "issue" + String(maxIssue - i + 1);
          let n2 = "issue" + String(i);
          if (event[n2]) {
            obj[n] = event[n2].name;
          }
        }

        //console.log(event)
        if (event.costcenter) {
          if (event.costcenter.name) {
            obj.Costcenter = event.costcenter.name;
          } else {
            event.costcenter.name = "not found";
          }
        }
        obj.Description = event.name;
        if (obj.Description.lastIndexOf("|") > 0) {
          obj.Description = obj.Description.substring(
            obj.Description.lastIndexOf("|") + 2,
            obj.Description.length
          );
        }
        obj.StartDate = this.$moment(event.start).format("DD.MM.YYYY");
        obj.StartTime = this.$moment(event.start).format("HH:mm");
        obj.EndDate = this.$moment(event.end).format("DD.MM.YYYY");
        obj.EndTime = this.$moment(event.end).format("HH:mm");
        obj.Hours =
          (this.$moment(event.end) - this.$moment(event.start)) / 3600 / 1000;
        obj.Hours = String(obj.Hours).replace(".", ",");

        // obj = _.sortBy(obj, ["User"]);

        // let durationMin = this.$moment.duration(obj.event.end - obj.event.start).minutes();
        // let durationHours = this.$moment.duration(obj.event.end - obj.event.start).hours();

        // const owner = _.find(this.Users, {_id: mc.owner_id});
        // const renter = _.find(this.Users, {_id: mc.renter_id});
        // const auth = {name: "Univ.-Prof. Dr. Peter Frigo", email: "peter.frigo@meduniwien.ac.at", phone: "0664 5110453"};
        // let organisation = {};
        //try {
          //organisation = array2Object(await sitesRegistryContract.getOrganisation(contract.Renter));
          // const machine = array2Object(await this.Web3.RentContract.getMachine(mc.machineId));
          // organisation = array2Object(await this.Web3.SitesRegistryContract.getOrganisation(machine.MachineOwner));

          // const machine = _.find(machines, {MachineId: mc.machineId});
          // //console.log("Machine ",machine, mc.machineId, machines)
          // if (machine) {

          //   organisation = _.find(organisations, {addressBC: machine.MachineOwner})
          //   //console.log("orgas ",organisations, organisation)
          //   if (owner) {
          //     let obj = {};
          //     //console.log(mc.contractNr)

          //     //let obj = JSON.parse(JSON.stringify(owner));

          //     obj.lastname = owner.lastname;
          //     obj.firstname = owner.firstname;
          //     obj.user_id = owner.user_id;
          //     obj.birthday = this.$moment(owner.birthday).format("DD.MM.YYYY");
          //     obj.gender = owner.gender;
          //     obj.phone = "'" + owner.phone;
          //     obj.email = owner.email;
          //     obj.postcode = owner.postcode;
          //     obj.town = owner.town;
          //     obj.street =  owner.street;
          //     obj.street_number = owner.street_number;
          //     obj.door_number  = owner.door_number;
          //     // console.log("Owner ", owner.lastname);
          //     // console.log("Renter ", renter.lastname);
          //     // console.log("organisation ", organisation.name);
          //     // console.log("machine ", machine.Name);
          //     obj.date = this.$moment(mc.createdAt).format("DD.MM.YYYY");
          //     obj.m_name = machine.Name;
          //     obj.o_name = organisation.name;

          //     obj.o_codeTown = organisation.codeTown;
          //     obj.o_street = organisation.street;

          //     obj.auth_name = auth.name;
          //     obj.auth_email = auth.email;
          //     obj.auth_phone = "'" + auth.phone;

          //     obj.r_firstname = renter.firstname;
          //     obj.r_lastname = renter.lastname;

          //     obj.contractNr = mc.contractNr;

          //     try {
          //       let subCat = JSON.parse(machine.SubCategory);
          //       //console.log(subCat, subCat.flag)
          //       if (subCat.flag) {
          //         obj.result = "positiv"
          //       } else {
          //         obj.result = "negativ"
          //       }

          //       //Filter Error Input
          //       //console.log(this.deepEqual(obj, obj_old), obj, obj_old)
          //       //console.log(JSON.stringify(obj) == obj_old, JSON.stringify(obj), obj_old)
          //       if (obj.user_id == obj_old.user_id && obj.result == obj_old.result && obj.date == obj_old.date) {
          //         console.log("Remove Dublette ", obj)
          //       } else {
          //         report.push(obj);
          //         obj_old = JSON.parse(JSON.stringify(obj));
          //       }
          //     } catch (err) {
          //       console.log(err);
          //     }
          //   }
          // }
          
        // } catch (err) {
        //   console.log(err);
        //   organisation = {};
        // }
        report.push(obj);
      }
      this.generateHeader(report);
      this.json_data = _.orderBy(report, ["start"], ["desc"]);
      for (let data of this.json_data) {
        data.Hours = String(data.Hours).replace(",", ".");
        data.Hours = parseFloat(data.Hours);
      }
      //console.table(this.json_data);
      this.json_title += "   -- Gesamt Stunden: " + _.sumBy(this.json_data, "Hours");
      //console.log(this.json_data)
      this.calcReport = true;
    },

    async generateExcel2() {
      this.json_data = [];
      this.json_title = "Projekt Level Report: " + this.$moment(this.selected_date, "YYYY-MM-DD").format("MMM YYYY");
      this.calcReport = false;
      let filter = {};
    
      if (this.checkbox_month) {
        filter.from = this.$moment(this.selected_date, "YYYY-MM-DD")
          .startOf("month")
          .format("X");
        filter.to = this.$moment(this.selected_date, "YYYY-MM-DD")
          .endOf("month")
          .format("X");
      } else {
        this.json_title += " to " + this.$moment(new Date(), "YYYY-MM-DD").format("MMM YYYY");
        filter.from = this.$moment(this.selected_date, "YYYY-MM-DD").format("X");
      }

      const selected_project_name = _.find(this.Issues, {_id: this.selected_project});
      if (this.checkbox_project) {
        this.json_title += " of Project " + selected_project_name.name;
        filter.issue = this.selected_project;
      }

      const selected_user_name = _.find(this.Users, {_id: this.selected_user});
      if (this.checkbox_user) {
        this.json_title += " and User " + selected_user_name.fullname;
        filter.user_id = this.selected_user;
      }

      const selected_location_name = _.find(this.Locations, {_id: this.selected_location});
      if (this.checkbox_location) {
        this.json_title += " and Location " + selected_location_name.name;
        filter.location = this.selected_location;
      }

      const selected_costcenter_name = _.find(this.Costcenters, {_id: this.selected_costcenter});
      if (this.checkbox_costcenter) {
        this.json_title += " and Costcenter " + selected_costcenter_name.name;
        filter.costcenter = this.selected_costcenter;
      }

      await this.loadReportEvents(filter);

      this.generateHeader(this.Event.Events.results3);
      this.json_data = this.Event.Events.results3;


      this.json_data = _.orderBy(this.json_data, [
        "fullname",
        "issue",
        "productive",
      ]);
      for (let data of this.json_data) {
         data.duration = parseFloat(data.duration);
      }
      //console.table(this.json_data);
      this.json_title += "   -- Gesamt Stunden: " + _.sumBy(this.json_data, "duration");
      this.calcReport = true;
    },


    // async generateExcel3() {
    //   this.json_title = "Projekt Report from " + this.$moment(this.selected_date, "YYYY-MM-DD").format("DD.MM.YYYY");
    //   this.calcReport = false;
    //   let filter = {};
    //   filter.from = this.$moment(this.selected_date, "YYYY-MM-DD").format("X");
    //   if (this.checkbox_project) {
    //     filter.issue = this.selected_project;
    //   }
            
    //   await this.loadReportEvents(filter);

    //   this.generateHeader(this.Event.Events.results3);
    //   this.json_data = this.Event.Events.results3;
    //   this.json_data = _.orderBy(this.json_data, [
    //     "fullname",
    //     "issue",
    //     "productive",
    //   ]);
    //   for (let data of this.json_data) {
    //     data.duration = String(data.duration).replace(".", ",");
    //   }
    //   //console.log(this.json_data);
    //   this.calcReport = true;
    // },

    async generateHeader(items) {
      let arr = [];
      this.json_fields = {};
      if (items.length > 0) {
        let foundIdx = 0;
        let itemLength = 0;
        for (let idx in items) {
          //console.log("Issue ", idx, items[idx] ,Object.keys(items[idx]).length)
          if (itemLength < Object.keys(items[idx]).length) {
            itemLength = Object.keys(items[idx]).length;
            foundIdx = idx;
          }
        }
        //console.log("Longest Issue ", items[foundIdx], foundIdx)
        for (let item of Object.keys(items[foundIdx])) {
          let obj = {};
          obj.text = this.$t(String(item));
          obj.value = item;
          this.json_fields[this.$t(String(item))] = item;
          arr.push(obj);
        }
        this.json_header = arr;
        //console.log(this.json_header)
      }
    },

  
  },
  watch: {},
};
</script>

<style>
.box-grey {
  border: solid 1px lightgrey;
}
</style>