import api from "../../../api";

export default {
  async getDeviceEvents({ commit }, obj) {
    commit("REQUEST_DEVICE_EVENTS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEVICE_EVENTS",
        await api.getDeviceEvents(obj)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DEVICE_EVENTS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
