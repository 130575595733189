import {
  AuthResource,
  SignUpResource,
  ActivateAccountResource,
  MySelfResource,
  ObsUserDataResource,
  ForgotPasswordResource,
  ResetPasswordResource,

  UserResource,
  UserCreateResource,
  UserReadResource,
  UserUpdateResource,
  UserDeleteResource,
  UserUpdateObsSettingsResource,

  // PersonResource,
  // PersonCreateResource,
  // PersonReadResource,
  // PersonUpdateResource,
  // PersonDeleteResource,
  
  EventCreateResource,
  EventResource,  
  EventUpdateResource,
  EventDeleteResource,
  EventReportResource,  
  
  IconCreateResource,
  IconResource,  
  IconUpdateResource,
  IconDeleteResource,

  // CostcenterCreateResource,
  // CostcenterResource,  
  // CostcenterUpdateResource,
  // CostcenterDeleteResource,

  LocationResource,
  LocationCreateResource,
  LocationUpdateResource,
  LocationDeleteResource,

  ScreenCreateResource,
  ScreenResource,  
  ScreenUpdateResource,
  ScreenDeleteResource,

  UserDeviceResource, 
  DeviceOnlyResource,
  DeviceEventResource,

  WeatherDailyResource,
  SolarFcResource,

  DatasetCreateResource,
  DatasetResource,  
  DatasetUpdateResource,
  DatasetDeleteResource,
  DatasetGetScoreResource,
  DatasetAllUserBenchmarksResource,

  GlobalResource,
  GlobalAllResource,
  GlobalCreateResource,
  GlobalUpdateResource,
  // SmApiResource
  
} from "./resources";

export default {
  authenticate({ email, password }) {
    return AuthResource.save({}, { email, password });
  },
  mySelf() {
    return MySelfResource.get();
  },
  obsUserData() {
    return ObsUserDataResource.get();
  },
  signUp({ email, password, displayname, fullname }) {
    return SignUpResource.save({}, { email, password, displayname, fullname });
  },
  activateAccount({ token }) {
    return ActivateAccountResource.get({ token }, {});
  },
  forgotPassword({ email }) {
    return ForgotPasswordResource.save({ email });
  },
  resetPassword({ token }) {
    return ResetPasswordResource.save({ token }, {});
  },
  getUsers() {
    return UserResource.get();
  },
  createUser(user) {
    return UserCreateResource.save({},user);
  },
  readUser(obj){
    return UserReadResource.get(obj, {});
  },
  deleteUser(id) {
    return UserDeleteResource.delete({ id }, {});
  },
  updateUser(user) {
    return UserUpdateResource.update({ id: user._id }, user);
  }, 
  updateObsSettings(obs_settings) {
    return UserUpdateObsSettingsResource.update(obs_settings);
  }, 
  getPersons() {
    return PersonResource.get();
  },
  createPerson(user) {
    return PersonCreateResource.save({},user);
  },
  readPerson({id}){
    return PersonReadResource.get({id}, {});
  },
  deletePerson(id) {
    return PersonDeleteResource.delete({ id }, {});
  },
  updatePerson(user) {
    return PersonUpdateResource.update({ id: user._id }, user);
  }, 
  getEvents(filter) {
    return EventResource.get(filter);
  },
  getEventsReport(filter) {
    return EventReportResource.get(filter);
  },
  deleteEvent(id) {
    return EventDeleteResource.delete({ id }, {});
  },
  updateEvent(event) {
    return EventUpdateResource.update({ id: event._id }, event);
  }, 
  createEvent(event) {
    return EventCreateResource.save({},event);
  },
  getIcons(filter) {
    return IconResource.get(filter);
  },
  deleteIcon(id) {
    return IconDeleteResource.delete({ id }, {});
  },
  updateIcon(event) {
    return IconUpdateResource.update({ id: event._id }, event);
  }, 
  createIcon(event) {
    return IconCreateResource.save({},event);
  },

  getScreens(filter) {
    return ScreenResource.get(filter);
  },
  deleteScreen(id) {
    return ScreenDeleteResource.delete({ id }, {});
  },
  updateScreen(event) {
    return ScreenUpdateResource.update({ id: event._id }, event);
  }, 
  createScreen(event) {
    return ScreenCreateResource.save({},event);
  },

  getDatasets(filter) {
    return DatasetResource.get(filter);
  },
  // deleteDataset(id) {
  //   return DatasetDeleteResource.delete({ id }, {});
  // },
  updateDataset(event) {
    return DatasetUpdateResource.update({ id: event._id }, event);
  }, 
  createDataset(event) {
    return DatasetCreateResource.save({},event);
  },
  getDatasetScore(filter) {
    return DatasetGetScoreResource.get(filter);
  },
  getAllUserBenchmarks(filter) {
    return DatasetAllUserBenchmarksResource.get(filter);
  },
  // getCostcenters(filter) {
  //   return CostcenterResource.get(filter);
  // },
  // deleteCostcenter(id) {
  //   return CostcenterDeleteResource.delete({ id }, {});
  // },
  // updateCostcenter(event) {
  //   return CostcenterUpdateResource.update({ id: event._id }, event);
  // }, 
  // createCostcenter(event) {
  //   return CostcenterCreateResource.save({},event);
  // },
  getLocation() {
    return LocationResource.get();
  },
  createLocation(location) {
    return LocationCreateResource.save({}, location);
  },
  updateLocation(location) {
    return LocationUpdateResource.update({ id: location._id }, location);
  },
  deleteLocation(id) {
    return LocationDeleteResource.delete({ id }, {});
  },
  getUserDevices(filter) {
    return UserDeviceResource.get(filter);
  },
  getDevicesOnly({ type } = {}) {
    return DeviceOnlyResource.get({ type });
  },
  getDeviceEvents({ deviceId, days, startDate, endDate }) {
    //console.log({ deviceId, days, startDate, endDate })
    return DeviceEventResource.get({ deviceId, days, startDate, endDate });
  },
  getWeatherDaily(filter) {
    return WeatherDailyResource.save({}, filter);
  },
  getSolarFc(filter) {
    return SolarFcResource.save({},filter);
  },

  getGlobal() {
    return GlobalResource.get();
  },
  getGlobalAll() {
    return GlobalAllResource.get();
  },
  createGlobal(item) {
    return GlobalCreateResource.save({}, item )
  },
  updateGlobal(item) {
    return GlobalUpdateResource.update({ id: item._id }, item);
  },
  
  // getSmApi() {
  //   // console.log("API sm");
  //   return SmApiResource.get();
  // },
};
