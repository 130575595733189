<template>
  <v-container class="pa-0 ma-0" :style="cssVars" >
    <v-row class="status-bar ml-0 mr-3">
      <!-- <v-col class="d-flex justify-start align-center my-n3" cols="auto">
        <v-icon>mdi-chevron-left</v-icon>
      </v-col> -->
      <v-col class="text-center my-n3">  
        {{formatedDate}}
      </v-col>
      
      <!-- <v-col class="d-flex justify-end align-center my-n3" cols="auto">
        <v-icon>mdi-chevron-right</v-icon>
      </v-col> -->
    </v-row>
   
    <!-- <v-row class="status-bar ml-0 mr-3 mt-5 mb-n1" >
      <v-col class="">
        <v-row class="">
          <v-col class="caption pb-0" cols="7">
            Production
          </v-col>
          <v-col class="caption pb-0" cols="3">
            120,0 kWh
          </v-col>
        </v-row>
        <v-row>
          <v-col class="grey--text caption py-0">
            Self Consumption
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pa-0">
            <apexchart
                type="radialBar"
                :options="radialBarOptions"
                :series="selfConsumptionSeries"         
              ></apexchart>
          </v-col>
          <v-col class="caption px-0">
            <v-row>
              <v-col class="pb-0">
                Eigenverbrauch: 91,0 kWh
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                Netzeinspeisung: 29,7 kWh
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="status-bar ml-0 mr-3" >
      <v-col >
        <v-row>
          <v-col class="caption pb-0" cols="7">
            Consumption
          </v-col>
          <v-col class="caption pb-0" cols="3">
            100,0 kWh
          </v-col>
        </v-row>
        <v-row>
          <v-col class="grey--text caption py-0">
           Self Sufficiency
          </v-col>
        </v-row>    
        <v-row>
          <v-col cols="4" class="pa-0">
            <apexchart
                type="radialBar"
                :options="radialBarOptions"
                :series="selfSufficiencySeries"         
              ></apexchart>
          </v-col>
          <v-col class="caption px-0">
            <v-row>
              <v-col class="pb-0">
                Eigenerzeugung: 34,2 kWh
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                Netzbezug: 8,6 kWh
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col class="mr-3">
        <!-- <Bar :chart-data="chartData" :chart-options="chartOptions" :height="windowHeight - 525"></Bar> -->
        <apexchart  v-if="showChart" type="line" height="350" :options="apexChartOptions" :series="apexChartData"></apexchart>
      </v-col>
    </v-row>
     <v-row class="ml-0 mr-3 ma-0">
      <v-col class="caption text-center my-n3">  
        Example: {{(pvShares * 50).toFixed(2)}} % shares of Tagger PV
      </v-col>
     </v-row>
  </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import _ from "lodash";
//import { Bar } from 'vue-chartjs/legacy';
//import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

//ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);


export default {
  name: "ElectricityDataScreen",
  props: { },
  components: {
    //Bar
  },
  
  data() {
    return {
      refreshInterval: null,
      selectedDate: null,
      selectedDeviceId: null,
      selectedDevice: null,
      selectedDays: 1,
      startDate: this.$moment().subtract(0,"days").format("YYYY-MM-DD"),
      endDate: this.$moment().format("YYYY-MM-DD"),
      myEvents: [],
      pvShares: 0.01,
      energy_production_data: [],
      // chartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   legend: {
      //     labels: {
      //       fontColor: "orange",
      //       defaultFontColor: "red",
      //       defaultFontSize: "40"
      //     }
      //   }
      // },
      selfConsumptionSeries: [75],
      selfSufficiencySeries: [80],
      radialBarOptions:{   
        colors: ["#20E647"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "50%"
            },       
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 4,
                color: "#fff",
                fontSize: "10px",
                show: true
              }
            }
          }
        },

        stroke: {
          lineCap: "round",
        }
      },
      windowHeight: 0,
      windowWidth: 0,

      
      apexChartOptions: {
        
        theme: {
          mode: this.$vuetify.theme.isDark ? "dark" : "light",
        },
        legend: {
          position: "top"
        },
        colors: [this.$vuetify.theme.currentTheme.energy_primary, this.$vuetify.theme.currentTheme.energy_secondary],
        forecastDataPoints: {
          count: 0,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        },
        chart: {
          toolbar: {
            show: true
          },
          zoom: {
            enabled: false,
          },
          background: this.$vuetify.theme.currentTheme.background_secondary,
          height: 350,
          type: 'line',
        },
        stroke: {
          width: "1",
         curve: "smooth"
        },
        fill: {
          type:'solid',
          opacity: [0.2, 0.5],
        },
        labels: [],
        markers: {
          size: 0
        },
        yaxis: [
          {
            title: {
              text: 'kWh',
            },
          },
          // {
          //   opposite: true,
          //   title: {
          //     text: 'Series B',
          //   },
          // },
        ],
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            format: 'dd MMM HH:mm',
            formatter: undefined,
          },
          y: {
            formatter: function (y) {
              if(typeof y !== "undefined") {
                return  y.toFixed(1) + " kWh";
              }
              return y;
            }
          }
        }
      },
      showChart: true,
    };
  },
  created: async function () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.selectedDate = this.$moment().subtract(0, "days").format("DD MMM YYYY");
    console.log("getDevicesOnly: ")
    await this.getDevicesOnly({type: "power"});
    console.log("getDevicesOnly: ", this.selectedDeviceId)
  },
  async mounted() {
  //  this.apexChartOptions.labels = this.date

    this.refreshInterval = setInterval(this.refresh, 10000);
  },

  destroyed() {
    try {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
      }
    } catch (e) {
      //
    }
  },
  methods: {
    ...mapActions("device", ["getDevicesOnly", "getDevice"]),
    ...mapActions("event", ["getDeviceEvents"]),

    async loadEvents() {
      console.log("GetDeviceEvents: ", this.selectedDeviceId)
      // await this.getDeviceEvents({
      //   deviceId: this.selectedDeviceId,
      //   //days: this.selectedDays,
      //   startDate: this.startDate,
      //   endDate: this.endDate,
      // });
      let arr = [];
      for (let el of this.events) {
        arr.push([parseInt(this.$moment(el._id).format("x")) + (this.$moment().utcOffset() * 60000), Number(el.total_positive.toFixed(1))]);
      }
      this.myEvents = arr; //JSON.parse(JSON.stringify(this.events));
    },

    calc_energy_production_data() {
      // console.log(_.map(this.SolarFc[0].data, "time", "value"));
      // return _.map(this.SolarFc[0].data, "value");
      
      const maxValueConsumption = Math.max(..._.map(this.myEvents,1));
      const maxValuePV = Math.max(...(_.map(this.SolarFc[0].data, "value")));
      if (maxValueConsumption > 0 && maxValuePV > 0) {
        this.pvShares = maxValueConsumption / maxValuePV;
      }
      //console.log(maxValueConsumption, maxValuePV, this.pvShares);
      let arr = [];
      for (let el of this.SolarFc[0].data) {
        arr.push([parseInt(this.$moment(el.time, "X").format("x")) + (this.$moment().utcOffset() * 60000), (el.value * this.pvShares).toFixed(1)]);
      }
      this.energy_production_data = arr;
    },

    async refresh() {
      this.loadEvents();
    }
  },
  computed: {
    ...mapState({
      SolarFc: ({ forecast }) => forecast.SolarFc,
      deviceStatus: ({ device }) => device.statusOnly,
      powerDevices: ({ device }) => device.powerDevicesOnly,
      powerDevice: ({ device }) => device.powerDevice,
      events: ({ event }) => event.events,
      eventStatus: ({ event }) => event.status,
    }),
    

    formatedDate() {
      return this.$moment(this.selectedDate).format("DD MMM YYYY");
    },

    // chartData() {
    //   return {
    //     datasets: [
    //       {
    //         label: "Production",
    //         data: this.energy_production_data,
    //         backgroundColor: "rgba(255, 223, 20, 0.5)",
    //         borderColor: "rgb(255, 223, 20)",
    //         borderWidth: 2,
    //         type: 'line'
    //       },
    //       {
    //         label: "Consumption",
    //         data: this.energy_consumption_data,
    //         backgroundColor: "rgba(0, 223, 20, 0.5)",
    //         borderColor: "rgb(0, 223, 20)",
    //         borderWidth: 2
    //       },
    //     ],
    //     labels: this.date
    //   };
    // },

    apexChartData() {
      return [{
          name: 'PV ForeCast',
          type: 'area',
          data: this.energy_production_data
        }, {
          name: 'Consumption',
          type: 'area',
          data: this.myEvents
        }]
    },


    // energy_consumption_data() {
    //   // let energyConsumption = JSON.parse(JSON.stringify(this.SolarFc[0].data));
    //   let arr = [];
    //   // for (let el of energyConsumption) {
    //   //   let value = el.value / (Math.random()*2+1);
    //   //   arr.push([parseInt(this.$moment(el.time, "X").format("x")) + (this.$moment().utcOffset() * 60000), value.toFixed(1)]);
    //   // }
    //   //console.log("Consumption: ", arr);
      
    //   // return _.map(energyConsumption, "value");
    //   //arr = _.map(this.myEvents, "total_positive");    
    //   // arr = []; 
    //   for (let el of this.myEvents) {
    //     arr.push([el.time, el.total_positive]);
    //   }
    //   //console.log("Consumption 2: ", arr);
    //   return arr;

    // },
    // date() {
    //   return _.map(this.SolarFc[0].data, val => {
    //     return this.$vuetify.breakpoint.smAndDown
    //       ? this.$moment(val.time, "X").format("HH:mm")
    //       : this.$moment(val.time, "X").format("HH:mm") //new Date(val.time).toLocaleString();
    //   });
    // },

    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_secondary,
          '--size': this.size + "px",
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_secondary,
          '--size': this.size + "px",
        }
      }
    },

    themeMode() {
      if (this.$vuetify.theme.isDark) {
        return "dark";
      } else {
        return "light"
      }
    }
  },
  watch: {
    themeMode(theme) {
      // console.log("Theme changed ", theme);
      this.showChart = false;
      this.apexChartOptions.theme.mode = theme;
      this.apexChartOptions.chart.background = this.cssVars['--bg'];
      
      // this.$nextTick(() => {
      //   this.$forceUpdate();
      //   console.log("Update forced")
      // });
      // this.apexChartOptions = {
      //   theme: {
      //     mode: theme
      //   }
      // };
      this.$nextTick(() => {
        this.showChart = true;
      });
      
    },
    async selectedDeviceId(val) {
      //console.log("selectedDeviceId ", val)
      await this.getDevice({_id: val});
      this.selectedDevice = this.powerDevice;
      this.loadEvents();
    },
    deviceStatus(val) {
      // preselect first device
      if (val === "success") {
        if (this.powerDevices.length > 0) {
          this.selectedDeviceId = this.powerDevices[0]._id;
        }
      }
    },
    myEvents() {
      this.calc_energy_production_data();
    }
  },


};
</script>

<style lang='scss'>

.status-bar-eds {
  background: #1af;//var(--bg) !important;
  border-radius: 4px;
}

</style>