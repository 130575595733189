<template>
  <v-container class=" pa-0 ma-0" :style="cssVars">
    <v-row class="status-bar ml-0 mr-3">
      <!-- <v-col class="d-flex justify-start align-center my-n3" cols="auto">
        <v-icon>mdi-chevron-left</v-icon>
      </v-col> -->
      <v-col class=" text-center my-n3">  
        <div class="status-bar2 mr-4">{{formatedDate}}</div>
      </v-col>
      <!-- <v-col class="d-flex justify-end align-center my-n3" cols="auto">
        <v-icon>mdi-chevron-right</v-icon>
      </v-col> -->
    </v-row>

    <v-row class="justify-start">
      <v-col cols="1"></v-col>
      <v-col cols="5" xs="3" class="ml-n4"  v-if="renderEnergyComponent">
        <GraphBar class="EnergyBar" :elements="energyBarElements" :titles="energyBarTitles" :titlesOff="false" size="30" />
      </v-col>
      <v-col cols="3" xs="3" class="ml-n8 mt-2" v-if="renderWeatherComponent">
        <v-row v-for="element of weatherBarElements" :key="element.dt" class="my-n5 status-bar">
          <v-col class="d-flex ma-n1 align-center" style="font-size: 12px">
            <ShowSVG
              class=""
              :id="element.dt"
              :name="matrixWeatherIcon(element)"
              :title="'weather_' + element.dt"
              primary="grey"
              secondary="black"
              size="30"
              text=""
              glow="false"
            />
            {{element.temp.toFixed(0)}}°C
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" xs="3" class="mt-2 ml-1" v-if="renderHeatingComponent && heatingBarElements">   
        <v-row v-for="element in heatingBarElements" :key="element._id" class="my-n5 status-bar">
          <v-col class="d-flex ma-n1 align-center" style="font-size: 12px">
            <ShowSVG
              class=""
              :id="element._id"
              :name="element.icon"
              :title="'room_' + element.roomname.replace(' ','_')"
              primary="grey"
              secondary="black"
              size="30"
              text=""
              glow="false"
            />
            {{fix_temp(element.actTemp)}}°C
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
//import { mapState, mapActions } from "vuex";
//import TextToSVG from "text-to-svg";
import ShowSVG from "../components/ShowSVG";
import GraphBar from "../components/GraphBar";
import ScoreBar from "../components/ScoreBar";

export default {
  name: "StatusBar",
  props: { energyBarElements: null, energyBarTitles: null, weatherBarElements: null, heatingBarElements: null },
  components: {
    ShowSVG,
    GraphBar,
    ScoreBar
  },
  data() {
    return {
      btnWidth: 0,
      selectedDate: new Date(),
      renderWeatherComponent: true,
      renderEnergyComponent: true,
      renderHeatingComponent: true,

      weatherIcons: [
        {
          owi: "01d",
          obsi: "forecast_sunny"
        },
        {
          owi: "01n",
          obsi: "forecast_sunny"
        },
        {
          owi: "02d",
          obsi: "forecast_mainlysunny"
        },
        {
          owi: "02n",
          obsi: "forecast_mainlysunny"
        },
        {
          owi: "03d",
          obsi: "forecast_cloudysun"
        },
        {
          owi: "03n",
          obsi: "forecast_cloudysun"
        },
        {
          owi: "04d",
          obsi: "forecast_heavyclouds"
        },
        {
          owi: "04d",
          obsi: "forecast_heavyclouds"
        },
        {
          owi: "09d",
          obsi: "forecast_rainfall"
        },
        {
          owi: "09n",
          obsi: "forecast_rainfall"
        },
        {
          owi: "10d",
          obsi: "forecast_heavyrainfall"
        },
        {
          owi: "10n",
          obsi: "forecast_heavyrainfall"
        },
        {
          owi: "11d",
          obsi: "forecast_thunderstorm"
        },
        {
          owi: "11n",
          obsi: "forecast_thunderstorm"
        },
        {
          owi: "13d",
          obsi: "forecast_heavysnowfall"
        },
        {
          owi: "13n",
          obsi: "forecast_heavysnowfall"
        },
        {
          owi: "50d",
          obsi: "forecast_misty"
        },
        {
          owi: "50n",
          obsi: "forecast_misty"
        },
      ]
      
    };
  },
  created: function () {
    this.btnWidth = (window.innerWidth - 50 - 3 * 3) / 3;
  },
  async mounted() {
   
  },
  methods: {
    matrixWeatherIcon(obj) {
      //console.log(obj.weather[0].icon);
      
      let icon = _.find(this.weatherIcons, {owi: obj.weather[0].icon})
      if (!icon) {
        icon = {};
        icon.obsi = "forecast_stormy";
      }
      return icon.obsi;
    },

    fix_temp(temp) {
      if (temp) {
        return temp.toFixed(0);
      } else {
        //ToDo: Change to N/A
        return "23"
        //return "N/A";
      }
    }
   
  },
  computed: {
    formatedDate() {
      return this.$moment(this.selectedDate).format("DD MMM YYYY");
    },
    cssVars() {
      if (this.$vuetify.theme.isDark) {          
        return {
          '--bg': this.$vuetify.theme.themes.dark.background_primary,
          '--bg2': this.$vuetify.theme.themes.dark.background_secondary,
        }
      } else {
        return {
          '--bg': this.$vuetify.theme.themes.light.background_primary,
          '--bg2': this.$vuetify.theme.themes.light.background_secondary,
        }
      }
    },
    
  },
  watch: {
    energyBarElements() {
      // console.log("elements changed");
      //this.$forceUpdate();
      this.renderEnergyComponent = false;
      this.$nextTick(() => {
        this.renderEnergyComponent = true;
      });
    },
    weatherBarElements() {
      // console.log("weather elements changed");
      this.renderWeatherComponent = false;
      this.$nextTick(() => {
        this.renderWeatherComponent = true;
      });
    }
  },
};
</script>

<style lang='scss'>

.status-bar {
  background: var(--bg) !important;
  border-radius: 4px;
}
.status-bar2 {
  background: var(--bg2) !important;
  border-radius: 4px;
}
</style>