import Vue from "vue";
import VueResource from "vue-resource";
import { API_ROOT } from "../config";

Vue.use(VueResource);

// Vue.http.options.crossOrigin = true
// Vue.http.options.credentials = true

/*if (process.env.NODE_ENV === "production") {
  throw new Error("Missing API root");
  // Vue.http.options.root = ...
}*/

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  request.headers.set("Authorization", localStorage.getItem("Authorization"));
  next(response => {
    if (response.status === 401) {
      if (!window.location.pathname === "/login")
        window.location.pathname = "/login";
    }
  });
});

export const AuthResource = Vue.resource(API_ROOT + "auth");
export const SignUpResource = Vue.resource(API_ROOT + "signup");
export const ActivateAccountResource = Vue.resource(
  API_ROOT + "activate/{token}"
);
export const MySelfResource = Vue.resource(API_ROOT + "myself");
export const ObsUserDataResource = Vue.resource(API_ROOT + "obs_user_data");
export const ForgotPasswordResource = Vue.resource(
  API_ROOT + "forgot_password"
);
export const ResetPasswordResource = Vue.resource(
  API_ROOT + "reset_password{/token}"
);

export const UserResource = Vue.resource(API_ROOT + "user");
export const UserCreateResource = Vue.resource(API_ROOT + "user");
export const UserReadResource = Vue.resource(API_ROOT + "user/{id}");
export const UserUpdateResource = Vue.resource(API_ROOT + "user/{id}");
export const UserDeleteResource = Vue.resource(API_ROOT + "user/{id}");
export const UserUpdateObsSettingsResource = Vue.resource(API_ROOT + "user/update_obs_settings");


export const PersonResource = Vue.resource(API_ROOT + "person");
export const PersonCreateResource = Vue.resource(API_ROOT + "person");
export const PersonReadResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonUpdateResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonDeleteResource = Vue.resource(API_ROOT + "person/{id}");

export const EventResource = Vue.resource(API_ROOT + "obs_event");
export const EventReportResource = Vue.resource(API_ROOT + "obs_event/report");
export const EventDeleteResource = Vue.resource(API_ROOT + "obs_event/{id}");
export const EventUpdateResource = Vue.resource(API_ROOT + "obs_event/{id}");
export const EventCreateResource = Vue.resource(API_ROOT + "obs_event");

export const IconResource = Vue.resource(API_ROOT + "icon");
export const IconDeleteResource = Vue.resource(API_ROOT + "icon/{id}");
export const IconUpdateResource = Vue.resource(API_ROOT + "icon/{id}");
export const IconCreateResource = Vue.resource(API_ROOT + "icon");

export const ScreenResource = Vue.resource(API_ROOT + "obs_screen");
export const ScreenDeleteResource = Vue.resource(API_ROOT + "obs_screen/{id}");
export const ScreenUpdateResource = Vue.resource(API_ROOT + "obs_screen/{id}");
export const ScreenCreateResource = Vue.resource(API_ROOT + "obs_screen");

// export const CostcenterResource = Vue.resource(API_ROOT + "costcenter");
// export const CostcenterDeleteResource = Vue.resource(API_ROOT + "costcenter/{id}");
// export const CostcenterUpdateResource = Vue.resource(API_ROOT + "costcenter/{id}");
// export const CostcenterCreateResource = Vue.resource(API_ROOT + "costcenter");

export const LocationResource = Vue.resource(API_ROOT + "location");
export const LocationCreateResource = Vue.resource(API_ROOT + "location");
export const LocationUpdateResource = Vue.resource(API_ROOT + "location/{id}");
export const LocationDeleteResource = Vue.resource(API_ROOT + "location/{id}");

export const UserDeviceResource = Vue.resource(API_ROOT + "obs_userdevice");
export const DeviceOnlyResource = Vue.resource(API_ROOT + "device/only");

export const WeatherDailyResource = Vue.resource(API_ROOT + "forecast/weather/daily");
export const SolarFcResource = Vue.resource(API_ROOT + "forecast/solar");

export const DatasetResource = Vue.resource(API_ROOT + "obs_dataset");
// export const DatasetDeleteResource = Vue.resource(API_ROOT + "obs_dataset/{id}");
export const DatasetUpdateResource = Vue.resource(API_ROOT + "obs_dataset/{id}");
export const DatasetCreateResource = Vue.resource(API_ROOT + "obs_dataset");
export const DatasetGetScoreResource = Vue.resource(API_ROOT + "obs_dataset/getScore");
export const DatasetAllUserBenchmarksResource = Vue.resource(API_ROOT + "obs_dataset/getAllUserBenchmarks");

export const GlobalResource = Vue.resource(API_ROOT + "global");
export const GlobalAllResource = Vue.resource(API_ROOT + "global/all");
export const GlobalCreateResource = Vue.resource(API_ROOT + "global");
export const GlobalUpdateResource = Vue.resource(API_ROOT + "global/{id}");
// export const SmApiResource = Vue.resource(API_ROOT + "sm_api");

export const DeviceEventResource = Vue.resource(
  API_ROOT + "device{/deviceId}/event"
);